import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + '/api/companytofreelancerelations/';

export const fetchCompanyToFreelanceRelations = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching data :", error);
        throw error;
    }
};

export const createCompanyToFreelanceRelation = async (freelanceData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', freelanceData, token);
    } catch (error) {
        console.error('Error creating relation:', error);
        throw error;
    }
};

export const updateCompanyToFreelanceRelation = async (companyToFreelanceRelationId, companyToFreelanceRelationData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${companyToFreelanceRelationId}`, 'POST', companyToFreelanceRelationData, token);
    } catch (error) {
        console.error('Error updating relation:', error);
        throw error;
    }
};
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';

import './index.css';

import {MessageComponent} from '../components/UI/MessageComponent';

import logosmall from '../assets/smarteem_logo_small.png';

import {resetPassword} from '../services/userService';
import {Button} from "@mui/material";

export const ResetPassword = () => {
    const {token} = useParams();
    const [password, setPassword] = useState('');
    const [message, setMessage] = React.useState({});

    // Gestion des traductions
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        // Envoie la demande de réinitialisation au serveur
        const reset = async () => {
            try {
                setMessage({type: "loading", message: "Mise à jour"});
                const responseData = await resetPassword(token, password);
                if (responseData.success) {
                    setMessage({type: "success", message: "Réinitialisation du mot de passe effectuée avec succès"});
                    navigate('/');
                } else {
                    setMessage({type: "error", message: "Echec de la réinitialisation"});
                }
            } catch (error) {
                // Gérer l'erreur
            }
        };
        reset();
    };

    return (
        <div>
            <div className='contentholder'>
                <img
                    src={logosmall}
                    lt="logo"
                    alt='Smarteem Logo'
                    height={100}
                    className='logosmall'
                />
                <div className='centerformcard'>
                    <form className='inputform' onSubmit={handleResetPassword}>
                        <div className='field'>
                            <h1>{t('New password') + ":"}</h1>
                        </div>
                        <div className='field'>
                            <input
                                type='password'
                                name='password'
                                placeholder='********'
                                className='input inputindex'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className='buttonbox'>
                            <Button
                                onClick={handleResetPassword}
                                variant="contained"
                                size='medium'
                                style={{width: '100%'}}
                            >{t("Change the password")}</Button>
                        </div>
                        <MessageComponent
                            type={message.type}
                            message={message.message}
                            width="90%"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import './missiondocuments.css';
import './input.css';

import { createFile, fetchFile } from '../../services/fileService';
import { fetchContracts } from '../../services/contractService';
// import { fetchQuotes } from '../../services/quoteService';
// import { fetchInvoices } from '../../services/invoiceService';
import { MessageComponent } from '../UI/MessageComponent';
import { FaFile, FaCommentDots, FaPlus } from 'react-icons/fa';
import { DocumentPopup } from '../Popup/DocumentPopup';

export const MissionDocuments = ({documentsType, missionData, canModify, setFileInfo, ...props}) => {
  const token = useSelector(state => state.auth.token);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [filesData, setFilesData] = useState({});
  const [loading, setLoading] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [message, setMessage] = React.useState({});

  const [showPopup, setShowPopup] = React.useState(true);
  const togglePopupVisibility = () => {
    setShowPopup(!showPopup);
  };
  
  const onPopupClose = () => {
    togglePopupVisibility();
    setTriggerApi(true);
  };

  useEffect(() => {
    const getDocumentsInfo = async () => {
    try {
      let fetchedFilesData;
      if (documentsType === 'contract') {
        fetchedFilesData = await fetchContracts(missionData.id, token);
      } else if (documentsType === 'invoice') {
        // fetchedFilesData = await fetchInvoices({missionId: missionId, token});
      } else if (documentsType === 'quote') {
        // fetchedFilesData = await fetchQuotes({missionId: missionId, token});
      } else {
        throw new Error('Invalid document type');
      }
      setFilesData(fetchedFilesData);
      setTriggerApi(false); // Réinitialiser le déclencheur après l'appel API
    } catch (error) {
        // Gérer l'erreur
    }
    };
    if (typeof documentsType !== undefined && triggerApi) {
      getDocumentsInfo();
    };
  }, [triggerApi, token]);
  
  const handleCellClick = async ({itemId, itemType}) => {
    try{
      setLoading(true); // Display the loading icon
      const fetchedFile = await fetchFile(itemId, token);

      // Créez une URL à partir du blob
      const url = URL.createObjectURL(fetchedFile);
      // Ouvrez le nouvel onglet du navigateur avec l'URL du blob
      const newTab = window.open(url, '_blank');

      setLoading(false); // Remove the loading icon

      if (!newTab) {
        // Le bloqueur de fenêtres pop-up a empêché l'ouverture du nouvel onglet
        console.error('Le nouvel onglet a été bloqué par le bloqueur de fenêtres pop-up.');
      }
    } catch (error) {
      setMessage({type:'error', message: error.message});
      setLoading(false); // Remove the loading icon
    }
  };

  let bodyComponent;
  try {
    bodyComponent = 
      <tbody className='mission-documents-tablebody'>    
        {filesData.map((item, index) => (
            <tr className='mission-documents-cell mission-documents-row' key={index} onClick={() => handleCellClick({itemId: item.id, itemType: ''})}>
                <FaFile size={20} className="fas" />
                <p>{item.name}</p>
                <FaCommentDots size={20} className="fas" />
            </tr>
        ))}
      </tbody>
  } catch {
    bodyComponent = <MessageComponent type={message.type} message={message.message} />
  };

  // Utilisez les données dans votre composant
  // par exemple, les afficher dans une liste
  return (
    <div className='input-column input-column-standard'>
      <div className='input-row'>
        <table className='mission-documents-table'>
            {bodyComponent}
        </table>
        {canModify ? 
          <>
            <FaPlus size={20} className="fas" onClick={togglePopupVisibility} />
            <p className='action-indication'>Add a new contract</p>
          </> 
          : 
          <></>
        }
      </div>
      {showPopup ? null : 
        <DocumentPopup 
          className=''
          onClose={onPopupClose}
          documentsType={documentsType}
          setFileInfo={setFileInfo}
          />
      }
    </div>
  );
};
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/companyfreelanceinteractions/";

export const fetchInteractions = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching interactions :", error);
        throw error;
    }
};

export const fetchFreelanceInteractions = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching interactions :", error);
        throw error;
    }
};

export const createInteraction = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating interaction :", error);
        throw error;
    }
};

export const updateInteraction = async (interactionId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${interactionId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating interaction :", error);
        throw error;
    }
};

export const deleteInteraction = async (interactionId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${interactionId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting interaction :", error);
        throw error;
    }
};
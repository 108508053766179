import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/contracts/";

export const fetchContract = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching contract:", error);
        throw error;
    }
};

export const createContract = async (contractData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', contractData, token);
    } catch (error) {
        throw error;
    }
};

export const fetchContracts = async ({filters = {}, token}) => {
    try {
        return requestApi(`${BASE_URL}`, 'POST', {filters}, token);
    } catch (error) {
        throw error;
    }
};

export const getContractsByCompany = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}get-by-company?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const getContractsByMission = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const updateContract = async (contractId, contractData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${contractId}`, 'PUT', contractData, token);
    } catch (error) {
        console.error("Error updating contract:", error);
        throw error;
    }
};

export const createContractSmarteem = async (datas, token) => {
    try {
        const response = await fetch(`${BASE_URL}create-contract-smarteem`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: JSON.stringify(datas),
        });
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const modifyManyContractStatus = async (datas, token) => {
    try {
        const response = await fetch(`${BASE_URL}modify-many-contract-status`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: JSON.stringify(datas),
        });
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const sendNotification = async (datas, token) => {
    try {
        return requestApi(`${BASE_URL}send-notification`, 'POST', datas, token);
    } catch (error) {
        throw error;
    }
};
import "./App.css";
import "./components/Button/button.css";
import "./components/UI/stepper.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {Provider} from "react-redux";
import {store} from "./utils/store";

import {AppRouter} from "./routes/router";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import {ToastProvider} from "./context/ToastProvider";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <ToastProvider>
                    <header>
                        <meta name="robots" content="noindex"/>
                    </header>
                    <div className="App">
                        <AppRouter/>
                    </div>
                </ToastProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;

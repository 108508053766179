import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/certifications/";

export const fetchCertifications = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching certifications :", error);
        throw error;
    }
};

export const fetchFreelanceCertifications = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching certifications :", error);
        throw error;
    }
};

export const createCertification = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating certification :", error);
        throw error;
    }
};

export const updateCertification = async (certificationId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${certificationId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating certification :", error);
        throw error;
    }
};

export const deleteCertification = async (certificationId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${certificationId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting certification :", error);
        throw error;
    }
};
import React, {memo, useState, useEffect, useMemo, useRef} from "react";
import {fetchFile} from "../../services/fileService";
import {useSelector} from "react-redux";
import Functions from "../../utils/Functions";
import userPicture from "../../assets/user.png";
import {Skeleton} from "@mui/material";
import Utils from "../../utils/Utils";

const S3Image = memo(function S3Image(
    {
        value,
        className = "user-card-image",
        displaySkeleton = false
    }
) {
    const token = useSelector((state) => state.auth.token);
    const [imageUser, setImageUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const maxAttempts = 4;
    const abortControllerRef = useRef(null);

    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        if (value === "default") {
            setImageUser("default");
        } else if (typeof value !== "undefined" && value) {
            let imageFound = false;
            let abortError = false;

            (async () => {
                if (displaySkeleton) {
                    setLoading(true);
                }
                for (let i = 0; i < maxAttempts; i++) {
                    if (imageFound) break;

                    try {
                        let picture;

                        if (Utils.isAbsoluteUrl(value)) {
                            picture = value;
                        } else {
                            picture = await fetchFile(
                                value,
                                Functions.TEXT_PROFILE_PICTURE,
                                token,
                                {signal: abortControllerRef.current.signal}
                            );
                        }
                        if (picture && !picture?.error) {
                            imageFound = true;
                            setImageUser(picture);
                            setLoading(false);
                        }
                    } catch (error) {
                        if (error.name === "AbortError") {
                            abortError = true;
                            break;
                        }
                    }

                    await new Promise(resolve => setTimeout(resolve, 2000));
                }

                if (!imageFound && !abortError) {
                    setImageUser("default");
                    setLoading(false);
                }
            })();
        }
    }, [token, value, displaySkeleton]);

    const imageUrl = useMemo(() => {
        if (imageUser && imageUser !== "default") {
            return Utils.isAbsoluteUrl(imageUser)
                ? imageUser
                : URL.createObjectURL(imageUser);
        }
        return userPicture;
    }, [imageUser]);

    return (
        <>
            {loading && <Skeleton animation="wave" className={className}/>}
            {!loading && imageUser && (
                <img src={imageUrl} alt="Display" className={className}/>
            )}
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
});

export default S3Image;

import {AppLayout} from "../components/UI/AppLayout";
import {useSelector} from "react-redux";
import {QuoteListSection} from "../components/Forms/Mission/Sections/QuoteSection/QuoteListSection";

export const QuotesBoard = () => {
    const sessionType = useSelector((state) => state.auth.sessionType);
    return (
        <>
            <AppLayout>
                <QuoteListSection sessionType={sessionType} isSection={false}/>
            </AppLayout>
        </>
    );
};

export default QuotesBoard;
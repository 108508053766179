import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {AppLayout} from "../components/UI/AppLayout";

import {CompanyForm} from "../components/Forms/Configuration/CompanyForm";
import {ContactRequests} from "../components/Forms/Configuration/ContactRequests";
import {OrganizationSection} from "../components/Forms/Configuration/OrganizationSection";
import {UserForm} from "../components/Forms/Configuration/UserForm";
import SectionMenu from "../components/UI/SectionMenu";
import {Language} from "../components/Forms/Configuration/Language";
import Functions from "../utils/Functions";
import {AssignmentManagement} from "../components/Forms/Configuration/AssignmentManagement";

export const Configuration = () => {
    const sessionType = useSelector((state) => state.auth.sessionType);
    const companyRole = useSelector((state) => state.auth.companyRole);
    const {t} = useTranslation();
    const [activeComponent, setActiveComponent] = useState("UserSection");
    const [message, setMessage] = React.useState({});

    let sectionMenuItems = [];

    if (
        sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
        companyRole === Functions.USER_ROLE.SUPER_ADMINISTRATOR
    ) {
        sectionMenuItems = [
            {key: 'UserSection', name: 'My account'},
            {key: 'CompanyForm', name: 'My company'},
            {key: 'OrganizationSection', name: 'Users'},
            {key: 'ContactRequestSection', name: 'Access to freelancer profiles'},
            {key: 'AssignmentManagement', name: 'Assignment management'},
            {key: 'Language', name: 'Language'},
        ];
    } else if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
        sectionMenuItems = [
            {key: 'UserSection', name: 'My account'},
            {key: 'OrganizationSection', name: 'Users'},
            {key: 'ContactRequestSection', name: 'Access to freelancer profiles'},
            {key: 'Language', name: 'Language'},
        ];
    } else if (sessionType === Functions.SESSION_TYPE_FREELANCE) {
        sectionMenuItems = [
            {key: 'UserSection', name: 'My account'},
            {key: 'Language', name: 'Language'},
        ];
    }

    const handleButtonClick = (componentName) => {
        setActiveComponent(componentName);
    };

    return (
        <AppLayout>
            <div className="pageheader">
                <h1 className="pagetitle">{t("Settings")}</h1>
            </div>
            <div className="form-layout">
                <div className="workflowmenu">
                    <SectionMenu
                        sectionMenuItems={sectionMenuItems}
                        activeSection={activeComponent}
                        onSectionMenuClick={handleButtonClick}
                    />
                </div>

                {activeComponent === "UserSection" && (
                    <UserForm
                        activeComponent={activeComponent}
                        message={message}
                        setMessage={setMessage}
                    />
                )}
                {activeComponent === "OrganizationSection" && (
                    <OrganizationSection
                        activeComponent={activeComponent}
                        error={message.message}
                    />
                )}
                {activeComponent === "CompanyForm" && (
                    <CompanyForm
                        activeComponent={activeComponent}
                        message={message}
                        setMessage={setMessage}
                    />
                )}
                {activeComponent === "ContactRequestSection" && (
                    <ContactRequests
                        activeComponent={activeComponent}
                        message={message}
                        setMessage={setMessage}
                    />
                )}
                {activeComponent === "AssignmentManagement" && (
                    <AssignmentManagement
                        message={message}
                        setMessage={setMessage}
                    />
                )}
                {activeComponent === "Language" && (
                    <Language
                        activeComponent={activeComponent}
                        message={message}
                        setMessage={setMessage}
                    />
                )}
            </div>
        </AppLayout>
    );
};

import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button} from "@mui/material";

import ValidationStep from "./Validation";
import ContactStep from "./ContactStep";
import {useToast} from "../../../context/ToastProvider";
import ResponsiveStepper from "../../UI/ResponsiveStepper";

const EnterpriseCollaborationRequestForm = ({collaborationRequest, onSaveFinish}) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(collaborationRequest);
    const [activeStep, setActiveStep] = useState(0);
    const checkStepRef = useRef();

    const steps = [
        {key: 'contact', name: 'Contact'},
        {key: 'validation', name: 'Validation'},
    ];
    const activeStepKey = steps[activeStep].key;

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        checkStepRef.current();
    };

    const handleSubmit = (response) => {
        if (response.message) {
            showToast(
                t(response.message),
                response.error ? 'error' : (response.success ? 'success' : 'warning'),
                10000
            );
        }
        if (response.success) {
            if (isLastStep()) {
                onSaveFinish(true);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    }

    return <Box sx={{width: {md: "40vw"}}}>
        <ResponsiveStepper
            steps={steps}
            index={activeStep}
            onStepClick={setActiveStep}
        />

        <Box sx={{mt: 4}}>
            {activeStepKey === "contact" &&
                <ContactStep
                    ref={checkStepRef}
                    data={data}
                    setLoading={setLoading}
                    setData={setData}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                />
            }
            {activeStepKey === "validation" &&
                <ValidationStep
                    ref={checkStepRef}
                    data={data}
                    setLoading={setLoading}
                    onSubmit={handleSubmit}
                />
            }

            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    variant="contained"
                    color="light"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{mr: 1}}
                >
                    {t("Previous")}
                </Button>
                <Box sx={{flex: '1 1 auto'}}/>
                <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    onClick={handleNext}>
                    {activeStep === steps.length - 1 ? t("Validate") : t("Next")}
                </Button>
            </Box>
        </Box>
    </Box>
}

export default EnterpriseCollaborationRequestForm
import React, {memo} from "react";

import userPicture from "../../assets/user.png";

import "./usercard.css";
import S3Image from "./S3Image";
import {Box} from "@mui/material";
import _ from "lodash";

export const UserCard = memo(function UserCard(
    {
        user,
        hideLabel = false,
        className = "",
        ...props
    }
) {
    return (
        <div className={`user-card ${className}`}>
            {user?.picture ? (
                <>
                    <S3Image value={user.picture} {...props}/>
                </>
            ) : (
                <img
                    src={userPicture}
                    alt="User Picture"
                    className="user-card-image"
                />
            )}
            {
                !hideLabel && (
                    <Box sx={{textWrap: "wrap", pl: 0.5}}>
                        {user?.fullName
                            ? <span>{user?.fullName}</span>
                            : <span>{user?.firstName} {user?.lastName}</span>
                        }
                    </Box>
                )
            }
        </div>
    );
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});

import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";

import {saveToken} from "../../../utils/actions/authActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

import {MessageComponent} from "../../UI/MessageComponent";
import {FieldHolder} from "../FieldHolder";

import "./signupform.css";

import {loginUser} from "../../../services/userService";
import {Button} from "@mui/material";
import Functions from "../../../utils/Functions";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";

export const LoginForm = (props) => {
    // Gestion des traductions
    const {t} = useTranslation();

    const {
        setFreelanceData,
        setFreelanceEditPermissions
    } = useFreelanceProfileStore();

    // // Gestion de l'envoi du formulaire signup
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [message, setMessage] = useState({});

    useEffect(() => {
        setFreelanceData({});
        setFreelanceEditPermissions([]);
    }, []);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleLogin = (e) => {
        e.preventDefault();
        // Effectue la requête API POST avec les données du formulaire
        const login = async () => {
            setLoading(true);
            try {
                const responseData = await loginUser(formData);
                setLoading(false);
                if (responseData.success) {
                    dispatch(
                        saveToken({
                            token: responseData.token,
                            userId: responseData.userId,
                            userFirstName: responseData.userFirstName,
                            userLastName: responseData.userLastName,
                            freelanceId: responseData.freelanceId,
                            enterpriseId: responseData.enterpriseId,
                            companyId: responseData.companyId,
                            companyRole: responseData.companyRole,
                            userType: responseData.userType,
                            displayConfig: responseData.displayConfig,
                            fullAccess: responseData.fullAccess
                        })
                    );

                    if (!responseData.onboardingComplete) {
                        navigate("/onboarding");
                    } else {
                        // Après une authentification réussie, redirigez l'utilisateur vers la dernière page visitée.
                        const lastVisitedPage = location.state?.lastVisitedPage;
                        if (lastVisitedPage && lastVisitedPage !== "/") {
                            navigate(lastVisitedPage);
                        } else {
                            // Si lastVisitedPage n'est pas défini, redirigez l'utilisateur vers la page d'accueil par défaut.
                            if (responseData.userType === Functions.SESSION_TYPE_ENTERPRISE) {
                                navigate("/talents/talentboard");
                            } else {
                                navigate("/profile");
                            }
                        }
                    }
                } else {
                    setMessage({
                        type: "error",
                        message: t(responseData.error),
                    });
                }
            } catch (error) {
                // Gérer l'erreur
            }
        };

        login();
    };

    return (
        <div className="centerformcard">
            <form className="inputform standardform" onSubmit={handleLogin}>
                <FieldHolder
                    fielddesc={t("Email")}
                    type="text"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    mandatoryFlag={true}
                />
                <FieldHolder
                    fielddesc={t("Password")}
                    type="password"
                    name="password"
                    id="password"
                    placeholder={t("************")}
                    value={formData.password}
                    onChange={handleChange}
                    mandatoryFlag={true}
                />
                <p className="indexlink" onClick={props.toggleResetPasswordVisibility}>
                    {t("First connection / forgot password?")}
                </p>
                <div className="buttonbox">
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={loading}
                        fullWidth
                        disableRipple
                    >
                        {t("Connect")}
                    </Button>

                </div>
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />
                <div className="field">
                    <p className="indexlink" onClick={props.toggleLoginVisibility}>
                        {t("Create a new account")}
                    </p>
                </div>
            </form>
        </div>
    );
};

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useTranslation} from "react-i18next";

import './../index.css';
import './../page.css';
import "../../components/Forms/form.css";

import logo from '../../assets/smarteem_logo.svg';
import {MessageComponent} from "../../components/UI/MessageComponent";
import {useDispatch} from "react-redux";
import {
    fetchCustomerEvaluation,
    updateCustomerEvaluation,
} from "../../services/freelanceEvaluationService";
import {Box, Grid, Stack, Typography} from "@mui/material";
import Utils from "../../utils/Utils";
import ResponsiveComponent from "../../components/UI/ResponsiveComponent";
import logosmall from "../../assets/smarteem_logo_small.png";
import {AssessmentForm} from "../../components/Forms/Mission/Sections/AssessmentSection/AssessmentForm";


export const PublicFreelanceEvaluation = () => {
    const {t} = useTranslation();
    const {id} = useParams();

    const [message, setMessage] = React.useState({});
    const [evaluation, setEvaluation] = useState({});

    const getEvaluation = async () => {
        try {
            const fetchedData = await fetchCustomerEvaluation(id);
            if (!fetchedData.error) {
                if (fetchedData.assessmentFreelanceStatus === true) {
                    setMessage({type: 'error', message: t("This review link has already been used")});
                }

                setEvaluation(fetchedData);
            } else {
                setMessage({type: 'error', message: fetchedData?.error});
            }
        } catch (error) {
            setMessage({type: 'error', message: error.message});
        }
    }

    useEffect(() => {
        (async () => {
            await getEvaluation();
        })()
    }, []);


    const handleAssessmentSaveFinish = (success) => {
        if (success) {
            console.log('Finish');
        }
    };

    return (
        <>
            <div className="onboarding-row-container layout">
                <ResponsiveComponent mobile>
                    <Box sx={{width: "100%", padding: "10px"}}>
                        <img
                            src={logosmall}
                            alt='Smarteem Logo'
                            className='logosmall'
                        />
                    </Box>
                </ResponsiveComponent>

                <ResponsiveComponent tablet desktop>
                    <div className="onboarding-column-container onboarding-column-left">
                        <img
                            src={logo}
                            alt='Smarteem Logo'
                            height={200}
                            className='app-bar-logo'
                        />
                        <div className="onboarding-welcome-message fsize-25">{t("Welcome to Smarteem")}</div>
                        <div className="onboarding-welcome-message  fsize-25"></div>
                    </div>
                </ResponsiveComponent>

                <div className="onboarding-column-container onboarding-column-right">
                    <div className="onboarding-card-w100">
                        <div style={{marginTop: "15px"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <MessageComponent type={message.type} message={message.message} width="90%"/>
                                    </div>
                                </Grid>

                                {evaluation?.id && !evaluation?.assessmentFreelanceStatus && (
                                    <>
                                        <Grid item xs={12}>
                                            <Stack alignItems="center">
                                                <Typography variant="h6" color="primary" sx={{fontWeight: "bold"}}>
                                                    {t("Assessment of")} {evaluation?.Mission?.StaffedFreelance?.User.firstName} {" "} {t("for assignment")} {" "} "{evaluation?.Mission?.name}"
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item container justifyContent="center" xs={12}>
                                            <Box className="standardform standardform-w70">
                                                <div className="card standardform-card card-padding-wide">
                                                    <AssessmentForm
                                                        assessment={evaluation}
                                                        canModify={true}
                                                        onSaveFinish={handleAssessmentSaveFinish}
                                                    />
                                                </div>
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
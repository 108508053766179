import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Button, Chip, IconButton} from "@mui/material";
import {useSelector} from "react-redux";
import {
    deleteCooptation,
    fetchFreelanceAffiliations,
    fetchFreelanceCooptations
} from "../../../../services/collaborationService";
import Popup from "../../../Popup/Popup";
import CooptationForm from "./CooptationSection/CooptationForm";
import {Table, TableActionMenu} from "../../../Tables/Table";
import Utils from "../../../../utils/Utils";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const CooptationSection = ({formData, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const freelanceId = formData?.id;

    const [cooptations, setCooptations] = useState([]);
    const defaultValue = {
        firstName: '',
        lastName: '',
        email: null,
        phoneNumber: '',
        workedWithFreelancer: '',
        language: '',
        recommendationProbability: '',
        recommendationDescription: '',
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });

    const columns = [
        {
            field: 'FreelanceProfile.User.fullName',
            headerName: t("Name"),
            type: 'string',
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return (
                    <Box sx={{textWrap: "wrap"}}>
                        {row?.FreelanceProfile?.User?.fullName}
                    </Box>
                );
            }
        },
        {
            field: 'FreelanceProfile.User.email',
            headerName: t("Email"),
            type: 'string',
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const email = row?.FreelanceProfile?.User?.email;
                return (
                    <div className="MuiDataGrid-cellContent" title={email} role="presentation">
                        {email}
                    </div>
                );
            }
        },
        {
            field: 'FreelanceProfile.User.phoneNumber',
            headerName: t("Phone number"),
            type: 'string',
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <Box sx={{textWrap: "wrap"}}>
                        {row?.FreelanceProfile?.User?.phoneNumber}
                    </Box>
                );
            }
        },
        {
            field: 'workedWithFreelancer',
            type: 'boolean',
            headerName: t("Collaboration"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.workedWithFreelancer ? t("Yes") : t("No")
            }
        },
        {
            field: 'recommendationProbability',
            headerName: t("Recommendation"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                const nps = row?.recommendationProbability;
                return nps !== null && typeof nps !== "undefined"
                    ? <Chip
                        label={+parseFloat(nps).toFixed(1)}
                        className={Utils.getRecommendationStatus(parseFloat(nps))}
                    />
                    : "-";
            }
        },
    ];
    if (canModify) {
        columns.push({
            field: 'action',
            headerName: '',
            minWidth: 80,
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <TableActionMenu
                        row={row}
                        onEdit={!row.onboardingComplete ? handleEdit : null}
                        onDelete={handleDelete}
                    />
                );
            },
        });
    }

    const loadCooptations = useCallback((query = {}) => {
        try {
            fetchFreelanceCooptations(freelanceId, query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    setRowCount(fetchedData.count);
                    setCooptations(fetchedData.data);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [token, freelanceId]);

    useEffect(() => {
        loadCooptations(filterConfigs);
    }, [filterConfigs, loadCooptations]);

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleEdit = (id) => {
        const index = cooptations.findIndex((cooptation) => cooptation.id === id);
        if (index > -1) {
            const _data = cooptations[index];
            const userData = _data?.FreelanceProfile?.User;
            setEditingData({
                id: _data.id,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                email: userData?.email,
                phoneNumber: userData?.phoneNumber,
                workedWithFreelancer: _data.workedWithFreelancer,
                language: _data.language,
                recommendationProbability: _data.recommendationProbability,
                recommendationDescription: _data.recommendationDescription,
            });
            setEditingMode(true);
        }
    }

    const handleDelete = async (id) => {
        await deleteCooptation(id, token);
        loadCooptations(filterConfigs);
    }

    const handleDialogClose = () => {
        setEditingMode(false);
    };

    const handleSaveFinish = (success) => {
        if (success) {
            loadCooptations(filterConfigs);
            handleDialogClose();
        }
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return <>
        <div className='card transparent card-padding-wide'>
            {canModify &&
                <Box style={{width: '100%', marginBottom: "15px", textAlign: "right"}}>
                    <Button color="secondary" variant="contained"
                            onClick={handleCreate}>
                        {t("Hiring a freelancer")}
                    </Button>

                    <Popup
                        title={t("Hiring a freelancer")}
                        open={isEditingMode}
                        onDialogClose={handleDialogClose}
                    >
                        <CooptationForm
                            cooptation={editingData}
                            onSaveFinish={handleSaveFinish}
                            token={token}
                        />
                    </Popup>
                </Box>
            }

            <Table
                columns={columns}
                data={cooptations}
                rowCount={rowCount}
                pageSize={pageSize}
                filterConfigs={filterConfigs}
                onChange={handleDataGridChange}
            />
        </div>
    </>
};
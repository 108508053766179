import React, {memo, useCallback} from 'react';
import WordCloud from 'react-d3-cloud';
import {scaleOrdinal} from 'd3-scale';
import {schemeCategory10} from 'd3-scale-chromatic';

const WordCloudChart = memo(function WordCloudChart({data, height}) {
    const fontSize = useCallback((word) => Math.log2(word.value * 7) * 6, []);
    const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10).domain([...Array(10).keys()]);

    return (
        <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{height: '100%', width: '100%'}}>
                <WordCloud
                    data={data}
                    height={height}
                    font="Poppins"
                    fontWeight="bold"
                    fontSize={fontSize}
                    spiral="rectangular"
                    rotate={(word) => 0}
                    padding={5}
                    random={Math.random}
                    fill={d => schemeCategory10ScaleOrdinal(d.text)}
                />
            </div>
        </div>
    );
}, () => {
    return true;
});

export default WordCloudChart;

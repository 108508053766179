import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import "./page.css";
import "./onboarding.css";
import logosmall from "../assets/smarteem_logo_small.png";

import {LogoutComponent} from "../components/UI/LogoutComponent";
import {UserForm} from "../components/Forms/Configuration/UserForm";
import {CompanyForm} from "../components/Forms/Configuration/CompanyForm";
import {LanguageSelector} from "../components/UI/LanguageSelector";
import {Box, Button, Divider, Stack} from "@mui/material";
import {FreelanceProfileSteps} from "../components/Forms/Onboarding/FreelanceProfileSteps";
import {updateUser} from "../services/userService";
import ResponsiveComponent from "../components/UI/ResponsiveComponent";
import ResponsiveStepper from "../components/UI/ResponsiveStepper";
import Functions from "../utils/Functions";

export const Onboarding = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const checkStepRef = useRef();
    const onboardingScrollContainerRef = useRef(null);
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    let steps;
    if (isEnterpriseSession) {
        steps = [
            {key: 'AccountConfigSection', name: 'Account configuration'},
            {key: 'CompanyProfileSection', name: 'Company profile'},
        ];
    } else {
        steps = [
            {key: 'AccountConfigSection', name: 'Account configuration'},
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'My profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
            {key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT, name: 'Informations and documents'},
        ];
    }

    const activeStepKey = steps[activeStep].key;

    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        if (onboardingScrollContainerRef.current) {
            onboardingScrollContainerRef.current.scrollTop = 0;
        }
    };

    const handleNext = () => {
        checkStepRef.current();
    };

    const handleSubmit = async (response) => {
        if (response.success) {
            if (isLastStep()) {
                await updateUser({userData: {onboardingComplete: true}, token: token});
                if (isEnterpriseSession) {
                    navigate("/missions/missionsboard");
                } else {
                    navigate("/profile");
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

                if (onboardingScrollContainerRef.current) {
                    onboardingScrollContainerRef.current.scrollTop = 0;
                }
            }
        }
    }

    return (
        <div className="onboarding-row-container layout">
            <ResponsiveComponent mobile>
                <Box sx={{width: "100%", padding: "10px"}}>
                    <img
                        src={logosmall}
                        alt='Smarteem Logo'
                        className='logosmall'
                    />
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem/>}
                        justifyContent="center"
                        columnGap={2}
                        sx={{marginTop: "15px"}}
                    >
                        <LanguageSelector/>
                        <LogoutComponent/>
                    </Stack>
                </Box>
            </ResponsiveComponent>

            <ResponsiveComponent tablet desktop>
                <Box className="onboarding-column-container onboarding-column-left">
                    <img
                        src={logosmall}
                        alt='Smarteem Logo'
                        className='logomedium'
                    />
                    <h1 className="onboarding-welcome-message">{t("Welcome to Smarteem community")}</h1>
                    <div className="onboarding-column-container" style={{overflow: "hidden"}}>
                        <LanguageSelector/>
                        <LogoutComponent/>
                    </div>
                </Box>
            </ResponsiveComponent>

            <div className="onboarding-column-container onboarding-column-right" ref={onboardingScrollContainerRef}>
                <div className="pageheader">
                </div>
                <div className="onboarding-card-w100">
                    <Box sx={{width: {xs: "100%", md: "70%"}}}>
                        <ResponsiveStepper
                            steps={steps}
                            index={activeStep}
                            onStepClick={setActiveStep}
                        />
                    </Box>

                    <>
                        <div className="form-layout" style={{marginTop: "2vh"}}>
                            {activeStepKey === "AccountConfigSection" &&
                                <UserForm
                                    ref={checkStepRef}
                                    setLoading={setLoading}
                                    onSubmit={handleSubmit}
                                    className="standardform-w70"
                                />
                            }
                            {activeStepKey !== "AccountConfigSection" && sessionType === "Freelance" &&
                                <FreelanceProfileSteps
                                    ref={checkStepRef}
                                    activeStepKey={activeStepKey}
                                    setLoading={setLoading}
                                    onSubmit={handleSubmit}
                                />
                            }
                            {activeStepKey !== "AccountConfigSection" && sessionType === "Enterprise" &&
                                <CompanyForm
                                    ref={checkStepRef}
                                    setLoading={setLoading}
                                    onSubmit={handleSubmit}
                                    className="standardform-w70"
                                />
                            }
                        </div>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: {xs: "100%", md: "70%"},
                            paddingTop: {xs: "10px", md: "25px"}
                        }}>
                            <Button
                                variant="contained"
                                color="light"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{mr: 1}}
                            >
                                {t("Previous")}
                            </Button>
                            <Box sx={{flex: '1 1 auto'}}/>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="secondary"
                                onClick={handleNext}>
                                {activeStep === steps.length - 1 ? t("Validate") : t("Next")}
                            </Button>
                        </Box>
                    </>
                </div>
            </div>
        </div>
    );
};

import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {FieldHolder} from "../../../../FieldHolder";

export const FreelanceCvCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const files = [];
    const [editMode, setEditMode] = useState(false);
    const [cvFile, setCvFile] = useState(null);

    useEffect(() => {
        if (editMode) {
            setCvFile(null);
        }
    }, [editMode]);

    const handleSave = async () => {
        files.push(cvFile);

        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields: [],
            freelanceProfileFiles: files
        });
        if (result.success) {
            setFreelanceData(result.data);
        }
    };

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            <FieldHolder
                fielddesc={t("CV")}
                type="file"
                name="curriculum_vitae"
                id="curriculum_vitae"
                value={freelanceProfileData.curriculum_vitae}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setCvFile}
                canModify={editMode}
            />
        </CustomCard>
    </>
};
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import Functions from "../../../../../utils/Functions";
import React, {useEffect, useRef, useState} from "react";
import {fetchEnterpriseProfiles} from "../../../../../services/enterpriseProfileService";
import {useSelector} from "react-redux";
import {FreelanceSelectionPopup} from "../../../../Popup/FreelanceSelectionPopup";
import {fetchFreelanceProfiles} from "../../../../../services/freelanceProfileService";
import {UserCard} from "../../../../UI/UserCard";
import skillService from "../../../../../services/skillService";
import {Table} from "../../../../Tables/Table";
import {
    createShortlistedFreelance, deleteShortlistedFreelance,
    fetchMissionShortlistedFreelances
} from "../../../../../services/missionShortlistedFreelance";
import Utils from "../../../../../utils/Utils";
import {MdDelete} from "react-icons/md";
import Popup from "../../../../Popup/Popup";
import {ProposeMissionForm} from "./ProposeMissionForm";
import {useToast} from "../../../../../context/ToastProvider";
import {CustomCard} from "../../../../UI/CustomCard";
import TagService from "../../../../../services/tagService";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const MissionDetailSection = (
    {
        missionId,
        formData,
        setFormData,
        company,
        handleChange,
        onSave,
        sessionType,
        staffedFreelanceRef,
        onMissionFormatChange,
        canModify
    }
) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const token = useSelector((state) => state.auth.token);

    const [showProposeMissionDialog, setShowProposeMissionDialog] = useState(false);
    const [showFreelanceSelectionDialog, setShowFreelanceSelectionDialog] = useState(false);

    const [editMode, setEditMode] = useState(canModify && !missionId);
    const [staffedFreelanceEditMode, setStaffedFreelanceEditMode] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [missionManagers, setMissionManagers] = useState([]);
    const [missionStaffedFreelances, setMissionStaffedFreelances] = useState([]);
    const [tags, setTags] = useState([]);
    const [skillsData, setSkillsData] = useState([]);
    const [shortlistedFreelances, setShortlistedFreelances] = useState([]);
    const [selectedShortlistedFreelances, setSelectedShortlistedFreelances] = useState([]);

    const [shortlistedFreelancesRowSelectionModel, setShortlistedFreelancesRowSelectionModel] = useState([]);

    const fetchMissionShortlistedFreelancesRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });

    const descriptionRef = useRef(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const columns = [
        {
            field: 'FreelanceProfile.User.fullName',
            type: 'string',
            headerName: t("Name"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const user = row?.FreelanceProfile?.User;
                return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
            }
        },
        {
            field: 'proposalDate',
            type: 'string',
            headerName: t("Proposed assignment"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.proposalDate && new Date(row.proposalDate),
            renderCell: ({row}) => {
                return row.proposalDate ? t('Yes') + " - " + Utils.formatDate(row.proposalDate) : t('No');
            }
        },
        {
            field: 'ProposalSender.User.fullName',
            type: 'string',
            headerName: t("Proposal sender"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.proposalSender ? row.ProposalSender.User.fullName : t('Not specified singular')
            }
        },
        {
            field: 'freelanceInterest',
            type: 'string',
            headerName: t("Freelance interest"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.freelanceInterest ? t(row.freelanceInterest) : t('Not specified singular')
            }
        },
        {
            field: 'action',
            headerName: '',
            minWidth: 60,
            flex: 0.6,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return !row.proposalDate && (
                    <IconButton onClick={() => handleDeleteShortlistedFreelance(row.id)} aria-label="delete">
                        <MdDelete/>
                    </IconButton>
                );
            }
        }
    ];

    useEffect(() => {
        if (editMode) {
            setFormErrors({});

            (async () => {
                const fetchedTags = await TagService.getMissionTags({}, token);
                if (!fetchedTags?.error) {
                    const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                    setTags(fetchedTagsData);
                }
            })();
        }
    }, [editMode]);

    useEffect(() => {
        (async () => {
            if (token) {
                const fetchedEnterpriseProfiles = await fetchEnterpriseProfiles({}, token);
                const fetchedEnterpriseProfilesData = fetchedEnterpriseProfiles?.data
                    .filter(e => e.companyRole !== Functions.USER_ROLE.READER)
                    .map(({User, ...rest}) => ({
                        id: rest.id,
                        name: User.fullName
                    }));
                setMissionManagers(fetchedEnterpriseProfilesData);

                if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
                    const fetchedFreelanceProfiles = await fetchFreelanceProfiles({}, token);
                    const fetchedFreelanceProfilesData = fetchedFreelanceProfiles.data.map(({User, ...rest}) => ({
                        id: rest.id,
                        name: User.fullName,
                        User
                    }));
                    setMissionStaffedFreelances(fetchedFreelanceProfilesData);
                }

                let defaultSkills = await skillService.getAllDefaultSkill(token);
                defaultSkills = defaultSkills.map((skill) => {
                    return {...skill, group: t(skillService.getSkillTypeLabel(skill.type))};
                });
                setSkillsData(defaultSkills);
            }
        })();
    }, [token, t, sessionType]);

    useEffect(() => {
        fetchMissionShortlistedFreelancesRef.current(filterConfigs);
    }, [formData.id, filterConfigs]);

    fetchMissionShortlistedFreelancesRef.current = async (query = {}) => {
        try {
            if (typeof formData.id !== "undefined" && formData.id && sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
                const fetchedData = await fetchMissionShortlistedFreelances(formData.id, query, token);
                setRowCount(fetchedData.count);
                setShortlistedFreelances(fetchedData.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteShortlistedFreelance = async (id) => {
        await deleteShortlistedFreelance(id, token);
        fetchMissionShortlistedFreelancesRef.current();
    };

    const handleDataGridChange = (filterConfigs) => {
        fetchMissionShortlistedFreelancesRef.current(filterConfigs);
    };

    const handleFreelanceSelection = async (selFreelances) => {
        for (const selFreelance of selFreelances) {
            await createShortlistedFreelance({
                MissionId: formData.id,
                FreelanceProfileId: selFreelance.id
            }, token);
        }

        fetchMissionShortlistedFreelancesRef.current(filterConfigs);
        handleFreelanceSelectionDialogClose();
    };

    const handleProposeMissionSaveFinish = (success) => {
        if (success) {
            setShortlistedFreelancesRowSelectionModel([]);
            setSelectedShortlistedFreelances([]);
            fetchMissionShortlistedFreelancesRef.current(filterConfigs);
            handleProposeMissionDialogClose();
        }
    };

    const handleFreelanceSelectionDialogShow = () => {
        if (formData.id) {
            setShowFreelanceSelectionDialog(true);
        } else {
            showToast(
                t("To shortlist freelancers, you must complete and save the assignment details"),
                "warning",
                10000
            );
        }
    };

    const handleFreelanceSelectionDialogClose = () => {
        setShowFreelanceSelectionDialog(false);
    };

    const handleProposeMissionDialogClose = () => {
        setShowProposeMissionDialog(false);
    };

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        setShortlistedFreelancesRowSelectionModel(newRowSelectionModel);
        const _shortlistedFreelances = shortlistedFreelances.filter(
            (item) => newRowSelectionModel.includes(item.id)
        );
        setSelectedShortlistedFreelances(_shortlistedFreelances);
    };

    const handleSave = async (staffedFreelance = false) => {
        if (descriptionRef.current) {
            const editor = descriptionRef.current.getEditor();
            editor.blur();
        }
        const result = onSave && await onSave();
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            if (staffedFreelance) {
                setStaffedFreelanceEditMode(false);
            } else {
                setEditMode(false);
            }
        }
    };

    return <>
        {
            sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
            company?.missionContractInvoiceCreation && (
                <div className="card standardform-card card-padding-wide"
                     style={{padding: "10px 20px", alignItems: "center"}}>
                    <Stack direction="column" alignItems="center" spacing={1}>
                        <Typography variant="label">{t("Assignment format")}</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={formData?.format}
                            exclusive
                            onChange={onMissionFormatChange}
                            aria-label={t("Assignment format")}
                            className="mission-format"
                            orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                        >
                            <ToggleButton value={Functions.MISSION_FORMAT.OPERATIONAL} disableRipple>
                                {t("Operational assignment")}
                            </ToggleButton>
                            <ToggleButton value={Functions.MISSION_FORMAT.CONTRACT_WITHOUT_INVOICE} disableRipple>
                                {t("Assignment without invoices")}
                            </ToggleButton>
                            <ToggleButton value={Functions.MISSION_FORMAT.CONTRACT_INVOICE} disableRipple>
                                {t("Assignment with contract and invoices")}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </div>
            )}

        <CustomCard
            className="justify-content-center"
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={canModify && missionId}
            onSave={handleSave}
        >
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Assignment name")}
                        type="text"
                        name="name"
                        id="name"
                        defaultValue={formData?.name}
                        onBlur={handleChange}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.name}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12} md={4}>
                    <FieldHolder
                        fielddesc={t("Assignment Manager")}
                        type="dropdown"
                        name="operational_manager"
                        id="operational_manager"
                        value={formData?.operational_manager}
                        datas={missionManagers}
                        formData={formData}
                        setFormData={setFormData}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.operational_manager}
                    />
                </Grid>
                {
                    sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
                    [
                        Functions.MISSION_FORMAT.CONTRACT_INVOICE,
                        Functions.MISSION_FORMAT.CONTRACT_WITHOUT_INVOICE
                    ].includes(formData?.format) && (
                        <>
                            <Grid item xs={12} md={4}>
                                <FieldHolder
                                    fielddesc={t("Contracting manager")}
                                    type="dropdown"
                                    name="hr_contact"
                                    id="hr_contact"
                                    value={formData?.hr_contact}
                                    datas={missionManagers}
                                    formData={formData}
                                    setFormData={setFormData}
                                    canModify={editMode}
                                    mandatoryFlag={true}
                                    error={formErrors.hr_contact}
                                />
                            </Grid>
                            {formData?.format === Functions.MISSION_FORMAT.CONTRACT_INVOICE && (
                                <Grid item xs={12} md={4}>
                                    <FieldHolder
                                        fielddesc={t("Invoice Manager")}
                                        type="dropdown"
                                        name="invoicing_contact"
                                        id="invoicing_contact"
                                        datas={missionManagers}
                                        value={formData?.invoicing_contact}
                                        formData={formData}
                                        setFormData={setFormData}
                                        canModify={editMode}
                                        mandatoryFlag={true}
                                        error={formErrors.invoicing_contact}
                                    />
                                </Grid>
                            )}
                        </>
                    )
                }
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12} md={4}>
                    <FieldHolder
                        fielddesc={t("Assignment type")}
                        type="dropdown"
                        name="deadline"
                        id="deadline"
                        set="AssignmentType"
                        value={formData?.deadline}
                        formData={formData}
                        setFormData={setFormData}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.deadline}
                    />
                </Grid>
                <Grid item xs={12} md={4}
                >
                    <FieldHolder
                        fielddesc={t("Starting date")}
                        type="date"
                        name="start_date"
                        id="start_date"
                        value={formData?.start_date}
                        onChange={handleChange}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.start_date}
                    />
                </Grid>
                {(formData.deadline === Functions.TEXT_CDD || formData?.estimated_end_date) && (
                    <Grid item xs={12} md={4}>
                        <FieldHolder
                            fielddesc={t("End date")}
                            type="date"
                            name="estimated_end_date"
                            id="estimated_end_date"
                            value={formData?.estimated_end_date}
                            onChange={handleChange}
                            canModify={editMode}
                            mandatoryFlag={true}
                            error={formErrors.estimated_end_date}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Staffing rate (%)")}
                        type="number"
                        name="estimatedStaffingRate"
                        id="estimatedStaffingRate"
                        value={formData?.estimatedStaffingRate}
                        min={1}
                        max={100}
                        unit="%"
                        onChange={handleChange}
                        canModify={editMode}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                {sessionType === Functions.SESSION_TYPE_ENTERPRISE && (
                    <>
                        <Grid item xs={12} md={4}>
                            <FieldHolder
                                fielddesc={t("Budget")}
                                type="number"
                                name="budget"
                                id="budget"
                                value={formData?.budget}
                                onChange={handleChange}
                                canModify={editMode}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FieldHolder
                                fielddesc={t("Budget currency")}
                                type="dropdown"
                                name="budgetCurrency"
                                id="budgetCurrency"
                                set="Exchange"
                                value={formData?.budgetCurrency}
                                formData={formData}
                                setFormData={setFormData}
                                canModify={editMode}
                            />
                        </Grid>
                        <Grid item xs={12} md>
                            <FieldHolder
                                fielddesc={t("Purchase order number")}
                                type="text"
                                name="purchaseOrderNumber"
                                id="purchaseOrderNumber"
                                defaultValue={formData?.purchaseOrderNumber}
                                onBlur={handleChange}
                                canModify={editMode}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Assignment location")}
                        type="text"
                        name="address"
                        id="address"
                        defaultValue={formData?.address}
                        onBlur={handleChange}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.address}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        ref={descriptionRef}
                        fielddesc={t("Activity deliverable")}
                        type="wysiwyg"
                        name="description"
                        id="description"
                        value={formData?.description}
                        onBlur={handleChange}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.description}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Final customer")}
                        type="dropdown"
                        name="MissionTags.mission_final_customer"
                        id="MissionTags.mission_final_customer"
                        value={formData?.MissionTags?.mission_final_customer}
                        datas={tags?.mission_final_customer}
                        formData={formData}
                        setFormData={setFormData}
                        ismulti={true}
                        canModify={editMode}
                        allowAdd={true}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Skills required")}
                        type="dropdown"
                        name="Skills"
                        id="Skills"
                        datas={skillsData}
                        value={formData?.Skills}
                        formData={formData}
                        setFormData={setFormData}
                        ismulti={true}
                        canModify={editMode}
                        allowGroup={true}
                    />
                </Grid>
            </Grid>
        </CustomCard>

        {editMode && !missionId && (
            <Stack alignItems="center" className="w-100">
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => handleSave()}
                >
                    {t("Save")}
                </Button>
            </Stack>
        )}

        {sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
            <div className="card standardform-card card-padding-wide">
                <Stack justifyContent="start" sx={{width: "100%"}}>
                    <Typography variant="label">{t("Preselected freelancer")}</Typography>
                </Stack>

                {canModify && (
                    <Stack direction="row" justifyContent="end" spacing={1} sx={{width: "100%"}}>
                        {selectedShortlistedFreelances.length > 0 &&
                            <>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setShowProposeMissionDialog(true)}
                                    size="small"
                                >
                                    {t("Propose the assignment")}
                                </Button>

                                <Popup
                                    title={t("Proposing assignments to freelancers")}
                                    open={showProposeMissionDialog}
                                    onDialogClose={handleProposeMissionDialogClose}
                                >
                                    <ProposeMissionForm
                                        shortlistedFreelances={selectedShortlistedFreelances}
                                        onSaveFinish={handleProposeMissionSaveFinish}
                                    />
                                </Popup>
                            </>
                        }
                        <>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleFreelanceSelectionDialogShow}
                                size="small"
                            >
                                {t("Pre-selecting a freelancer")}
                            </Button>

                            <FreelanceSelectionPopup
                                open={showFreelanceSelectionDialog}
                                title={t("Select freelancers")}
                                onDialogClose={handleFreelanceSelectionDialogClose}
                                values={[]}
                                excludes={shortlistedFreelances.map((item) => item.FreelanceProfile.id)}
                                onSelect={handleFreelanceSelection}
                            />
                        </>
                    </Stack>
                )}

                <Table
                    className="MuiDataGrid-cell-padding-sm"
                    columns={columns}
                    data={shortlistedFreelances}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    setFilterConfigs={setFilterConfigs}
                    checkboxSelection={Boolean(formData.id)}
                    isRowSelectable={(params) => !params.row.proposalDate}
                    onChange={handleDataGridChange}
                    rowSelectionModel={shortlistedFreelancesRowSelectionModel}
                    onRowSelectionModelChange={handleRowSelectionModelChange}
                    hideFilter={true}
                />
            </div>
        }

        {sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
            <CustomCard
                className="justify-content-center"
                editMode={staffedFreelanceEditMode}
                setEditMode={setStaffedFreelanceEditMode}
                canModify={canModify && missionId && !staffedFreelanceRef.current}
                onSave={() => handleSave(true)}
            >
                <Grid container spacing={{xs: 2, md: 3}}>
                    <Grid item container justifyContent="start" xs={12}>
                        {canModify && !staffedFreelanceRef.current ? (
                            <FieldHolder
                                fielddesc={t("Staffed freelancer")}
                                type="dropdown"
                                name="staffedFreelanceId"
                                id="staffedFreelanceId"
                                formData={formData}
                                value={formData?.staffedFreelanceId}
                                datas={missionStaffedFreelances}
                                setFormData={setFormData}
                                canModify={staffedFreelanceEditMode}
                                mandatoryFlag={true}
                                error={formErrors.staffedFreelanceId}
                                renderOption={(props, option) => (
                                    <Box component="li" key={option.id}
                                         sx={{display: 'flex', alignItems: 'center'}} {...props}>
                                        <UserCard user={option.User} displaySkeleton={true}/>
                                    </Box>
                                )}
                            />
                        ) : (
                            <>
                                <Stack direction="column" justifyContent="start" spacing={1} sx={{width: "100%"}}>
                                    <Typography variant="label">{t("Staffed freelancer")}</Typography>
                                    <Stack alignItems="start">
                                        {formData.StaffedFreelance
                                            ? <UserCard user={formData?.StaffedFreelance?.User} displaySkeleton={true}/>
                                            : "-"
                                        }
                                    </Stack>
                                </Stack>
                            </>
                        )}
                    </Grid>
                </Grid>
            </CustomCard>
        }
    </>
};
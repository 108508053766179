import React from 'react';
import {Step, StepButton, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";

const ResponsiveStepper = ({steps, index, stepKey, onStepClick}) => {
    const {t} = useTranslation();

    const getStepIndex = (key) => {
        return steps.findIndex(item => item.key === key);
    };

    let stepIndex = stepKey ? getStepIndex(stepKey) : index;

    return <>
        <Stepper
            alternativeLabel
            activeStep={stepIndex}
            style={{margin: "15px 0", width: "100%"}}
        >
            {steps.map((step, index) => (
                <Step key={index}>
                    <StepButton color="inherit" onClick={() => onStepClick(index)}>
                        {t(step.name)}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    </>
};

export default ResponsiveStepper;
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FaUserPlus} from "react-icons/fa";

import {HelperCard} from "../../../UI/HelperCard";
import {FieldHolder} from "../../FieldHolder";

import "./freelanceinfostep.css";
import {Box, Button, Grid} from "@mui/material";
import Functions from "../../../../utils/Functions";
import {addUserToCompany} from "../../../../services/userService";
import {useSelector} from "react-redux";
import {useToast} from "../../../../context/ToastProvider";

export const FreelanceAdding = ({email, onSaveFinish, onDialogClose}) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [messageData, setMessageData] = useState("");

    const handleChange = (e) => {
        setMessageData(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            (async () => {
                setLoading(true);
                const data = {
                    userData: {
                        email: email,
                        main_type: Functions.SESSION_TYPE_FREELANCE
                    },
                    freelanceProfile: {},
                    notificationMessage: messageData
                };

                const responseData = await addUserToCompany({userData: data,token: token});
                if (responseData.success) {
                    setLoading(false);

                    showToast(t("The freelancer has been added to your pool"));
                    onSaveFinish && onSaveFinish(true);
                }
            })();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{width: {md: "40vw"}, mt: 2}}>
            <form
                onSubmit={handleSubmit}
                noValidate
                style={{display: "flex", flexDirection: "column"}}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <HelperCard
                            icon={<FaUserPlus/>}
                            headerText={"Add the freelancer to your pool"}
                            descriptionText={"By clicking on ‘Validate’, the freelancer will be notified that they have been added to your pool of freelancers.\n\nYou can also send them a personalised message by filling in the field below."}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Message for the freelancer")}
                            type="multiline"
                            name="message"
                            id="message"
                            placeholder={t("Message")}
                            value={messageData}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <Button
                            variant="contained"
                            color="light"
                            onClick={onDialogClose}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >{t("Validate")}</Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

import Functions from "../utils/Functions";
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/tags/";

class TagService {

    getTags = async (entity, query, token) => {
        try {
            const params = new URLSearchParams(query);
            const url = `${BASE_URL}entity/${entity}?${params.toString()}`;

            return requestApi(url, 'GET', {}, token);
        } catch (error) {
            console.error('Error fetching tags:', error)
            throw error
        }
    };

    getFreelanceProfileTags = async (query, token) => {
        try {
            return this.getTags('freelance-profile', query, token);
        } catch (error) {
            console.error('Error fetching freelance profile tags:', error)
            throw error
        }
    };

    getMissionTags = async (query, token) => {
        try {
            return this.getTags('mission', query, token);
        } catch (error) {
            console.error('Error fetching mission tags:', error)
            throw error
        }
    };

    groupTagsByType = (tags) => {
        const tagTypes = Object.values(Functions.TAGS_TYPE);

        return tagTypes.reduce((groupedTags, type) => {
            groupedTags[type] = tags.filter(
                (value) => value.type === type
            )
            return groupedTags;
        }, {})
    };

    modifyTag = async (tagId, data, token) => {
        try {
            return requestApi(`${BASE_URL}modify/${tagId}`, 'PUT', data, token);
        } catch (error) {
            console.error('Error updating tag:', error);
            throw error;
        }
    };

    deleteTag = async (tagId, token) => {
        try {
            return requestApi(`${BASE_URL}delete/${tagId}`, 'DELETE', {}, token);
        } catch (error) {
            console.error("Error deleting tag :", error);
            throw error;
        }
    };
}

export default new TagService();
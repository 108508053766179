import React, {useRef} from "react";
import {Button, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MdAdd, MdClose} from "react-icons/md";
import {FieldHolder} from "../Forms/FieldHolder";
import Utils from "../../utils/Utils";
import {Display} from "./Display";

const Document = (
    {
        set = "",
        label,
        documents,
        formData,
        setFormData,
        canModify,
        ...props
    }
) => {
    const {t} = useTranslation();

    const addNewDocument = () => {
        if (typeof formData[set] !== "undefined") {
            const updatedDocuments = [...formData[set]];
            updatedDocuments.push({
                file: "",
                name: "",
                mandatory: false,
            });
            setFormData({...formData, [set]: updatedDocuments});
        }
    };

    return (
        <Stack
            direction="column"
            alignItems="flex-start"
            spacing={0.5}
            sx={{width: "100%"}}
        >
            <div>
                <Typography variant="label">
                    <span>{label}</span>
                </Typography>
            </div>
            <Stack
                direction="column"
                alignItems="start"
                variant="body1"
                sx={{width: "100%"}}
            >
                <div style={{width: "100%"}}>
                    {documents?.length === 0 && !canModify && (
                        <Typography>-</Typography>
                    )}
                    {documents?.map((document, index) => (
                        <DocumentRow
                            set={set}
                            key={index}
                            index={index}
                            document={document}
                            formData={formData}
                            setFormData={setFormData}
                            canModify={canModify}
                            {...props}
                        />
                    ))}
                </div>
                {canModify && (
                    <div style={{marginTop: "15px"}}>
                        <Button
                            variant="outlined"
                            startIcon={<MdAdd/>}
                            size="small"
                            onClick={addNewDocument}
                        >
                            {t("Add")}
                        </Button>
                    </div>
                )}
            </Stack>
        </Stack>
    );
};

const DocumentRow = (
    {
        set,
        index,
        document,
        formData,
        setFormData,
        canModify,
        ...props
    }
) => {
    const {t} = useTranslation();
    const deleteFileRef = useRef();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(Utils.updateNestedField(formData, name, value));
    };

    const handleDelete = () => {
        if (deleteFileRef.current) {
            deleteFileRef.current.click();
        }
        const updatedDocuments = [...formData[set]];
        updatedDocuments.splice(index, 1);
        setFormData({...formData, [set]: updatedDocuments});
    };

    return (
        <>
            {canModify && (
                <div
                    style={{
                        textAlign: "left",
                        marginTop: "10px",
                        border: "1px solid #09174736",
                        borderRadius: "1ch",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        gap: "20px",
                    }}
                >
                    <FieldHolder
                        fielddesc={t("Name")}
                        type="text"
                        name={`${set}.${index}.name`}
                        id={`documentName${index}`}
                        value={document.name}
                        onChange={handleChange}
                        canModify={canModify}
                        mandatoryFlag={false}
                    />
                    <FieldHolder
                        type="file"
                        name={`${set}.${index}.file`}
                        id={`documentFile${index}`}
                        value={document.file}
                        formData={formData}
                        setFormData={setFormData}
                        onFileChange={props.onFileChange}
                        canModify={canModify}
                        ref={deleteFileRef}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<MdClose/>}
                        onClick={handleDelete}
                        size="small"
                    >
                        {t("Delete")}
                    </Button>
                </div>
            )}

            {!canModify && (
                <div style={{marginTop: "10px"}}>
                    <Display
                        type="file"
                        label={document.name}
                        id={props.id}
                        value={document.file}
                        {...props}
                    />
                </div>
            )}
        </>
    );
};

export {Document, DocumentRow};

import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + '/api/reporting/';

/* Expense */

export const getTotalExpense = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}total-expense?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching total expense:', error);
        throw error;
    }
};

export const getTotalExpenseByMonth = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}total-expense-by-month?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching total expense by month:', error);
        throw error;
    }
};

export const getTotalExpenseByMission = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}total-expense-by-mission?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching total expense by mission:', error);
        throw error;
    }
};

export const getTotalExpenseByJob = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}total-expense-by-job?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching total expense by job:', error);
        throw error;
    }
};

export const getTotalExpenseByFreelance = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}total-expense-by-freelance?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching total expense by freelance:', error);
        throw error;
    }
};

export const getTotalExpenseByUser = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}total-expense-by-user?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching total expense by user:', error);
        throw error;
    }
};

/* Freelance */

export const getFreelanceData = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-data?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceByJob = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-job?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceBySkill = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-skill?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceAdrByJob = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-adr-by-job?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceBySeniority = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-seniority?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceByUser = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-user?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceByTag = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-tag?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceBySource = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-source?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getMissionByFreelance = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission-by-freelance?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};

export const getFreelanceByRecommendation = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance-by-recommendation?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching freelance data:', error);
        throw error;
    }
};
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Grid} from "@mui/material";
import {FieldHolder} from "../../../../FieldHolder";
import Utils from "../../../../../../utils/Utils";
import Functions from "../../../../../../utils/Functions";
import {createFile} from "../../../../../../services/fileService";
import {createMissionResource, updateMissionResource} from "../../../../../../services/missionResource";

export const OnboardingResourceForm = ({resource, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [resourceData, setResourceData] = useState(resource);
    const [resourceFile, setResourceFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const objectAttachedType = "missionResource";
    const requiredFields = ['title', 'file'];

    const handleChange = (e) => {
        const {name, value} = e.target;
        setResourceData({...resourceData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(resourceData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let data = {...resourceData};

            if (resourceFile) {
                const fileData = {
                    typeFile: Functions.TEXT_DOCUMENT,
                    file: resourceFile.theFile,
                };
                const result = await createFile(fileData, "", token);
                data.file = !result.error ? result : null;
            }

            let response;
            if (data.id) {
                response = await updateMissionResource(data.id, data, token);
            } else {
                response = await createMissionResource(data, token);
            }
            onSaveFinish && onSaveFinish(!response.error);

            setLoading(false);
        })()
    };

    return <Box sx={{width: {md: "25vw"}}}>
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            style={{display: "flex", flexDirection: "column"}}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Document title")}
                        type="text"
                        name="title"
                        id="title"
                        value={resourceData.title}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Description")}
                        type="multiline"
                        name="description"
                        id="description"
                        rows={2}
                        value={resourceData.description}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.description}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        type="file"
                        name="file"
                        id="file"
                        value={resourceData.file}
                        objectAttachedType={objectAttachedType}
                        formData={resourceData}
                        setFormData={setResourceData}
                        setFileInfo={setResourceFile}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.file}
                    />
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >{t("Save")}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>
};
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Grid} from "@mui/material";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {FieldHolder} from "../../../FieldHolder";
import Functions from "../../../../../utils/Functions";
import Utils from "../../../../../utils/Utils";
import {createSatisfaction, updateSatisfaction} from "../../../../../services/freelanceSatisfactionService";
import RatingSelector from "../../../../Inputs/RatingSelector";
import {Display} from "../../../../Inputs/Display";


export const FreelanceSatisfactionForm = ({feedback, canModify, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [message, setMessage] = useState({});
    const [feedbackData, setFeedbackData] = useState(feedback);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    const handleFeedbackChange = (e) => {
        const {name, value} = e.target;
        setFeedbackData({...feedbackData, [name]: value});
    };

    const handleRatingClick = (name, rating) => {
        if (canModify) {
            setFeedbackData({...feedbackData, [name]: rating});
        }
    };

    const handleFeedbackSave = async (e) => {
        e.preventDefault();

        let data = {...feedbackData};

        const verifyData = Utils.verifyDataFeedback(t, data, data.id ? "EDIT" : "ADD");
        if (verifyData.type === "error") {
            setMessage(verifyData);
        } else {
            try {
                setMessage({type: "loading", message: t("Saving data")});
                let result;
                if (data.id) {
                    data.assessmentFreelanceStatus = Functions.TEXT_VALIDATED;
                    result = await updateSatisfaction(data.id, data, token);
                } else {
                    result = await createSatisfaction(data, token);
                }
                if (result.error) {
                    setMessage({type: "error", message: result.error});
                } else {
                    setMessage({type: "success", message: t("Data saved successfully")});
                    onSaveFinish && onSaveFinish(true);
                }
            } catch (error) {

            }
        }
    };

    return <Box>
        <Grid container direction="column" spacing={2}>
            {message.type &&
                <Grid item xs={12}>
                    <MessageComponent
                        type={message.type}
                        message={message.message}
                        width="100%"
                    />
                </Grid>
            }

            {feedbackData?.id
                ? (
                    <>
                        <Grid item xs={12}>
                            <Display
                                label={t("Date")}
                                value={Utils.formatDate(feedbackData.createdAt)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RatingSelector
                                label={t("On a scale of 0 to 10, how much did you enjoy working on this assignment?")}
                                onChange={handleRatingClick}
                                name="nps"
                                value={feedbackData.nps}
                                min={0}
                                max={10}
                                canModify={isFreelanceSession}
                                justifyContent="start"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t('Why?')}
                                type="multiline"
                                name="comment"
                                id="comment"
                                value={feedbackData.comment}
                                onChange={handleFeedbackChange}
                                canModify={isFreelanceSession}
                            />
                        </Grid>
                    </>
                )
                : (
                    <>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FieldHolder
                                    fielddesc={t("Freelancer's first name")}
                                    type="text"
                                    name="assessorFirstName"
                                    id="assessorFirstName"
                                    value={feedbackData.assessorFirstName}
                                    onChange={handleFeedbackChange}
                                    canModify={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldHolder
                                    fielddesc={t("Freelancer's last name")}
                                    type="text"
                                    name="assessorLastName"
                                    id="assessorLastName"
                                    value={feedbackData.assessorLastName}
                                    onChange={handleFeedbackChange}
                                    canModify={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t('Message for the freelancer')}
                                type="multiline"
                                name="commentToTheFreelance"
                                id="commentToTheFreelance"
                                placeholder={t('Write here')}
                                value={feedbackData.commentToTheFreelance}
                                onChange={handleFeedbackChange}
                                canModify={isEnterpriseSession}
                                sx={{mt: 0.5}}
                            />
                        </Grid>
                    </>
                )
            }

            {canModify && (
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleFeedbackSave}
                    >
                        {t("Save")}
                    </Button>
                </Grid>
            )}
        </Grid>
    </Box>
};


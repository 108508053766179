import React from "react";
import {useTranslation} from "react-i18next";

import {MessageComponent} from '../../UI/MessageComponent';
import {FieldHolder} from '../FieldHolder';

import './signupform.css';

import {sendResetPasswordEmail} from '../../../services/userService';
import {Button} from "@mui/material";
import Mentions_d_information_Smarteem from "../../../assets/Mentions_d_information_Smarteem.pdf";


export const PasswordResetForm = (props) => {
    // Gestion des traductions
    const {t} = useTranslation();

    const [email, setEmail] = React.useState('');

    const [error, setError] = React.useState('');
    const [message, setMessage] = React.useState({});


    const handleResetPassword = async (e) => {
        e.preventDefault();
        // Envoie la demande de réinitialisation au serveur
        const reset = async () => {
            try {
                const responseData = await sendResetPasswordEmail(email);
                if (responseData.success) {
                    setMessage({type: "success", message: "E-mail envoyé avec succès"});
                } else {
                    setMessage({type: "error", message: "Utilisateur inconnu"});
                }
            } catch (error) {
                // Gérer l'erreur
            }
        };
        reset();
    };


    return (
        <div className='centerformcard'>
            <form className='inputform standardform' onSubmit={handleResetPassword}>
                <h3>{t('Enter your email:')}</h3>
                <FieldHolder
                    fielddesc={t("Email")}
                    type="text"
                    name="email"
                    id="01"
                    placeholder={t("jean.dupont@company.com")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    mandatoryFlag={true}
                />
                <a
                    className='indexlink'
                    href={Mentions_d_information_Smarteem}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t("To find out more about how your personal data is managed and to exercise your rights, please refer to the Smarteem information notice.")}
                </a>
                <div className='buttonbox'>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                    >
                        {t("Send a reset email")}
                    </Button>
                </div>
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />
                {error && <p className="error-message">{error}</p>}
                <div className='field'>
                    <p className='indexlink' onClick={props.toggleResetPasswordVisibility}>{t("Back to login")}</p>
                </div>
            </form>
        </div>
    );
};
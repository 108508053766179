import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {FieldHolder} from "../../../../FieldHolder";
import {Display} from "../../../../../Inputs/Display";
import {FaEnvelope, FaGlobe, FaLinkedin, FaPhoneAlt} from "react-icons/fa";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";

export const FreelanceContactCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const requiredFields = ['User.dialCode', 'User.phoneNumber', 'User.email'];

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    const handleSave = async () => {
        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields,
        });
        if (result?.error) {
            setFormErrors(result.data);
        }
    };

    return <>
        <CustomCard
            className="justify-content-center"
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            {editMode
                ? (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <FieldHolder
                                    fielddesc={t("Dial code")}
                                    type="text"
                                    name="User.dialCode"
                                    id="dialCode"
                                    value={freelanceProfileData?.User?.dialCode}
                                    onChange={handleFreelanceDataChange}
                                    canModify={true}
                                    mandatoryFlag={true}
                                    error={formErrors["User.dialCode"]}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <FieldHolder
                                    fielddesc={t("Phone number")}
                                    type="text"
                                    name="User.phoneNumber"
                                    id="phoneNumber"
                                    value={freelanceProfileData?.User?.phoneNumber}
                                    onChange={handleFreelanceDataChange}
                                    canModify={true}
                                    mandatoryFlag={true}
                                    error={formErrors["User.phoneNumber"]}
                                />
                            </Grid>
                        </Grid>
                        <FieldHolder
                            fielddesc={t("Email")}
                            type="text"
                            name="User.email"
                            id="email"
                            placeholder={t("user@domain.com")}
                            value={freelanceProfileData?.User?.email}
                            onChange={handleFreelanceDataChange}
                            canModify={true}
                            mandatoryFlag={true}
                            error={formErrors["User.email"]}
                        />
                        <FieldHolder
                            fielddesc={t("LinkedIn profile")}
                            type="url"
                            name="linkedinProfile"
                            id="linkedinProfile"
                            placeholder={t("www.linkedin.com/user")}
                            value={freelanceProfileData?.linkedinProfile}
                            onChange={handleFreelanceDataChange}
                            canModify={true}
                            displayPlaceholder={false}
                        />
                        <FieldHolder
                            fielddesc={t("Website")}
                            type="url"
                            name="website"
                            id="website"
                            placeholder={t("www.website.com")}
                            value={freelanceProfileData?.website}
                            onChange={handleFreelanceDataChange}
                            canModify={true}
                            displayPlaceholder={false}
                        />
                    </>
                )
                : (
                    <Grid container className="w-100" justifyContent="center" alignItems="center" gap={2}>
                        {freelanceProfileData?.User?.phoneNumber && (
                            <Grid item xs={12}>
                                <Typography variant="iconText">
                                    <FaPhoneAlt className="Mui-icon" size={16}
                                                style={{minWidth: "20px", marginRight: "12px"}}/>
                                    <Typography className="Mui-textTruncate">
                                        {freelanceProfileData.User?.dialCode
                                            ? `${freelanceProfileData.User?.dialCode} `
                                            : ""
                                        } {freelanceProfileData.User?.phoneNumber}
                                    </Typography>
                                </Typography>
                            </Grid>
                        )}
                        {freelanceProfileData?.User?.email && (
                            <Grid item xs={12}>
                                <Typography variant="iconText">
                                    <FaEnvelope className="Mui-icon" size={20}
                                                style={{minWidth: "20px", marginRight: "12px"}}/>
                                    <Typography className="Mui-textTruncate">
                                        {freelanceProfileData.User?.email}
                                    </Typography>
                                </Typography>
                            </Grid>
                        )}
                        {freelanceProfileData?.linkedinProfile && (
                            <Grid item xs={12}>
                                <Stack direction="row" className="w-100">
                                    <FaLinkedin className="Mui-icon" size={20}
                                                style={{minWidth: "20px", marginRight: "12px"}}/>
                                    <Box className="Mui-textTruncate">
                                        <Display type="url" value={freelanceProfileData.linkedinProfile}/>
                                    </Box>
                                </Stack>
                            </Grid>
                        )}
                        {freelanceProfileData?.website && (
                            <Grid item xs={12}>
                                <Stack direction="row" className="w-100">
                                    <FaGlobe className="Mui-icon" size={20}
                                                style={{minWidth: "20px", marginRight: "12px"}}/>
                                    <Box className="Mui-textTruncate">
                                        <Display type="url" value={freelanceProfileData.website}/>
                                    </Box>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                )
            }
        </CustomCard>
    </>
};
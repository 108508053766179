import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import {FieldHolder} from "../../../../FieldHolder";
import Functions from "../../../../../../utils/Functions";
import React, {useEffect, useState} from "react";
import skillService from "../../../../../../services/skillService";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";

export const FreelanceSkillCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        (async () => {
            const defaultSkills = await skillService.getAllDefaultSkill(token);
            const skillsData = skillService.groupSkillsByType(defaultSkills);
            setSkills(skillsData);
        })();
    }, [token]);

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={() =>
                handleFreelanceProfileSave({
                    token,
                    freelanceProfileData,
                    callback: () => setEditMode(false)
                })
            }
        >
            <FieldHolder
                fielddesc={t("Technical skills")}
                type="dropdown"
                name="FreelanceProfileSkills.technical"
                id="FreelanceProfileSkills.technical"
                value={freelanceProfileData?.FreelanceProfileSkills?.technical}
                datas={skills[Functions.SKILL_TYPE.TECHNICAL]}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                canModify={editMode}
                ismulti={true}
            />
            <FieldHolder
                fielddesc={t("Languages")}
                type="dropdown"
                name="FreelanceProfileSkills.language"
                id="FreelanceProfileSkills.language"
                value={freelanceProfileData?.FreelanceProfileSkills?.language}
                datas={skills[Functions.SKILL_TYPE.LANGUAGE]}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                canModify={editMode}
                ismulti={true}
            />
            <FieldHolder
                fielddesc={t("Transversal skills")}
                type="dropdown"
                name="FreelanceProfileSkills.transversal"
                id="FreelanceProfileSkills.transversal"
                value={freelanceProfileData?.FreelanceProfileSkills?.transversal}
                datas={skills[Functions.SKILL_TYPE.TRANSVERSAL]}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                ismulti={true}
                canModify={editMode}
            />
            <FieldHolder
                fielddesc={t("Business lines")}
                type="dropdown"
                name="FreelanceProfileSkills.business_line"
                id="FreelanceProfileSkills.business_line"
                value={freelanceProfileData?.FreelanceProfileSkills?.business_line}
                datas={skills[Functions.SKILL_TYPE.BUSINESS]}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                ismulti={true}
                canModify={editMode}
            />
        </CustomCard>
    </>
};
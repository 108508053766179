import Functions from "../utils/Functions";
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/preferences/";
class PreferenceService {
    constructor() {

    }

    getAllDefaultPreference = async (token) => {
        try {
            return requestApi(`${BASE_URL}get-defaults`, 'GET', {}, token);
        } catch (error) {
            console.error('Error fetching preferences:', error)
            throw error
        }
    }

    groupPreferencesByType = (preferences) => {
        const preferenceTypes = Object.values(Functions.PREFERENCE_TYPE);

        return preferenceTypes.reduce((groupedPreferences, type) => {
            groupedPreferences[type] = preferences.filter(
                (value) => value.type === type
            )
            return groupedPreferences;
        }, {})
    }
}

export default new PreferenceService();
import React, {useCallback, useRef, useState} from "react";
import {createInteraction, updateInteraction} from "../../../../services/companyFreelanceInteractionService";
import {Box, Button, Grid, IconButton, Popover, Stack, Tooltip, Typography} from "@mui/material";
import {FieldHolder} from "../../FieldHolder";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Utils from "../../../../utils/Utils";
import {getInteractionTypeIcon} from "./CompanyFreelanceInteraction";
import Functions from "../../../../utils/Functions";

export const CompanyFreelanceInteractionForm = ({interaction, interactionType, onSaveFinish, onDialogClose}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(interaction);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);
    const isNoteSection = (interactionType === "note");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleInteractionTypeChange = (type) => {
        setData({...data, type});
        handleClose();
    };

    const getInteractionTypeButton = useCallback((type, size) => {
        const interactionTypeIcon = getInteractionTypeIcon(type, size);
        return (
            <Tooltip
                title={t(interactionTypeIcon.name)}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -6],
                                }
                            }
                        ]
                    }
                }}
            >
                <IconButton onClick={() => handleInteractionTypeChange(type)}>{interactionTypeIcon.icon}</IconButton>
            </Tooltip>
        );
    }, [t]);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let requiredFields = ['title'];
        if (isNoteSection) {
            requiredFields = ['description'];
        }

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let response;
            if (data.id) {
                response = await updateInteraction(data.id, data, token);
            } else {
                response = await createInteraction(data, token);
            }
            onSaveFinish && onSaveFinish(!response.error);

            setLoading(false);
        })()
    };

    const openMenu = Boolean(anchorEl);
    const id = openMenu ? 'interaction-type-popover' : undefined;

    return <Box sx={{width: {md: "25vw"}}}>
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            style={{display: "flex", flexDirection: "column"}}
        >
            <Grid container spacing={3}>
                {!isNoteSection && (
                    <>
                        <Grid item container spacing={1}>
                            <Grid item xs="auto">
                                <IconButton
                                    aria-describedby={id}
                                    title={t("Interaction type")}
                                    onClick={handleClick}
                                    sx={{border: '1px solid rgba(0, 0, 0, 0.23)'}}
                                >
                                    {getInteractionTypeIcon(data.type, 25).icon}
                                </IconButton>
                                <Popover
                                    id={id}
                                    open={openMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    sx={{marginTop: 1}}
                                >
                                    <Stack direction="column" spacing={1} sx={{p: 1.5, width: "300px"}}>
                                        <Typography>{t("Interaction type")}</Typography>
                                        <Stack direction="row" alignItems="start" spacing={2}>
                                            {getInteractionTypeButton(Functions.INTERACTION_TYPE.CALL, 22)}
                                            {getInteractionTypeButton(Functions.INTERACTION_TYPE.EMAIL, 22)}
                                            {getInteractionTypeButton(Functions.INTERACTION_TYPE.MESSAGE, 22)}
                                            {getInteractionTypeButton(Functions.INTERACTION_TYPE.MEETING, 22)}
                                        </Stack>
                                    </Stack>
                                </Popover>
                            </Grid>
                            <Grid item xs>
                                <FieldHolder
                                    fielddesc={t("Interaction title")}
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={data.title}
                                    onChange={handleChange}
                                    canModify={true}
                                    mandatoryFlag={true}
                                    error={formErrors.title}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Date")}
                                type="date"
                                name="date"
                                id="date"
                                value={data.date}
                                onChange={handleChange}
                                canModify={true}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={isNoteSection ? "" : t("Description")}
                        type={isNoteSection ? "wysiwyg" : "multiline"}
                        name="description"
                        id="description"
                        value={data.description}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.description}
                    />
                </Grid>
                <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <Button
                        variant="contained"
                        color="light"
                        onClick={onDialogClose}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        {interaction.id
                            ? t("Edit")
                            : (isNoteSection ? t("Add") : t("Create interaction"))
                        }
                    </Button>
                </Grid>
            </Grid>
        </form>
    </Box>
}

import React, {useEffect, useState} from "react";
import {FieldHolder} from "../Forms/FieldHolder";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Functions from "../../utils/Functions";
import TagService from "../../services/tagService";
import {saveCompanyFreelanceNote} from "../../services/freelanceProfileService";
import Utils from "../../utils/Utils";

export const EditableTag = ({id, field, value, api, data, onEditOption}) => {
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const {t} = useTranslation();

    const [inputValue, setInputValue] = useState([]);
    const [options, setOptions] = useState([]);
    const isSuperAdministrator = connectedUser.companyRole === Functions.USER_ROLE.SUPER_ADMINISTRATOR;

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        setOptions(data);
    }, [data]);

    const handleDropdownChange = async (name, selectedValue) => {
        const newValue = selectedValue.map(item => ({...item, name: item.label}));
        setInputValue(newValue);

        let data = {FreelanceProfileId: id};
        data = Utils.updateNestedField(data, field, newValue);
        const result = await saveCompanyFreelanceNote(data, token);
        if (!result?.error) {
            api.setEditCellValue({id, field, value: newValue});
        }
    };

    const handleEditOption = async (option, newLabel) => {
        if (option.value !== option.label) {
            await TagService.modifyTag(option.value, {name: newLabel}, token);
        }
        const newValue = inputValue.map(item => item.id === option.value ? {...item, name: newLabel} : item);
        api.setEditCellValue({id, field, value: newValue});
        onEditOption && onEditOption(option, newLabel);
    };

    const handleDeleteOption = async (option) => {
        if (option.value !== option.label) {
            await TagService.deleteTag(option.value, token);
        }
        const newValue = inputValue.filter(item => item.id !== option.value);
        api.setEditCellValue({id, field, value: newValue});
    };

    return (
        <FieldHolder
            fielddesc={t("Tags")}
            type="dropdown"
            name="tag"
            id={`row.${id}.tag`}
            value={inputValue}
            datas={options}
            canModify={true}
            ismulti={true}
            allowAdd={isSuperAdministrator}
            allowManageOption={isSuperAdministrator}
            onDropdownChange={handleDropdownChange}
            onEditOption={handleEditOption}
            onDeleteOption={handleDeleteOption}
        />
    );
};
import {BehaviorSubject, Observable} from 'rxjs';
import {isIterable} from "rxjs/internal/util/isIterable";

class FreelanceProfileFiles {
    constructor() {
        this.files = new BehaviorSubject([]);
        this.freelanceProfile = new BehaviorSubject(null);
        this.filesDeleted = new BehaviorSubject([]);
    }

    setFreelanceProfile(freelanceProfile) {
        this.freelanceProfile.next(freelanceProfile);
    }

    setFiles(files) {
        this.files.next(files);
    }

    setFilesDeleted(file) {
        //const imageUrl = URL.createObjectURL(blob);
        //this.imageFile.next(imageUrl);
    }

    getFilesObservable() {
        return this.files.asObservable();
    }

    getFilesDeletedObservable() {
        return this.filesDeleted.asObservable();
    }

    getFreelanceProfileAsObservable() {
        return this.freelanceProfile.asObservable();
    }

    isIterable(obj) {
        return typeof obj[Symbol.iterator] === 'function';
    }

}

export default new FreelanceProfileFiles();
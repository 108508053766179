import React, {forwardRef} from "react";
import "./fieldholder.css";

import {useTranslation} from "react-i18next";
import {Display} from "../Inputs/Display";
import {MissionDocuments} from "../Inputs/MissionDocuments";
import {ImageInput} from "../Inputs/ImageInput";
import {InputDropdown} from "../Inputs/InputDropdown";
import {Input, Textarea} from "../Inputs/Input";
import {FileInput} from "../Inputs/FileInput";
import Editor from "../Inputs/Editor";

/**
 * Primary UI component for user interaction
 */
export const FieldHolder = forwardRef((
    {
        set,
        datas = [],
        fielddesc,
        type,
        value = "",
        canModify = true,
        toTranslate = false,
        subComponent,
        formData,
        setFormData,
        objectAttachedType = null,
        setFileInfo,
        documentsType,
        ismulti = false,
        onChange,
        sibling = null,
        additionalComponent = <></>,
        className = "",
        mandatoryFlag = false, isDisabled = null,
        ...props
    },
    ref
) => {
    const checkType = (type) => {
        let subComponentReturn;

        if (subComponent !== undefined) {
            subComponentReturn = subComponent;
        } else if (!canModify && isDisabled === null) {
            subComponentReturn = (
                <Display
                    set={set}
                    type={type}
                    label={fielddesc}
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder}
                    value={value}
                    datas={datas}
                    toTranslate={toTranslate}
                    className={className}
                    ismulti={Boolean(ismulti)}
                    required={mandatoryFlag}
                    {...props}
                />
            );
        } else {
            switch (type) {
                case "document":
                    subComponentReturn = (
                        <MissionDocuments
                            type={type}
                            name={props.name}
                            placeholder={props.placeholder}
                            value={value}
                            objectAttachedType={objectAttachedType}
                            style={{margin: props.margin}}
                            canModify={canModify}
                            documentsType={documentsType}
                            missionData={formData}
                            setFileInfo={setFileInfo}
                            onChange={onChange}
                            className={className}
                            {...props}
                        />
                    );
                    break;
                case "file":
                    subComponentReturn = (
                        <FileInput
                            ref={ref}
                            type={type}
                            label={fielddesc}
                            name={props.name}
                            id={props.id}
                            placeholder={props.placeholder}
                            value={value}
                            formData={formData}
                            setFormData={setFormData}
                            objectAttachedType={objectAttachedType}
                            style={{margin: props.margin}}
                            canModify={canModify}
                            setFileInfo={setFileInfo}
                            sibling={sibling}
                            className={className}
                            required={mandatoryFlag && canModify}
                            {...props}
                        />
                    );
                    break;
                case "image":
                    subComponentReturn = (
                        <ImageInput
                            type={type}
                            label={fielddesc}
                            name={props.name}
                            id={props.id}
                            placeholder={props.placeholder}
                            value={value}
                            formData={formData}
                            setFormData={setFormData}
                            onChange={onChange}
                            objectAttachedType={objectAttachedType}
                            style={{margin: props.margin}}
                            canModify={canModify}
                            setFileInfo={setFileInfo}
                            className={className}
                            {...props}
                        />
                    );
                    break;
                case "dropdown":
                    subComponentReturn = (
                        <InputDropdown
                            set={set}
                            label={fielddesc}
                            name={props.name}
                            id={props.id}
                            required={mandatoryFlag && canModify}
                            placeholder={props.placeholder}
                            value={value}
                            datas={datas}
                            onChange={onChange}
                            style={{margin: props.margin}}
                            formData={formData}
                            setFormData={setFormData}
                            ismulti={Boolean(ismulti)}
                            isDisabled={!canModify}
                            className={className}
                            {...props}
                        />
                    );
                    break;
                case "multiline":
                    subComponentReturn = (
                        <Textarea
                            label={fielddesc}
                            name={props.name}
                            id={props.id}
                            placeholder={props.placeholder}
                            value={value}
                            onChange={onChange}
                            required={mandatoryFlag && canModify}
                            disabled={!canModify}
                            {...props}
                        />
                    );
                    break;
                case 'wysiwyg':
                    subComponentReturn = (
                        <Editor
                            ref={ref}
                            label={fielddesc}
                            name={props.name}
                            id={props.id}
                            placeholder={props.placeholder}
                            value={value}
                            onChange={onChange}
                            required={mandatoryFlag && canModify}
                            disabled={!canModify}
                            {...props}
                        />
                    );
                    break;
                default:
                    subComponentReturn = (
                        <Input
                            type={type}
                            label={fielddesc}
                            name={props.name}
                            id={props.id}
                            placeholder={props.placeholder}
                            value={value}
                            onChange={onChange}
                            className={className}
                            required={mandatoryFlag && canModify}
                            disabled={!canModify}
                            {...props}
                        />
                    );
                    break;
            }
        }

        return subComponentReturn;
    };

    return (
        <>
            {checkType(type)}
            {additionalComponent}
        </>
    );
});

const MandatoryText = () => {
    const {t} = useTranslation();
    return <p className="mandatory-legend">{t("* champs obligatoires")}</p>;
};

export {MandatoryText};

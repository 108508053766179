import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Grid} from "@mui/material";
import {FieldHolder} from "../../../../FieldHolder";
import Utils from "../../../../../../utils/Utils";
import {createMissionStorageLink, updateMissionStorageLink} from "../../../../../../services/missionStorageLink";

export const OnboardingStorageLinkForm = ({storageLink, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [storageLinkData, setStorageLinkData] = useState(storageLink);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = ['title', 'url'];

    const handleChange = (e) => {
        const {name, value} = e.target;
        setStorageLinkData({...storageLinkData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(storageLinkData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let data = {...storageLinkData};

            let response;
            if (data.id) {
                response = await updateMissionStorageLink(data.id, data, token);
            } else {
                response = await createMissionStorageLink(data, token);
            }
            onSaveFinish && onSaveFinish(!response.error);

            setLoading(false);
        })()
    };

    return <Box sx={{width: {md: "25vw"}}}>
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            style={{display: "flex", flexDirection: "column"}}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Link title")}
                        type="text"
                        name="title"
                        id="title"
                        value={storageLinkData.title}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Description")}
                        type="multiline"
                        name="description"
                        id="description"
                        rows={2}
                        value={storageLinkData.description}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.description}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Link URL")}
                        type="url"
                        name="url"
                        id="url"
                        value={storageLinkData.url}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.url}
                    />
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >{t("Save")}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>
};
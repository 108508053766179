import React from "react";

import {Grid, Stack} from "@mui/material";
import {FreelanceInfo1Card} from "./Card/FreelanceInfo1Card";
import {FreelanceInfo2Card} from "./Card/FreelanceInfo2Card";
import {FreelanceInfo3Card} from "./Card/FreelanceInfo3Card";
import {FreelanceInfo4Card} from "./Card/FreelanceInfo4Card";

export const InformationAndDocumentSection = ({formData, ...props}) => {
    return (
        <Stack direction="column" gap={2} sx={{width: "100%"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={props.fullWidth ? 12 : 7.5}>
                    <FreelanceInfo1Card formData={formData}/>
                </Grid>
                <Grid item xs={12} md sx={{overflow: "hidden"}}>
                    <FreelanceInfo2Card formData={formData}/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={props.fullWidth ? 12 : 7.5}>
                    <FreelanceInfo3Card formData={formData}/>
                </Grid>
                <Grid item xs={12} md sx={{overflow: "hidden"}}>
                    <FreelanceInfo4Card formData={formData}/>
                </Grid>
            </Grid>
        </Stack>
    );
};

import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {fetchDefaultJobKeywords} from "../../../../../../services/freelanceProfileService";
import {Box, Grid} from "@mui/material";
import {FieldHolder} from "../../../../FieldHolder";
import {Display} from "../../../../../Inputs/Display";
import {FreelanceAvailabilityDisplay} from "../../../FreelanceAvailabilityDisplay";
import {MdLocalSee} from "react-icons/md";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import Utils from "../../../../../../utils/Utils";

export const FreelanceInfoCard = ({formData, showProfilePicture = true}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);
    const [jobKeywords, setJobKeywords] = useState([]);
    const [profilePicture, setProfilePicture] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const files = [];
    const requiredFields = ['User.firstName', 'User.lastName', 'jobTitle'];
    const userData = freelanceProfileData?.User;
    const freelanceFullName = userData ? `${userData.firstName} ${userData.lastName}` : "-";
    const averageDailyRateCurrency = t(Utils.getParameterValue(freelanceProfileData?.averageDailyRateCurrency, "Currency"));

    useEffect(() => {
        (async () => {
            const defaultJobKeywords = await fetchDefaultJobKeywords(token);
            setJobKeywords(defaultJobKeywords);
        })();
    }, [token]);

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
            setProfilePicture(null);
        }
    }, [editMode]);

    const handleSave = async () => {
        files.push(profilePicture);

        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields,
            freelanceProfileFiles: files
        });
        if (result?.error) {
            setFormErrors(result.data);
        }
    };

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            <Grid container alignItems="center" spacing={2}>
                {showProfilePicture && (
                    <Grid item xs="auto" className="profile-picture">
                        <Box sx={{width: "100px", height: "100px"}}>
                            <FieldHolder
                                fielddesc={t("Profile picture")}
                                type="image"
                                name="User.picture"
                                id="picture"
                                placeholder={t("Picture")}
                                value={freelanceProfileData?.User?.picture}
                                setFileInfo={setProfilePicture}
                                textUpload={<MdLocalSee size={25}/>}
                                onChange={handleFreelanceDataChange}
                                canModify={editMode}
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item xs>
                    <Grid container spacing={2}>
                        {editMode
                            ? (
                                <>
                                    <Grid item xs={6}>
                                        <FieldHolder
                                            fielddesc={t("First name")}
                                            type="text"
                                            name="User.firstName"
                                            id="firstName"
                                            value={freelanceProfileData?.User?.firstName}
                                            onChange={handleFreelanceDataChange}
                                            canModify={editMode}
                                            mandatoryFlag={true}
                                            error={formErrors["User.firstName"]}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FieldHolder
                                            fielddesc={t("Last name")}
                                            type="text"
                                            name="User.lastName"
                                            id="lastName"
                                            value={freelanceProfileData?.User?.lastName}
                                            onChange={handleFreelanceDataChange}
                                            canModify={editMode}
                                            mandatoryFlag={true}
                                            error={formErrors["User.lastName"]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FieldHolder
                                            fielddesc={t("Job title")}
                                            type="text"
                                            name="jobTitle"
                                            id="jobTitle"
                                            placeholder={t("Consultant")}
                                            value={freelanceProfileData?.jobTitle}
                                            onChange={handleFreelanceDataChange}
                                            canModify={editMode}
                                            mandatoryFlag={true}
                                            error={formErrors.jobTitle}
                                        />
                                    </Grid>
                                </>
                            )
                            : (
                                <Grid item xs={12}>
                                    <Display
                                        className="profile-title"
                                        label={freelanceFullName}
                                        value={freelanceProfileData?.jobTitle}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Job keywords")}
                        type="dropdown"
                        name="FreelanceProfileJobKeywords"
                        id="FreelanceProfileJobKeywords"
                        value={freelanceProfileData?.FreelanceProfileJobKeywords}
                        datas={jobKeywords}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        canModify={editMode}
                        ismulti={true}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
                {editMode
                    ? (
                        <>
                            <Grid item xs={6}>
                                <FieldHolder
                                    fielddesc={t("ADR")}
                                    type="number"
                                    name="averageDailyRate"
                                    id="averageDailyRate"
                                    value={freelanceProfileData.averageDailyRate}
                                    onChange={handleFreelanceDataChange}
                                    canModify={editMode}
                                    displayPlaceholder={false}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FieldHolder
                                    fielddesc={t("Average daily rate currency")}
                                    type="dropdown"
                                    name="averageDailyRateCurrency"
                                    id="averageDailyRateCurrency"
                                    set="Currency"
                                    placeholder={t("Euros / US Dollar...")}
                                    formData={freelanceProfileData}
                                    setFormData={setFreelanceData}
                                    value={freelanceProfileData.averageDailyRateCurrency}
                                    canModify={editMode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FieldHolder
                                    fielddesc={`${t("ADR")} minimum`}
                                    type="number"
                                    name="averageDailyRateMin"
                                    id="averageDailyRateMin"
                                    value={freelanceProfileData.averageDailyRateMin}
                                    onChange={handleFreelanceDataChange}
                                    canModify={editMode}
                                    displayPlaceholder={false}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FieldHolder
                                    fielddesc={`${t("ADR")} maximum`}
                                    type="number"
                                    name="averageDailyRateMax"
                                    id="averageDailyRateMax"
                                    value={freelanceProfileData.averageDailyRateMax}
                                    onChange={handleFreelanceDataChange}
                                    canModify={editMode}
                                    displayPlaceholder={false}
                                />
                            </Grid>
                        </>
                    )
                    : (
                        <>
                            <Grid item xs={4}>
                                <Display
                                    label={t("ADR")}
                                    value={Utils.showAmount(freelanceProfileData?.averageDailyRate, averageDailyRateCurrency, 0)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Display
                                    label={`${t("ADR")} minimum`}
                                    value={Utils.showAmount(freelanceProfileData?.averageDailyRateMin, averageDailyRateCurrency, 0)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Display
                                    label={`${t("ADR")} maximum`}
                                    value={Utils.showAmount(freelanceProfileData?.averageDailyRateMax, averageDailyRateCurrency, 0)}
                                />
                            </Grid>
                        </>
                    )
                }
            </Grid>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Display
                        label={t("Availabilities")}
                        value={<FreelanceAvailabilityDisplay availabilityData={freelanceProfileData}/>}
                    />
                </Grid>
            </Grid>
        </CustomCard>
    </>
};
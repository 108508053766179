import {Display} from "../../../../../Inputs/Display";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import {Box, Grid} from "@mui/material";
import {FieldHolder} from "../../../../FieldHolder";
import {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {fetchCitiesByCountry, fetchCity, fetchCountries} from "../../../../../../services/countryService";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {debounce} from "lodash";

export const FreelanceLocationCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [freelanceLocation, setFreelanceLocation] = useState({});

    const fetchCitiesRef = useRef();
    const countryRef = useRef(null);
    const [filterConfigs, setFilterConfigs] = useState({
        filter: '',
        range: JSON.stringify([0, 199]),
    });

    const freelanceLocationCity = freelanceLocation?.name;
    const freelanceLocationCountry = freelanceLocation?.Country?.name;
    const freelanceLocationName = freelanceLocationCity ? `${freelanceLocationCity}, ${freelanceLocationCountry}` : "";

    useEffect(() => {
        (async () => {
            const fetchedCountries = await fetchCountries(token);
            setCountries(fetchedCountries.data);
        })();
    }, [token]);

    useEffect(() => {
        if (!editMode) {
            if (freelanceProfileData.location) {
                (async () => {
                    const locationData = await fetchCity(freelanceProfileData.location, token);
                    if (locationData) {
                        setFreelanceLocation(locationData);
                        const countryId = locationData?.Country?.id;
                        searchCities(countryId, locationData?.name);
                        countryRef.current = countryId;
                    }
                })();
            } else {
                setFreelanceLocation({});
                countryRef.current = "";
                setCities([]);
            }
        }
    }, [token, editMode, freelanceProfileData.location]);

    useEffect(() => {
        if (freelanceProfileData?.locationCountry) {
            countryRef.current = freelanceProfileData.locationCountry;
            searchCities(countryRef.current);
        }
    }, [freelanceProfileData?.locationCountry]);


    const searchCities = (countryId, inputValue = "") => {
        let filters = {};
        if (inputValue) {
            filters = {
                name: {
                    operator: 'contains',
                    value: inputValue
                }
            };
        }

        fetchCitiesRef.current(countryId, {
            ...filterConfigs,
            filter: Object.keys(filters).length ? JSON.stringify(filters) : ''
        });
    };

    const handleInputCityChange = useCallback(
        debounce((inputValue) => {
            if (countryRef.current) {
                searchCities(countryRef.current, inputValue);
            }
        }, 300), // 300ms debounce time
        []
    );

    fetchCitiesRef.current = async (countryId, query = {}) => {
        try {
            const fetchedCities = await fetchCitiesByCountry(countryId, query, token);
            setCities(fetchedCities.data);
        } catch (error) {

        }
    }

    const getDetailDialogContent = () => {
        return <>
            <Box sx={{width: {md: "20vw"}}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Display
                            label={t("Localization")}
                            value={freelanceLocationName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Display
                            type="dropdown"
                            set="WorkingTermsType"
                            label={t("Preferences")}
                            value={freelanceProfileData?.working_terms_preference}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Display
                            type="checkbox"
                            set="WorkingTermsType"
                            label={t("National mobility")}
                            value={freelanceProfileData?.national_mobility}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Display
                            type="checkbox"
                            set="WorkingTermsType"
                            label={t("International mobility")}
                            value={freelanceProfileData?.international_mobility}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    };

    return <>
        <CustomCard
            detailDialogContent={getDetailDialogContent()}
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={() =>
                handleFreelanceProfileSave({
                    token,
                    freelanceProfileData,
                    callback: () => setEditMode(false)
                })
            }
        >
            {editMode
                ? (
                    <>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FieldHolder
                                    fielddesc={t("Country")}
                                    type="dropdown"
                                    name="locationCountry"
                                    id="locationCountry"
                                    value={countryRef.current}
                                    datas={countries}
                                    formData={freelanceProfileData}
                                    setFormData={setFreelanceData}
                                    canModify={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldHolder
                                    fielddesc={t("Localization")}
                                    type="dropdown"
                                    name="location"
                                    id="locationCity"
                                    value={freelanceProfileData?.location}
                                    datas={cities}
                                    formData={freelanceProfileData}
                                    setFormData={setFreelanceData}
                                    onInputChange={handleInputCityChange}
                                    disableClearable={false}
                                    canModify={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FieldHolder
                                    fielddesc={t("Preferences")}
                                    type="dropdown"
                                    set="WorkingTermsType"
                                    name="working_terms_preference"
                                    id="working_terms_preference"
                                    value={freelanceProfileData?.working_terms_preference}
                                    formData={freelanceProfileData}
                                    setFormData={setFreelanceData}
                                    canModify={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FieldHolder
                                    fielddesc={t("National mobility")}
                                    type="checkbox"
                                    name="national_mobility"
                                    id="national_mobility"
                                    value={freelanceProfileData?.national_mobility}
                                    onChange={handleFreelanceDataChange}
                                    canModify={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FieldHolder
                                    fielddesc={t("International mobility")}
                                    type="checkbox"
                                    name="international_mobility"
                                    id="international_mobility"
                                    value={freelanceProfileData?.international_mobility}
                                    onChange={handleFreelanceDataChange}
                                    canModify={true}
                                />
                            </Grid>
                        </Grid>
                    </>
                )
                : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Display
                                label={t("Localization")}
                                value={freelanceLocationName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{pl: 2}}>
                                <Display
                                    type="dropdown"
                                    set="WorkingTermsType"
                                    label={t("Preferences")}
                                    value={freelanceProfileData?.working_terms_preference}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )
            }
        </CustomCard>
    </>
};
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useToast} from "../../../../../context/ToastProvider";
import useFileOpener from "../../../../../hooks/customHooks";
import Functions from "../../../../../utils/Functions";
import {Box, Button, Chip, Grid, IconButton, Stack, Typography} from "@mui/material";
import Utils from "../../../../../utils/Utils";
import {Display} from "../../../../Inputs/Display";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {MdOutlineFileDownload} from "react-icons/md";
import {PDFViewer2} from "../../../../Pdf/PDFViewer2";
import {createFile, fetchFile} from "../../../../../services/fileService";
import {SignaturePadPopup} from "../../../../Popup/SignaturePadPopup";
import missionRxjs from "../../../../../services/missionRxjs";
import {fetchUser} from "../../../../../services/userService";
import {PDFDocument, rgb, StandardFonts} from "pdf-lib";
import {modifyManyQuoteStatus, updateQuote} from "../../../../../services/quoteService";

export const QuoteDetailSection = (
    {
        formData,
        selectedQuote,
        setSelectedQuote,
        sessionType
    }
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const showToast = useToast();
    const {loading, openFileInNewTab} = useFileOpener();

    const [showSignaturePadDialog, setShowSignaturePadDialog] = useState(false);
    const [message, setMessage] = useState({});
    const [quoteFile, setQuoteFile] = useState(null);
    const [activeFile, setActiveFile] = useState(null);
    const [activeFileName, setActiveFileName] = useState(null);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    useEffect(() => {
        if (selectedQuote?.id) {
            (async () => {
                await loadQuoteFile(selectedQuote);
            })();
        }
    }, [selectedQuote.id]);

    const handleSignatureSave = async (blobSignature) => {
        missionRxjs.setQuoteSignatureFile(blobSignature);
        setShowSignaturePadDialog(false);
        await createPdfMergeFile(blobSignature);
    };

    const createPdfMergeFile = async (blobSignature) => {
        const now = new Date();
        const fetchedUser = await fetchUser(token);

        const updatedDataQuote = selectedQuote;

        updatedDataQuote["enterprise_signature"] = true;
        updatedDataQuote["enterprise_signature_date"] = now;
        updatedDataQuote["enterpriseSignatureUserId"] = fetchedUser.id;
        updatedDataQuote["status"] = Functions.TEXT_SIGNED;

        // Charger le document PDF existant
        const convertedFile = blobToFile(quoteFile, "converted.pdf");
        const existingPdfBytes = await convertedFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        //Font du text
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        // Récupérer la dernière page du document
        const lastPageIndex = pdfDoc.getPageCount() - 1;
        const lastPage = pdfDoc.getPages()[lastPageIndex];

        // const enterpriseUser = mission?.OperationalManager?.User;
        const text =
            t("Electronically signed on Smarteem") + "\n" + t("the") + " " +
            now.toISOString().split("T")[0] + " " +
            t("By").toString().toLowerCase() + " " + fetchedUser?.fullName;
        const textSize = 10;

        // Get the width and height of the first page
        lastPage.drawText(text, {
            x: 50,
            y: 180,
            font: helveticaFont,
            size: textSize,
            color: rgb(0, 0, 0),
        });

        // Charger l'image blob
        const imageBytes = await new Response(blobSignature).arrayBuffer();

        // Ajouter l'image à la page
        const image = await pdfDoc.embedPng(imageBytes);
        const imageSize = 100;
        lastPage.drawImage(image, {
            x: 50,
            y: 50,
            width: imageSize,
            height: imageSize,
        });

        // Convertir le document PDF en tableau des bytes
        const pdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes
        const pdfBlob = new Blob([pdfBytes], {type: "application/pdf"});

        // Créer un objet URL pour le Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const quoteFiles = [
            {name: "quote_signature", theFile: blobSignature},
            {name: "merge_file", theFile: pdfBlob},
        ];

        try {
            setMessage({type: "loading", message: "Saving datas"});

            for (const value of quoteFiles) {
                const property = value.name;
                const file = value.theFile;
                const fileData = {
                    typeFile: Functions.TEXT_DOCUMENT,
                    [value.name]: file,
                };
                if (file !== null) {
                    updatedDataQuote[property] = await createFile(fileData, "", token);
                }
            }

            const quoteUpdated = await updateQuote(
                selectedQuote.id,
                updatedDataQuote,
                token
            );

            setMessage({});
            if (quoteUpdated) {
                showToast(t("The document has been signed"));

                setActiveFile(pdfUrl);
                setSelectedQuote(selectedQuote);
                missionRxjs.setQuoteFiles(quoteFiles);
            } else {
                setMessage(quoteUpdated);
            }
        } catch (error) {
            setMessage({type: "error", message: Functions.TEXT_ERROR_SAVE});
        }
    };

    const handleSaveQuoteData = async (quoteDatas, status) => {
        const updatedQuote = await modifyManyQuoteStatus({quotes: quoteDatas}, token);
        if (updatedQuote?.error) {
            setMessage({type: "error", message: t("Error saving data")});
        } else {
            const quoteToModify = selectedQuote;
            quoteToModify.status = status;
            setSelectedQuote(quoteToModify);

            setMessage({
                type: "success",
                message: t("Quote updated successfully"),
            });
        }
    };

    const handleClickAction = async (e, status) => {
        e.preventDefault();
        let quoteDatas = [];

        setMessage({});
        switch (status) {
            case Functions.TEXT_REFUSED:
            case Functions.TEXT_REJECTED:
            case Functions.TEXT_OPEN:
                const item = {
                    id: selectedQuote.id,
                    status: status,
                };
                quoteDatas = [...quoteDatas, item];

                await handleSaveQuoteData(quoteDatas, status);
                break;
            default:
                break;
        }
    };

    const blobToFile = (blob, fileName) => {
        return new File([blob], fileName, {type: "application/pdf"});
    };

    const loadQuoteFile = async (quote) => {
        let displayFile = quote.file;
        let keyNameFile = "file";

        setMessage({});

        if (quote.status === Functions.TEXT_SIGNED) {
            displayFile = quote.merge_file;
            keyNameFile = "merge_file";
        }
        try {
            setMessage({type: "loading", message: t("Fetching data")});
            const fetchedFile = await fetchFile(displayFile, Functions.TEXT_DOCUMENT, token);

            setTimeout(() => {
                if (fetchedFile?.error) {
                    setMessage({type: "error", message: fetchedFile.error});
                } else {
                    setQuoteFile(fetchedFile);
                    setActiveFile(URL.createObjectURL(fetchedFile));
                    setActiveFileName(quote[keyNameFile]);
                    setMessage({});
                }
            }, 1000);
        } catch (error) {
            setMessage({
                type: "error",
                message: t("File removed or moved"),
            });
        }

    };

    const getQuoteHeader = () => {
        const isOpenStatus = selectedQuote.status === Functions.TEXT_OPEN;

        if (isEnterpriseSession && isOpenStatus) {
            return (
                <Stack
                    direction="row"
                    justifyContent={{md: "end"}}
                    columnGap={1}
                >
                    <Button
                        type="button"
                        variant="contained"
                        color="light"
                        onClick={(e) => handleClickAction(e, "Rejected")}
                    >
                        {t("Reject the quote")}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowSignaturePadDialog(true)}
                    >
                        {t("Sign")}
                    </Button>
                    <SignaturePadPopup
                        open={showSignaturePadDialog}
                        title={false}
                        onConfirm={handleSignatureSave}
                        onDialogClose={() => setShowSignaturePadDialog(false)}
                    />
                </Stack>
            );
        }

        return null;
    };

    const handleFileOpen = async (value) => {
        await openFileInNewTab(value, Functions.TEXT_DOCUMENT, token);
    };

    return <>
        {formData?.contractualisation_type !== Functions.TEXT_CONTRAT_ONLY &&
            <div style={{width: "100%", height: "100%", overflow: "auto"}}>
                <div className="common-board-container">
                    {getQuoteHeader()}

                    <Grid container direction="row" columnSpacing={3} className="h-100">
                        <Grid item xs={3} className="h-100">
                            <div className="box-item standardform-card card-padding-wide h-100">
                                {selectedQuote.id && (
                                    <>
                                        <Stack spacing={1} alignItems="center" sx={{width: "100%"}}>
                                            <Typography variant="labelInfo">{selectedQuote.name}</Typography>
                                            <Chip
                                                label={t(Functions.STATUS_QUOTE[selectedQuote.status])}
                                                className={Functions.STATUS_QUOTE_BG[selectedQuote.status]}
                                                sx={{minWidth: "8rem"}}
                                            />
                                        </Stack>
                                        <Display
                                            label={t("Amount")}
                                            value={`${selectedQuote.amount} ${Utils.getCurrencySymbol(selectedQuote.devise)}`}
                                        />
                                        <Display
                                            label={t("Added on")}
                                            value={Utils.formatDate(selectedQuote.createdAt)}
                                        />
                                        {selectedQuote.QuoteCreatorUser &&
                                            <Display
                                                label={t("Added by")}
                                                value={selectedQuote.QuoteCreatorUser?.fullName}
                                            />
                                        }
                                        <Display
                                            label={t("Signed")}
                                            value={
                                                selectedQuote.enterprise_signature
                                                    ? `${t("Yes")} - ${t("the")} ${Utils.formatDate(selectedQuote.enterprise_signature_date)}`
                                                    : t("No")
                                            }
                                        />
                                        {selectedQuote.commentEnterprise &&
                                            <Display
                                                label={t("Message")}
                                                value={selectedQuote.commentEnterprise}
                                            />
                                        }
                                        {selectedQuote.commentFreelance &&
                                            <Display
                                                label={t("Message")}
                                                value={selectedQuote.commentFreelance}
                                            />
                                        }
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs className="h-100">
                            <div className="box-item standardform-card card-padding-wide h-100"
                                 style={{alignItems: "center"}}
                            >
                                {selectedQuote.status && (
                                    <>
                                        <Box sx={{width: "92%"}}>
                                            <MessageComponent
                                                type={message.type}
                                                message={message.message}
                                                width="100%"
                                            />
                                            {
                                                activeFileName &&
                                                ![
                                                    Functions.TEXT_REJECTED,
                                                    Functions.TEXT_CANCELED
                                                ].includes(selectedQuote.status) && (
                                                    <Stack direction="row" justifyContent="start"
                                                           spacing={0.5}
                                                           sx={{width: "100%"}}>
                                                        <IconButton
                                                            disabled={loading}
                                                            color="primary"
                                                            title={t("Download")}
                                                            onClick={() => handleFileOpen(activeFileName)}
                                                        >
                                                            <MdOutlineFileDownload size={30}/>
                                                        </IconButton>
                                                    </Stack>
                                                )
                                            }
                                        </Box>
                                        <div className="common-board-pdfviewerbox">
                                            <PDFViewer2
                                                activeFile={activeFile}
                                                fileName={activeFileName}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        }
    </>
};
import React, {useEffect, useState} from 'react';
import {Button, Grid, Rating, Stack, Typography} from "@mui/material";

const RatingSelector = (
    {
        type = "square",
        label,
        name,
        value = null,
        min = 1,
        max = 10,
        onChange,
        canModify = true,
        justifyContent = "center"
    }
) => {
    const [selectedRating, setSelectedRating] = useState(value);
    useEffect(() => {
        setSelectedRating(value);
    }, [value]);

    const ratings = Array.from({length: max - min + 1}, (_, index) => index + min);

    const handleRatingClick = (rating) => {
        if (canModify) {
            setSelectedRating(rating);
            onChange && onChange(name, rating);
        }
    };

    return <>
        {type === "square" && (
            <Stack direction="column" alignItems={justifyContent}>
                <Typography variant="label" sx={{textWrap: "wrap"}}>{label}</Typography>
                <Stack direction="row" justifyContent={justifyContent} flexWrap="wrap" sx={{gap: "5px", mt: 1}}>
                    {ratings.map((rating) => (
                        <Button
                            className="MuiButton-square"
                            key={rating}
                            onClick={() => handleRatingClick(rating)}
                            variant={selectedRating === rating ? 'contained' : 'outlined'}
                            sx={{minWidth: "45px", width: "45px", height: "45px"}}
                            disableRipple={!canModify}
                        >
                            {rating}
                        </Button>
                    ))}
                </Stack>
            </Stack>
        )}
        {type === "star" && (
            <Grid container direction="row" className="rating-selector-star" sx={{width: "100%"}}>
                <Grid item xs={2.5}>
                    <Typography variant="label">{label}</Typography>
                </Grid>
                <Grid item container alignItems="start" xs>
                    <Rating
                        readOnly={!canModify}
                        value={selectedRating}
                        onChange={(event, newValue) => {
                           handleRatingClick(newValue);
                        }}
                    />
                </Grid>
            </Grid>
        )}
    </>
};

export default RatingSelector
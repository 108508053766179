import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/portfolios/";

export const fetchPortfolios = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching portfolios :", error);
        throw error;
    }
};

export const fetchFreelancePortfolios = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching portfolios :", error);
        throw error;
    }
};

export const fetchFreelancePortfolioTags = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}/tags?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching tags :", error);
        throw error;
    }
};


export const createPortfolio = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating portfolio :", error);
        throw error;
    }
};

export const updatePortfolioDisplayOrder = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}reorder`, 'POST', data, token);
    } catch (error) {
        console.error("Error updating portfolio :", error);
        throw error;
    }
};

export const updatePortfolio = async (portfolioId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${portfolioId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating portfolio :", error);
        throw error;
    }
};

export const deletePortfolio = async (portfolioId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${portfolioId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting portfolio :", error);
        throw error;
    }
};
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Link, Stack, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import Popup from "../../../../../Popup/Popup";
import {Table, TableActionMenu} from "../../../../../Tables/Table";
import {Display} from "../../../../../Inputs/Display";
import {OnboardingStorageLinkForm} from "./OnboardingStorageLinkForm";
import {deleteMissionStorageLink, fetchMissionStorageLinks} from "../../../../../../services/missionStorageLink";
import {CustomTooltipCell} from "../../../../../Tables/CustomTooltipCell";
import Utils from "../../../../../../utils/Utils";
import Functions from "../../../../../../utils/Functions";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const OnboardingStorageLink = ({formData, setFormData, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [storageLinks, setStorageLinks] = useState([]);
    const defaultValue = {
        MissionId: formData?.id,
        title: '',
        description: '',
        url: '',
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);

    const fetchStorageLinksRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });

    const columns = [
        {
            field: 'title',
            headerName: t("Link title"),
            type: 'string',
            minWidth: 200,
            flex: 2,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'description',
            headerName: t("Description"),
            type: 'string',
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.description ? <CustomTooltipCell value={row.description}/> : "-";
            }
        },
        {
            field: 'url',
            headerName: t("Link"),
            minWidth: 200,
            flex: 2,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return <Link
                    href={Utils.formatUrl(row.url)}
                    underline="none"
                    target="_blank"
                    sx={{display: "block", width: "100%"}}>
                        <Display type="textTruncate" value={row.url}/>
                </Link>
            }
        },
    ];

    if (canModify) {
        columns.push({
            field: 'action',
            headerName: '',
            minWidth: 70,
            flex: 0.7,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return <TableActionMenu
                    row={row}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            },
        });
    }

    useEffect(() => {
        fetchStorageLinksRef.current(filterConfigs);
    }, [formData.id, filterConfigs]);

    fetchStorageLinksRef.current = async (query = {}) => {
        try {
            if (formData?.id) {
                const fetchedData = await fetchMissionStorageLinks(formData.id, query, token);
                setStorageLinks(fetchedData.data);
                setRowCount(fetchedData.count);
            }
        } catch (error) {

        }
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleEdit = (id) => {
        const index = storageLinks.findIndex((sl) => sl.id === id);
        if (index > -1) {
            setEditingData(storageLinks[index] ?? defaultValue);
            setEditingMode(true);
        }
    };

    const handleDelete = async (id) => {
        await deleteMissionStorageLink(id, token);
        fetchStorageLinksRef.current(filterConfigs);
    };

    const handleDialogClose = () => {
        setEditingMode(false);
    };

    const handleSaveFinish = (success) => {
        if (success) {
            fetchStorageLinksRef.current(filterConfigs);

            const newSectionStatus = Utils.updateStatusSection(
                formData.status_section,
                Functions.SECTION_ONBOARDING
            );
            setFormData({...formData, status_section: newSectionStatus});

            handleDialogClose();
        }
    };

    return <Box sx={{width: '100%'}}>
        <Stack direction="column" alignItems="flex-start" spacing={2}>
            <Typography variant="label">{t("Links to storage spaces")}</Typography>
            {!canModify && storageLinks.length === 0 && (
                <Alert icon={false} severity="info" sx={{width: '100%'}}>
                    {t("No link added")}
                </Alert>
            )}
            {storageLinks.length > 0 && (
                <Table
                    className="MuiDataGrid-cell-padding-20"
                    columns={columns}
                    data={storageLinks}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                    hideFilter={true}
                />
            )}
            {canModify && (
                <>
                    <Button variant="contained" size="small" color="secondary" onClick={handleCreate}>
                        {t("Add")}
                    </Button>

                    <Popup
                        title={t("Add a link")}
                        open={isEditingMode}
                        onDialogClose={handleDialogClose}
                    >
                        <OnboardingStorageLinkForm
                            storageLink={editingData}
                            onSaveFinish={handleSaveFinish}
                        />
                    </Popup>
                </>
            )}
        </Stack>
    </Box>
};
import {useState} from "react";
import {fetchFile} from "../services/fileService";

/**
 * custom Hook pour ouvrir le fichier sauvegardé sous S3
 * @returns {[boolean,{}]}
 */
const useFileOpener = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({type: null, message: ''});

    const openFileInNewTab = async (fileValue, fileType, authToken) => {
        try {
            setLoading(true);
            const fetchedFile = await fetchFile(fileValue, fileType, authToken);

            // Créez une URL à partir du blob
            const url = URL.createObjectURL(fetchedFile);

            // Ouvrez le nouvel onglet du navigateur avec l'URL du blob
            const newTab = window.open(url, "_blank");

            setLoading(false);
        } catch (error) {
            setMessage({type: "error", message: "Fichier non trouvé."});
            setLoading(false); // Fin du chargement en cas d'erreur
        }
    }

    return {loading, message, openFileInNewTab};
}

export default useFileOpener
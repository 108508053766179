import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/missionresources/";

export const fetchMissionResources = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching missionResources :", error);
        throw error;
    }
};

export const createMissionResource = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating missionResource :", error);
        throw error;
    }
};

export const updateMissionResource = async (missionResourceId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${missionResourceId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating missionResource :", error);
        throw error;
    }
};

export const deleteMissionResource = async (missionResourceId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${missionResourceId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting missionResource :", error);
        throw error;
    }
};
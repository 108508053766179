import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppLayout} from "../../../components/UI/AppLayout";
import Functions from "../../../utils/Functions";
import {fetchOffers} from "../../../services/recruitment";
import {Table} from "../../../components/Tables/Table";
import {useNavigate} from "react-router-dom";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const JobApplicationBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const tableRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const columns = useMemo(() => {
        return [
            {
                field: 'title',
                type: 'string',
                headerName: t("Offer title"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.title;
                }
            },
            {
                field: 'candidateCount',
                type: 'number',
                noSearchFilter: true,
                headerName: t("Total candidates"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return parseInt(row.candidateCount) > 0 ? row.candidateCount : "-";
                }
            },
            {
                field: 'newCandidateCount',
                type: 'number',
                noSearchFilter: true,
                headerName: t("New candidates"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return parseInt(row.newCandidateCount) > 0 ? row.newCandidateCount : "-";
                }
            },
            {
                field: 'createdAt',
                type: 'date',
                headerName: t("Last application date"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
                renderCell: ({row}) => {
                    return "-"; //Utils.formatDate(row.createdAt);
                }
            },
        ];
    }, []);

    const loadOffers = useCallback((query = {}) => {
        try {
            if (isEnterpriseSession) {
                fetchOffers(query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isEnterpriseSession]);

    useEffect(() => {
        loadOffers(filterConfigs);
    }, [filterConfigs, loadOffers]);

    const handleCellClick = ({id}) => {
        navigate(`/recruitment/candidates/detail/${id}`);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout>
            <div className="pageheader">
                <h1 className="pagetitle">
                    {t("Job applications")}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
            </div>
            <div className="table-layout">
                <Table
                    ref={tableRef}
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                    onCellClick={handleCellClick}
                />
            </div>
        </AppLayout>
    );
};
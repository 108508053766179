import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useTranslation} from "react-i18next";

import './../index.css';
import './../page.css';
import "../../components/Forms/form.css";
import "../../components/Forms/FreelanceProfile/freelanceprofileform.css";

import {SlEnvolopeLetter} from "react-icons/sl";

import logosmall from '../../assets/smarteem_logo_small.png';
import {fetchRequest} from "../../services/enterpriseCollaborationRequestService";
import {HelperCard} from "../../components/UI/HelperCard";
import {MessageComponent} from "../../components/UI/MessageComponent";
import {LanguageSelector} from "../../components/UI/LanguageSelector";
import {useDispatch} from "react-redux";
import SectionMenu from "../../components/UI/SectionMenu";
import {Box, Button} from "@mui/material";
import ResponsiveComponent from "../../components/UI/ResponsiveComponent";
import Functions from "../../utils/Functions";
import {useFreelanceProfileStore} from "../../store/freelanceProfileStore";
import {ProfileSection} from "../../components/Forms/FreelanceProfile/Sections/ProfileSection/ProfileSection";
import {AvailabilitySection} from "../../components/Forms/FreelanceProfile/Sections/AvailabilitySection";
import {ReferralSection} from "../../components/Forms/FreelanceProfile/Sections/ReferralSection";
import skillService from "../../services/skillService";


export const PublicFreelanceProfile = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const [message, setMessage] = useState({});
    const [activeSection, setActiveSection] = useState(Functions.FREELANCE_PROFILE_SECTION.PROFILE);

    useEffect(() => {
        (async () => {
            try {
                const fetchedData = await fetchRequest(id);
                if (!fetchedData.error) {
                    let freelanceProfileData = fetchedData.FreelanceProfile;
                    if (freelanceProfileData?.FreelanceProfileSkills) {
                        const skills = freelanceProfileData.FreelanceProfileSkills.map(item => item.Skill);
                        freelanceProfileData.FreelanceProfileSkills = skillService.groupSkillsByType(skills);
                    }
                    if (freelanceProfileData?.FreelanceProfileJobKeywords) {
                        freelanceProfileData.FreelanceProfileJobKeywords = freelanceProfileData.FreelanceProfileJobKeywords?.map(item => item.JobKeyword);
                    }
                    setFreelanceData(freelanceProfileData);
                    dispatch({
                        type: 'TEMPORARY_TOKEN',
                        payload: fetchedData.token,
                    });
                } else {
                    setMessage({type: 'error', message: fetchedData?.error});
                }
            } catch (error) {
                setMessage({type: 'error', message: error.message});
            }
        })()
    }, []);

    const handleButtonClick = (componentName) => {
        setActiveSection(componentName);
    };

    const sectionMenuItems = [
        {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'My profile'},
        {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
        {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
    ];

    const helperContent = (
        <>
            <HelperCard
                icon={<SlEnvolopeLetter/>}
                headerText={"Why use Smarteem?"}
                descriptionText={
                    "With Smarteem, you can: \n\n- consult freelancers' information and legal documents \n\n- sign contracts, NDAs and other documents electronically and have them signed by your freelancers \n\n- check and validate invoices sent by your freelancers"
                }
            />
        </>
    );

    return (
        <>
            <div className="onboarding-row-container layout">
                <ResponsiveComponent mobile>
                    <Box sx={{width: "100%", padding: "10px"}}>
                        <img
                            src={logosmall}
                            alt='Smarteem Logo'
                            className='logosmall'
                        />
                    </Box>
                </ResponsiveComponent>

                <ResponsiveComponent tablet desktop>
                    <div className="onboarding-column-container onboarding-column-left">
                        <img
                            src={logosmall}
                            alt='Smarteem Logo'
                            height={200}
                            className='logomedium'

                        />
                        <h1 className="onboarding-welcome-message">{t("Welcome to Smarteem")}</h1>
                        <h2 className="onboarding-welcome-message">
                            {freelanceData.User?.firstName + " " + freelanceData.User?.lastName + t(" shared his profile with you")}
                        </h2>
                        <div className="onboarding-column-container">
                            <LanguageSelector/>
                        </div>
                    </div>
                </ResponsiveComponent>

                <div className="onboarding-column-container onboarding-column-right">
                    <div className="onboarding-card-w100">
                        <div id="freelanceProfileForm" style={{marginTop: "15px"}}>
                            {Object.keys(freelanceData).length > 0 &&
                                <div className="form-layout" style={{marginTop: "2vh"}}>
                                    <div className="workflowmenu">
                                        <SectionMenu
                                            color="info"
                                            sectionMenuItems={sectionMenuItems}
                                            activeSection={activeSection}
                                            onSectionMenuClick={handleButtonClick}
                                        />
                                    </div>
                                    <div className="standardform standardform-w50">
                                        {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                                            <ProfileSection
                                                formData={freelanceData}
                                                editPermissions={[]}
                                                canModify={false}
                                                fullWidth={true}
                                            />
                                        )}
                                        {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                                            <AvailabilitySection
                                                formData={freelanceData}
                                                setFormData={setFreelanceData}
                                                editPermissions={[]}
                                            />
                                        )}
                                        {activeSection === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                                            <ReferralSection
                                                formData={freelanceData}
                                                setFormData={setFreelanceData}
                                                canModify={false}
                                            />
                                        )}
                                    </div>
                                    <div className="helpercard-container">
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            onClick={() => navigate("/")}
                                            fullWidth
                                        >{t("Create my account on Smarteem")}</Button>
                                        {helperContent}
                                    </div>
                                </div>
                            }
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <MessageComponent type={message.type} message={message.message} width="90%"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
import {Box, Button, Grid} from "@mui/material";
import Popup from "./Popup";
import {useTranslation} from "react-i18next";
import {SignaturePad} from "../SignatureCanvas/SingnaturePad";
import {useRef} from "react";

export const SignaturePadPopup = ({open, title, onConfirm, onDialogClose}) => {
    const {t} = useTranslation();

    const signatureCanvasRef = useRef(null);

    const handleSave = async (e) => {
        e.preventDefault();
        // Convertir le contenu du canvas en une image PNG avec html-to-image
        const dataUrl = await signatureCanvasRef.current.toDataURL("image/png");

        // Convertir l'URL en un objet Blob
        const blobSignature = await fetch(dataUrl).then((res) => res.blob());
        onConfirm && onConfirm(blobSignature);
    };

    const handleClear = (e) => {
        e.preventDefault();
        signatureCanvasRef.current.clear();
    };

    return <Popup
        open={open}
        title={title}
        onDialogClose={onDialogClose}
    >
        <Box sx={{width: {md: "30vw"}, display: "flex", justifyContent:"center"}}>
            <SignaturePad
                signatureCanvasRef={signatureCanvasRef}
                signatureOfText={t("Signature field")}
                handleClear={handleClear}
                handleSave={handleSave}
            />
        </Box>
    </Popup>
};
import React from "react";
import "./chart.css";
import Chart from "react-apexcharts";

export const DonutChart = (
    {
        series,
        categories,
        chartHeight
    }
) => {

    const data = {
        series: series,
        options: {
            chart: {
                type: 'donut',
                fontFamily: 'Poppins',
            },
            legend: {
                position: 'bottom',
                onItemHover: {
                    highlightDataSeries: false
                }
            },
            plotOptions: {
                pie: {
                    expandOnClick: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            labels: categories,
        }
    };

    return (
        <Chart
            type="donut"
            options={data.options}
            series={data.series}
            height={chartHeight}
        />
    );
};
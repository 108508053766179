import React from 'react';
import {useTranslation} from "react-i18next";
import {Box, Tab, Tabs} from "@mui/material";

import './displaybar.css';

export const DisplayBar = ({displayItems = [], displayType, onDisplayMenuClick}) => {
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        onDisplayMenuClick && onDisplayMenuClick(newValue);
    };

    return <>
        {displayItems.length > 0 &&
            <Box sx={{width: '100%', mb: 3}}>
                <Tabs
                    value={displayType}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="primary"
                >
                    {displayItems.map((displayItem) => (
                        <Tab key={displayItem.key} value={displayItem.key} label={t(displayItem.name)} />
                    ))}
                </Tabs>
            </Box>
        }
    </>
}
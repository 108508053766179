import { createStore,combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';

import authReducer from './reducers/authReducer';


const rootReducer = combineReducers({
  auth: authReducer,
});

// Configuration for Redux Persist
const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies, {sameSite: 'none'}),
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
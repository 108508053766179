import React, {memo, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {addUserToCompany} from "../../services/userService";

import Functions from "../../utils/Functions";
import {FreelanceSearchStep} from "../Forms/FreelanceProfile/FreelanceCreation/FreelanceSearchStep";
import {FreelancePersonalInfoStep} from "../Forms/FreelanceProfile/FreelanceCreation/FreelancePersonalInfoStep";
import {FreelanceInfoStep} from "../Forms/FreelanceProfile/FreelanceCreation/FreelanceInfoStep";
import Utils from "../../utils/Utils";
import {Box, Button, Grid} from "@mui/material";
import ResponsiveStepper from "../UI/ResponsiveStepper";
import Popup from "./Popup";


export const FreelancePopup = memo(({show, onClose, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [message, setMessage] = useState({});
    const [freelanceStep, setFreelanceStep] = useState(Functions.TEXT_SEARCH);
    const [disabledBtnSave, setDisabledBtnSave] = useState(false);

    const defaultFormData = {
        existingUser: false,
        email: "",
        firstName: "",
        lastName: "",
        main_type: Functions.SESSION_TYPE_FREELANCE,
        password: Utils.generateRandomPassword(15),
    };
    const [formData, setFormData] = useState(defaultFormData);

    const [freelanceProfileData, setFreelanceProfileData] = useState({});
    const [messageData, setMessageData] = useState("");

    const steps = useMemo(() => {
        const _steps = [
            {key: Functions.TEXT_SEARCH, name: 'Checking the e-mail'},
            {key: Functions.TEXT_PERSONAL_INFO_STEP, name: 'Add freelancer'},
        ];

        if (!formData.existingUser && freelanceStep !== Functions.TEXT_SEARCH) {
            _steps.push({key: Functions.TEXT_VALIDATION_STEP, name: 'Validation'});
        }

        return _steps;
    }, [formData, freelanceStep]);

    useEffect(() => {
        setMessage({});
    }, []);

    const handleClickNextStep = async () => {
        const proceedToNextStep = (nextStep) => {
            const verifyData = Utils.verifyDataFreelance(t, formData, freelanceStep);
            if (verifyData.type === "error") {
                setMessage(verifyData);
            } else {
                setFreelanceStep(nextStep);
                setMessage({});
            }
        };

        switch (freelanceStep) {
            case Functions.TEXT_SEARCH:
                proceedToNextStep(Functions.TEXT_PERSONAL_INFO_STEP);
                break;
            case Functions.TEXT_PERSONAL_INFO_STEP:
                if (formData.existingUser) {
                    await handleSaveFreelanceInfo();
                } else {
                    proceedToNextStep(Functions.TEXT_VALIDATION_STEP);
                }
                break;
            default:
                break;
        }
    };

    const handleClickPreviousStep = () => {
        switch (freelanceStep) {
            case Functions.TEXT_PERSONAL_INFO_STEP:
                setFreelanceStep(Functions.TEXT_SEARCH);
                break;
            case Functions.TEXT_VALIDATION_STEP:
                setFreelanceStep(Functions.TEXT_PERSONAL_INFO_STEP);
                break;
            default:
                break;
        }
    };

    const handleSaveFreelanceInfo = async () => {
        setMessage({type: "loading", message: t("Saving data")});

        setDisabledBtnSave(true);
        try {
            const userDatas = formData;
            const freelanceProfileDatas = freelanceProfileData;

            userDatas.main_type = Functions.SESSION_TYPE_FREELANCE;

            const finalData = {
                userData: userDatas,
                freelanceProfile: freelanceProfileDatas,
                notificationMessage: messageData,
            };

            const responseData = await addUserToCompany({userData: finalData,token: token});
            if (responseData.success) {
                setDisabledBtnSave(false);
                await new Promise((resolve) => setTimeout(resolve, 600));

                handleDialogClose(true);
            }
        } catch (error) {
            setMessage({type: "error", message: error.message});
        }
    };

    const handleDialogClose = (reload = false) => {
        setFreelanceProfileData({});
        setFormData(defaultFormData);
        setMessage({});
        setMessageData("");
        setFreelanceStep(Functions.TEXT_SEARCH);

        onClose && onClose(reload);
    };

    return (
        <>
            <Popup
                open={show}
                onDialogClose={() => handleDialogClose()}
            >
                <Box sx={{width: {md: "40vw"}}}>
                    <Grid container direction="column" columns={12} spacing={2}>
                        <Grid item xs={12}>
                            <ResponsiveStepper
                                steps={steps}
                                stepKey={freelanceStep}
                                onStepClick={(index) => (
                                    setFreelanceStep(steps[index]?.key)
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {freelanceStep === Functions.TEXT_SEARCH && (
                                <FreelanceSearchStep
                                    formData={formData}
                                    setFormData={setFormData}
                                    setFreelanceStep={setFreelanceStep}
                                    message={message}
                                    setMessage={setMessage}
                                    canModify={canModify}
                                />
                            )}

                            {freelanceStep === Functions.TEXT_PERSONAL_INFO_STEP && (
                                <FreelancePersonalInfoStep
                                    formData={formData}
                                    setFormData={setFormData}
                                    message={message}
                                    onSave={handleClickNextStep}
                                    canModify={canModify}
                                />
                            )}

                            {freelanceStep === Functions.TEXT_VALIDATION_STEP && (
                                <FreelanceInfoStep
                                    messageData={messageData}
                                    setMessageData={setMessageData}
                                    canModify={canModify}
                                />
                            )}
                        </Grid>

                        {freelanceStep === Functions.TEXT_VALIDATION_STEP && (
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                                <div className="bloc-list-btn">
                                    <Button
                                        variant="contained"
                                        color="light"
                                        disabled={freelanceStep === Functions.TEXT_SEARCH}
                                        size="medium"
                                        onClick={handleClickPreviousStep}
                                    >{t("Previous")}</Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={disabledBtnSave}
                                        size="medium"
                                        onClick={handleSaveFreelanceInfo}
                                    >{t("Validate")}</Button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Popup>
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.show === nextProps.show;
});

import React from "react";
import {Menu} from "./Menu";

import "./applayout.css";

export const AppLayout = ({className = "", children}) => {
    return (
        <div className="layout">
            <Menu/>
            <div className="container-content">
                <div className={`content ${className}`}>{children}</div>
            </div>
        </div>
    );
};

import {Alert, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Utils from "../../../utils/Utils";
import {Trans, useTranslation} from "react-i18next";

export const MissionMissingFieldAlert = ({missingFields}) => {
    const {t} = useTranslation();
    const [message, setMessage] = useState("");

    useEffect(() => {
        const _message = missingFields.fields.map((missingField) => {
            let errorMessage;
            if (missingFields.errorTemplate) {
                errorMessage = <Trans
                    t={t}
                    i18nKey={missingFields.errorTemplate}
                    values={{field: `"${t(Utils.mapFieldToLabel('mission', missingField))}"`}}
                    components={[<span></span>]}
                />
            } else {
                errorMessage = t(Utils.mapFieldToLabel('mission', missingField));
            }

            return (
                <Stack key={missingField}>
                    <Typography>
                        - {errorMessage}
                    </Typography>
                </Stack>
            );
        })
        setMessage(_message);
    }, [missingFields]);

    return <Stack sx={{width: "100%", height: "100%"}}>
        <Alert severity="warning" sx={{borderRadius: "15px"}}>
            <Typography sx={{mt: 0.5}}>
                <Trans
                    t={t}
                    i18nKey="In the <0>{{section}}</0> section"
                    values={{section: t(missingFields.section?.name)}}
                    components={[<strong></strong>]}
                /> :
            </Typography>
            <Stack direction="column" sx={{mt: 1}}>
                {message}
            </Stack>
        </Alert>
    </Stack>
}
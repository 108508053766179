import React, {useEffect, useState} from "react";
import {AppLayout} from "../components/UI/AppLayout";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {fetchContract} from "../services/contractService";
import {ContractDetailSection} from "../components/Forms/Mission/Sections/ContractSection/ContractDetailSection";

export const ContractDetail = () => {
    const {id} = useParams();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [contract, setContract] = useState({});

    useEffect(() => {
        (async () => {
            if (typeof id !== "undefined") {
                const fetchedContract = await fetchContract(id, token);
                setContract(fetchedContract);
            }
        })();
    }, [id, token]);

    return (
        <>
            <AppLayout>
                <ContractDetailSection
                    selectedContract={contract}
                    setSelectedContract={setContract}
                    sessionType={sessionType}
                    isSection={false}
                />
            </AppLayout>
        </>
    );
};
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + '/api/freelanceevaluation/';

export const createEvaluation = async (datas, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', datas, token);
    } catch (error) {
        console.error('Error creating evaluation:', error);
        throw error;
    }
};

export const fetchEvaluationsByMission = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const fetchCustomerEvaluation = async (evaluationId) => {
    try {
        const response = await fetch(`${BASE_URL}customer-evaluation/${evaluationId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (response.status === 204) {
            // La réponse est vide (204 No Content)
            return []; // ou toute autre action appropriée
        } else {
            return await response.json();
        }
    } catch (error) {
        console.error('Error fetching evaluation:', error);
        throw error;
    }
};

export const updateEvaluation = async (evaluationId, datas, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${evaluationId}`, 'PUT', datas, token);
    } catch (error) {
        console.error('Error updating evaluation:', error);
        throw error;
    }
};

export const updateCustomerEvaluation = async (evaluationId, datas) => {
    try {
        return requestApi(`${BASE_URL}modify-customer-evaluation/${evaluationId}`, 'PUT', datas);
    } catch (error) {
        console.error('Error updating evaluation:', error);
        throw error;
    }
};
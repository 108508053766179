import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importez les fichiers de traduction
import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en", // Langue de secours
        interpolation: {
            escapeValue: false // Ne pas échapper les valeurs interprétées
        }
    });

export default i18n;
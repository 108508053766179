import {forwardRef} from "react";
import {useSelector} from "react-redux";
import {createRequest} from "../../../services/enterpriseCollaborationRequestService";
import {HelperCard} from "../../UI/HelperCard";
import {SlEnvolopeLetter} from "react-icons/sl";

const ValidationStep = forwardRef(({data, setLoading, onSubmit}, ref) => {
    const token = useSelector((state) => state.auth.token);

    const handleSubmit = () => {
        (async () => {
            setLoading(true);
            const response = await createRequest(data, token);
            setLoading(false);

            onSubmit && onSubmit(response);
        })()
    };
    ref.current = handleSubmit;

    return (
        <HelperCard
            icon={<SlEnvolopeLetter/>}
            headerText={"You have filled in all the necessary fields to send your Smarteem profile details"}
            descriptionText={
                "Your contact will receive an email from Smarteem including your message. He will have access to a link giving access to your freelance profile and all your information during 30 days. \nYour contact will also be given the opportunity to manage an assignment with you via Smarteem.\nBy clicking on validate you consent on sharing your information with the people using this link."
            }
        />
    )
})

export default ValidationStep
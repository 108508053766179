import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Functions from "../../../../../utils/Functions";
import {Table} from "../../../../Tables/Table";
import Utils from "../../../../../utils/Utils";
import {Button, Chip, Grid, Stack, Typography} from "@mui/material";
import Popup from "../../../../Popup/Popup";
import {
    fetchSatisfactionsByMission
} from "../../../../../services/freelanceSatisfactionService";
import {Box} from "@mui/system";
import {FreelanceSatisfactionDetailSection} from "./FreelanceSatisfactionDetailSection";
import {FreelanceSatisfactionForm} from "./FreelanceSatisfactionForm";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const FreelanceSatisfactionListSection = forwardRef((
    {
        formData,
        setFormData,
        objectAttachedType,
        isSection = true,
        sessionType,
        canModify
    },
    ref
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState({});

    const staffedFreelance = formData?.StaffedFreelance;

    const defaultFormDataFeedback = {
        assessorFirstName: staffedFreelance?.User.firstName,
        assessorLastName: staffedFreelance?.User.lastName,
        MissionId: formData?.id,
        assessmentFreelanceStatus: Functions.TEXT_PENDING
    };
    const [formDataFeedback, setFormDataFeedback] = useState(defaultFormDataFeedback);

    const [feedbacks, setFeedbacks] = useState([]);
    const tableRef = useRef();
    const fetchFeedbacksRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const columns = [
        {
            field: 'Mission.StaffedFreelance.User.fullName',
            type: 'string',
            headerName: t("Freelancer"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.Mission?.StaffedFreelance?.User.fullName;
            }
        },
        {
            field: 'createdAt',
            type: 'date',
            headerName: t("Date"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
            renderCell: ({row}) => {
                return Utils.formatDate(row.createdAt);
            }
        },
        {
            field: 'nps',
            type: 'number',
            headerName: t("Satisfaction level"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.nps !== null ? <Chip
                    label={row.nps}
                    className={Utils.getRecommendationStatus(parseFloat(row.nps))}
                /> : <></>;
            }
        },
        {
            field: 'assessmentFreelanceStatus',
            type: 'singleSelect',
            headerName: t("Status"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueOptions: Object.entries(Functions.STATUS_FEEDBACK).map(([value, label]) => ({
                value: value,
                label: t(label)
            })),
            renderCell: ({row}) => {
                const status = t(Functions.STATUS_FEEDBACK[row.assessmentFreelanceStatus]);
                return <Chip
                    label={status}
                    title={status}
                    size="small"
                    className={Functions.STATUS_FEEDBACK_BG[row.assessmentFreelanceStatus]}
                />
            }
        }
    ];

    useEffect(() => {
        fetchFeedbacksRef.current(filterConfigs);
    }, [formData.id, filterConfigs]);

    fetchFeedbacksRef.current = async (query = {}) => {
        try {
            if (formData?.id) {
                const fetchedFeedbacks = await fetchSatisfactionsByMission(formData.id, query, token);
                if (!fetchedFeedbacks?.error) {
                    setRowCount(fetchedFeedbacks.count);
                    setFeedbacks(fetchedFeedbacks.data);
                }
            }
        } catch (error) {
        }
    };

    const updateSectionStatus = () => {
        const newSectionStatus = Utils.updateStatusSection(
            formData.status_section,
            Functions.SECTION_FREELANCE_SATISFACTION
        );
        setFormData({...formData, status_section: newSectionStatus});
    };

    const handleFeedbackSaveFinish = (success) => {
        if (success) {
            setShowFeedbackDialog(false);
            handleInitList();
            updateSectionStatus();
        }
    };

    const handleFeedbackDialogShow = () => {
        setFormDataFeedback(defaultFormDataFeedback);
        setShowFeedbackDialog(true);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleFeedbackCellClick = (row) => {
        setSelectedFeedback(row);
    };

    const handleInitList = () => {
        setSelectedFeedback({});
        setFormDataFeedback(defaultFormDataFeedback);
        tableRef.current && tableRef.current.initTable();
        setFilterConfigs(defaultFilterConfigs);
    };

    if (ref) {
        ref.current = handleInitList;
    }

    return <>
        <div className="card standardform-card card-padding-wide" style={{display: "block", height: "100%"}}>
            {Object.keys(selectedFeedback).length === 0 && (
                <div className="common-board-container" style={{width: "100%"}}>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <Typography variant="labelInfo">
                            {t("Feedbacks list")}
                        </Typography>
                        {canModify && (
                            <Stack direction="row" spacing={1} sx={{justifyContent: {xs: "start", md: "flex-end"}}}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleFeedbackDialogShow}
                                >
                                    {t("Ask feedback to the freelancer")}
                                </Button>
                                <Popup open={showFeedbackDialog}
                                       title={t("Ask the freelancer for feedback on the assignment")}
                                       onDialogClose={() => setShowFeedbackDialog(false)}
                                >
                                    <Box sx={{width: {md: "30vw"}}}>
                                        <FreelanceSatisfactionForm
                                            feedback={formDataFeedback}
                                            canModify={true}
                                            onSaveFinish={handleFeedbackSaveFinish}
                                        />
                                    </Box>
                                </Popup>
                            </Stack>
                        )}
                    </Stack>

                    <Table
                        ref={tableRef}
                        columns={columns}
                        data={feedbacks}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        onChange={handleDataGridChange}
                        onCellClick={handleFeedbackCellClick}
                    />
                </div>
            )}
            {Object.keys(selectedFeedback).length > 0 && (
                <FreelanceSatisfactionDetailSection
                    formData={formData}
                    selectedFeedback={selectedFeedback}
                    sessionType={sessionType}
                    onInitList={handleInitList}
                />
            )}
        </div>
    </>
});
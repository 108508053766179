import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/recruitment/";

export const fetchOffer = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}offers/${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching offer:", error);
        throw error;
    }
};

export const fetchOffers = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}offers?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching offer :", error);
        throw error;
    }
};

export const createOffer = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}offers/create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating offer :", error);
        throw error;
    }
};

export const updateOffer = async (offerId, data, token) => {
    try {
        return requestApi(`${BASE_URL}offers/modify/${offerId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating offer :", error);
        throw error;
    }
};

export const deleteOffer = async (offerId, token) => {
    try {
        return requestApi(`${BASE_URL}offers/delete/${offerId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting offer :", error);
        throw error;
    }
};
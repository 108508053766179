import React, {useState} from "react";
import {Alert, Box, Grid, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {MdMoreVert, MdOutlineMail} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {Display} from "../../../../Inputs/Display";
import {FaPhoneAlt} from "react-icons/fa";
import {PiLinkedinLogoBold} from "react-icons/pi";

const ReferralList = ({referrals, canModify, onEdit, onDelete, ...props}) => {
    const {t} = useTranslation();

    return <>
        <div style={{width: '100%'}}>
            {referrals.length === 0 &&
                <Alert icon={false} severity="info">{t("No referral added")}</Alert>
            }
            {referrals?.map((referral, index) => (
                <ReferralRow
                    key={index}
                    referral={referral}
                    canModify={canModify}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    fullWidth={props.fullWidth}
                />
            ))}
        </div>
    </>
};

const ReferralRow = ({referral, canModify, onEdit, onDelete, ...props}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleEdit = () => {
        handleClose();
        onEdit && onEdit(referral.id);
    }

    const handleDelete = () => {
        handleClose();
        onDelete && onDelete(referral.id);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <>
        <div style={{
            position: "relative",
            backgroundColor: "#ffffff",
            textAlign: "left",
            marginBottom: "15px",
            border: "1px solid #09174736",
            borderRadius: "1ch",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            gap: "12px"
        }}>
            <Typography variant="label" sx={{fontSize: "20px", color: "#091747"}}>{referral.fullName}</Typography>
            <Stack spacing={0.5}>
                {referral.companyName && (
                    <Typography className="w-100" sx={{whiteSpace: "break-spaces"}}>
                        <Typography variant="label" sx={{color: "#091747", display: "inline-block"}}>
                            {t("Company")} : &nbsp;
                        </Typography>
                        {referral.companyName}
                    </Typography>
                )}
                {referral.context && (
                    <Typography className="w-100" sx={{whiteSpace: "break-spaces"}}>
                        <Typography variant="label" sx={{color: "#091747", display: "inline-block"}}>
                            {t("Context / Assignment")} : &nbsp;
                        </Typography>
                        {referral.context}
                    </Typography>
                )}
            </Stack>
            <Grid container
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  columnSpacing={1.5}
                  rowGap={1}
                  sx={{width: "100%", mt: 0.5}}
            >
                {referral.phoneNumber &&
                    <Grid item xs={12} md={props.fullWidth ? 12 : 2.5}>
                        <Typography variant="iconText">
                            <FaPhoneAlt className="Mui-icon" size={16} style={{minWidth: "20px", marginRight: "10px"}}/>
                            <Typography className="Mui-textTruncate">
                                {referral.phoneNumber}
                            </Typography>
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} md={props.fullWidth ? 12 : 5}>
                    <Typography variant="iconText" title={referral.email}>
                        <MdOutlineMail className="Mui-icon" size={24} style={{minWidth: "20px", marginRight: "10px"}}/>
                        <Typography className="Mui-textTruncate">
                            {referral.email}
                        </Typography>
                    </Typography>
                </Grid>
                {referral.linkedinProfile &&
                    <Grid item xs={12} md={props.fullWidth ? 12 : (referral.phoneNumber ? 4.5 : 7)}>
                        <Stack direction="row" alignItems="center" className="w-100">
                            <PiLinkedinLogoBold className="Mui-icon" size={24}
                                             style={{minWidth: "20px", marginRight: "10px"}}/>
                            <Box className="Mui-textTruncate">
                                <Display type="url" value={referral.linkedinProfile}/>
                            </Box>
                        </Stack>
                    </Grid>
                }
            </Grid>

            {canModify &&
                <div style={{position: "absolute", top: "5px", right: "5px"}}>
                    <Grid container justifyContent="flex-end">
                        <IconButton
                            aria-controls="item-actions-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MdMoreVert/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleEdit}>{t("Edit")}</MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <Typography color="secondary">{t("Delete")}</Typography>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </div>
            }
        </div>
    </>
};

export {
    ReferralList,
    ReferralRow
};
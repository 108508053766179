import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/achievements/";

export const fetchAchievements = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching achievements :", error);
        throw error;
    }
};

export const fetchFreelanceAchievements = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching achievements :", error);
        throw error;
    }
};

export const createAchievement = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating achievement :", error);
        throw error;
    }
};

export const updateAchievement = async (achievementId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${achievementId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating achievement :", error);
        throw error;
    }
};

export const deleteAchievement = async (achievementId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${achievementId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting achievement :", error);
        throw error;
    }
};
import Functions from "../utils/Functions";
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/skill";

class SkillService {

    getAllDefaultSkill = async (token) => {
        try {
            return requestApi(`${BASE_URL}/get-defaults`, 'GET', {}, token);
        } catch (error) {
            console.error('Error fetching contract:', error);
            throw error;
        }
    };

    groupSkillsByType = (skills) => {
        const skillTypes = Object.values(Functions.SKILL_TYPE);

        return skillTypes.reduce((groupedSkills, type) => {
            groupedSkills[type] = skills.filter(
                (value) => value.type === type
            )
            return groupedSkills;
        }, {})
    }

    getSkillTypeLabel(skillType) {
        let label;
        switch (skillType) {
            case Functions.SKILL_TYPE.LANGUAGE:
                label = "Languages";
                break;
            case Functions.SKILL_TYPE.TECHNICAL:
                label = "Technical skills";
                break;
            case Functions.SKILL_TYPE.TRANSVERSAL:
                label = "Transversal skills";
                break;
            case Functions.SKILL_TYPE.BUSINESS:
                label = "Business lines";
                break;
            default:
                break
        }

        return label;
    };
}

export default new SkillService();
import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

import {fetchFreelanceProfile} from "../../../services/freelanceProfileService";

import "../form.css";
import {HelperCard} from "../../UI/HelperCard";
import {FaRegAddressCard} from "react-icons/fa";
import {LuShieldCheck} from "react-icons/lu";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";
import Functions from "../../../utils/Functions";
import {ProfileSection} from "../FreelanceProfile/Sections/ProfileSection/ProfileSection";
import {AvailabilitySection} from "../FreelanceProfile/Sections/AvailabilitySection";
import {ReferralSection} from "../FreelanceProfile/Sections/ReferralSection";
import {
    InformationAndDocumentSection
} from "../FreelanceProfile/Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import Utils from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {MessageComponent} from "../../UI/MessageComponent";

export const FreelanceProfileSteps = forwardRef(({activeStepKey, setLoading, onSubmit}, ref) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const freelanceId = useSelector((state) => state.auth.freelanceId);

    const {
        freelanceData,
        setFreelanceData,
        freelanceEditPermissions,
        setFreelanceEditPermissions
    } = useFreelanceProfileStore();

    const [message, setMessage] = useState({});
    const [triggerApi, setTriggerApi] = useState(true);
    const formRef = useRef(null);
    const objectAttachedType = "freelanceProfile";

    useEffect(() => {
        (async () => {
            try {
                const fetchedFreelanceProfile = await fetchFreelanceProfile(freelanceId, token, true);
                setFreelanceData(fetchedFreelanceProfile);
                setFreelanceEditPermissions([Functions.FREELANCE_PROFILE_PERMISSION.FULL]);
                setTriggerApi(false);
            } catch (error) {
            }
        })();
    }, [freelanceId, token, triggerApi]);

    useEffect(() => {
        setMessage({});
    }, [activeStepKey]);


    const handleNext = async () => {
        const verifyData = Utils.verifyDataFreelance(
            t,
            freelanceData,
            activeStepKey === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT ? "information_document" : ""
        );
        if (verifyData.type === "error") {
            setMessage(verifyData);
            return;
        }

        onSubmit && onSubmit({success: true});
    };

    if (ref) {
        ref.current = handleNext;
    }

    let helperContent;
    if (activeStepKey === "PersonalInformationSection") {
        helperContent = (
            <>
                <HelperCard
                    icon={<FaRegAddressCard/>}
                    headerText={"Why complete your profile?"}
                    descriptionText={
                        "Your Smarteem profile can be shared with multiple companies. Once completed, you can transmit all your personal information, skills, and documents to a new company with a single click."
                    }
                />
                <HelperCard
                    icon={<LuShieldCheck/>}
                    headerText={"How is your profile secured?"}
                    descriptionText={
                        "When a company seeks access to your profile, you receive a request that you can accept or reject in \"Settings\", under \"Contact Requests\". If you do not accept the request, the company will not have access to your profile."
                    }
                />
            </>
        );
    }

    return (
        <>
            <form
                ref={formRef}
                className={`standardform standardform-w70`}
                noValidate
            >
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <ProfileSection
                        formData={freelanceData}
                        objectAttachedType={objectAttachedType}
                        editPermissions={freelanceEditPermissions}
                        canModify={true}
                    />
                )}
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                    <AvailabilitySection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={freelanceEditPermissions}
                    />
                )}
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                    <ReferralSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        canModify={true}
                    />
                )}
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                    <InformationAndDocumentSection
                        formData={freelanceData}
                        objectAttachedType={objectAttachedType}
                        setFormData={setFreelanceData}
                        editPermissions={freelanceEditPermissions}
                        canModify={true}
                    />
                )}

                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />
            </form>

            {helperContent &&
                <div className="helpercard-container">{helperContent}</div>
            }
        </>
    );
});

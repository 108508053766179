import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppLayout} from "../../../components/UI/AppLayout";
import {useParams} from "react-router-dom";
import {fetchOffer} from "../../../services/recruitment";

export const JobApplicationDetail = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const {id} = useParams();

    const [offerId, setOfferId] = useState(id);
    const [offer, setOffer] = useState({});

    useEffect(() => {
        try {
            if (offerId) {
                fetchOffer(offerId, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        console.log(fetchedData);
                        setOffer(fetchedData);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [offerId, token]);

    return (
        <AppLayout>
            <div className="pageheader">
                <h1 className="pagetitle">
                    {offer && offer.title ? `${t("Job applications")} - ${offer.title}` : ""}
                </h1>
            </div>
            <div className="table-layout">
                Ici
            </div>
        </AppLayout>
    );
};
import {
    Box, Button,
    Checkbox, FormControl, IconButton, InputAdornment, InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader, OutlinedInput
} from "@mui/material";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {fetchFreelanceProfiles} from "../../services/freelanceProfileService";
import {useSelector} from "react-redux";
import {MdSearch} from "react-icons/md";
import _, {debounce} from "lodash";
import {UserCard} from "../UI/UserCard";
import Popup from "./Popup";

export const FreelanceSelectionPopup = memo(function FreelanceSelectionPopup(
    {
        open,
        title,
        help,
        onDialogClose,
        values,
        excludes = [],
        onSelect,
        maxSelection = 0
    }
) {
    const token = useSelector((state) => state.auth.token);
    const {t} = useTranslation();
    const [freelances, setFreelances] = useState([]);
    const [selectedFreelances, setSelectedFreelances] = useState(values);
    const fetchFreelancesRef = useRef();
    const customFilterRef = useRef(null);
    const [filterConfigs, setFilterConfigs] = useState({
        filter: ''
    });

    let listSubHeader = "";
    if (typeof help !== "undefined") {
        listSubHeader = <ListSubheader component="div">
            {help}
        </ListSubheader>;
    }

    const debouncedSearch = useCallback(
        debounce(({name, value}) => {
            const filters = {};
            filters[name] = {
                operator: 'contains',
                value: value
            };

            setFilterConfigs({
                ...filterConfigs,
                filter: Object.keys(filters).length ? JSON.stringify(filters) : ''
            });
        }, 300), // 300ms debounce time
        []
    );

    useEffect(() => {
        setSelectedFreelances(values);
    }, [values]);

    useEffect(() => {
        fetchFreelancesRef.current(filterConfigs);
    }, [filterConfigs]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        debouncedSearch({name, value});
    };

    const handleToggle = (freelance) => () => {
        const index = selectedFreelances.findIndex((selectedFreelance) => selectedFreelance.id === freelance.id);

        if (index === -1) {
            if (maxSelection === 0 || selectedFreelances.length < maxSelection) {
                setSelectedFreelances([...selectedFreelances, freelance]);
            }
        } else {
            const newSelectedFreelances = [...selectedFreelances];
            newSelectedFreelances.splice(index, 1);
            setSelectedFreelances(newSelectedFreelances);
        }
    };

    const handleSelection = () => {
        onSelect && onSelect(selectedFreelances);
    };

    fetchFreelancesRef.current = async (query = {}) => {
        try {
            const fetchedData = await fetchFreelanceProfiles(query, token);
            setFreelances(fetchedData.data);
        } catch (error) {

        }
    }

    return <Popup
        title={title}
        open={open}
        onDialogClose={onDialogClose}
    >
        <Box sx={{minWidth: {md: "25vw"}}}>
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="search">{t("Search")}</InputLabel>
                <OutlinedInput
                    inputProps={{ref: customFilterRef}}
                    type="text"
                    name="User.fullName"
                    id="User.fullName"
                    onChange={handleChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <MdSearch/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("Search")}
                />
            </FormControl>
            {
                freelances.length > 0 &&
                <List
                    sx={{width: '100%', maxHeight: '41vh', overflow: 'auto', marginTop: "15px"}}
                    subheader={listSubHeader}
                >
                    {freelances.filter((freelance) => !excludes.includes(freelance.id)).map((freelance) => {
                        const labelId = `checkbox-list-label-${freelance.id}`;
                        return (
                            <ListItem
                                key={freelance.id}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(freelance)}
                                        checked={selectedFreelances.some(
                                            (selectedFreelance) => selectedFreelance.id === freelance.id)
                                        }
                                        disableRipple
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={handleToggle(freelance)} dense>
                                    <ListItemAvatar>
                                        <UserCard user={freelance.User} displaySkeleton={true} hideLabel={true}/>
                                    </ListItemAvatar>
                                    <ListItemText id={labelId} primary={freelance.User.fullName}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            }

            <div style={{textAlign: "center", marginTop: 15}}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={selectedFreelances.length === 0}
                    onClick={handleSelection}
                >{t("Confirm selection")}</Button>
            </div>
        </Box>
    </Popup>
}, (prevProps, nextProps) => {
    return prevProps.open === nextProps.open &&
        _.isEqual(prevProps.values, nextProps.values) &&
        _.isEqual(prevProps.excludes, nextProps.excludes);
});
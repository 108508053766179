const GOOGLE_API_KEY = "AIzaSyAEkmP2gsaBGeGIX0ABg6HApEBR_et0bkc";

class VideoService {
    constructor() {

    }

    getVideoThumbnailUrl = async (videoUrl) => {
        let thumbnailUrl = null;

        if (this.isYouTubeVideo(videoUrl)) {
            const videoId = this.getYouTubeVideoId(videoUrl);
            thumbnailUrl = await this.getYouTubeThumbnailUrl(videoId, GOOGLE_API_KEY);
        } else if (this.isVimeoVideo(videoUrl)) {
            const videoId = this.getVimeoVideoId(videoUrl);
            thumbnailUrl = await this.getVimeoThumbnailUrl(videoId);
        }

        return thumbnailUrl;
    };

    isYouTubeVideo = (url) => {
        return url.includes('youtube.com') || url.includes('youtu.be');
    };

    isVimeoVideo = (url) => {
        return url.includes('vimeo.com');
    };

    getYouTubeVideoId = (url) => {
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);

        if (match && match[1]) {
            return match[1];
        }
        return null;
    };

    getVimeoVideoId = (url) => {
        const regExp = /vimeo\.com\/([0-9]+)/;
        const match = url.match(regExp);

        if (match && match[1]) {
            return match[1];
        }
        return null;
    };

    getYouTubeThumbnailUrl = async (videoId, apiKey) => {
        try {
            const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet`);
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération de l\'aperçu de la vidéo');
            }
            const data = await response.json();
            // Extraire l'URL de la miniature de la réponse
            return data.items[0].snippet.thumbnails.standard?.url || data.items[0].snippet.thumbnails.high.url;
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'aperçu de la vidéo :', error);
            return null;
        }
    };

    getVimeoThumbnailUrl = async (videoId) => {
        try {
            const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération de l\'aperçu de la vidéo Vimeo');
            }
            const data = await response.json();

            // Extraire l'URL de la miniature de la réponse
            return data[0].thumbnail_large;
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'aperçu de la vidéo Vimeo :', error);
            return null;
        }
    };
}

export default new VideoService();
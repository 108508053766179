import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/invoices/";

export const fetchInvoice = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching invoice:", error);
        throw error;
    }
};

export const createInvoice = async (datas, token) => {
    try {
      return requestApi(`${BASE_URL}create`, 'POST', datas, token);
    } catch (error) {
        throw error;
    }
};

export const getInvoicesByFreelance = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}get-by-freelance?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const getInvoicesByCompany = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}get-by-company?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const getInvoicesByMission = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};


export const modifyManyInvoiceStatus = async (datas, token) => {
    try {
      return requestApi(`${BASE_URL}modify-many-invoice-status`, 'POST', datas, token);
    } catch (error) {
        throw error;
    }
};

export const getInvoiceSendEmails = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}send-emails?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const sendNotification = async (datas, token) => {
    try {
        return requestApi(`${BASE_URL}send-notification`, 'POST', datas, token);
    } catch (error) {
        throw error;
    }
};
import React, {createContext, useContext, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const ToastContext = createContext();

export const ToastProvider = ({children}) => {
    const [toastOpen, setToastOpen] = useState(false);
    const [toastAutoHideDuration, setToastAutoHideDuration] = useState(3000);
    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('success');

    const showToast = (message, severity = 'success', duration) => {
        setToastMessage(message);
        setToastSeverity(severity);
        if (typeof duration !== "undefined") {
            setToastAutoHideDuration(duration);
        }
        setToastOpen(true);
    };

    const closeToast = () => {
        setToastOpen(false);
    };

    const ToastComponent = (
        <Snackbar open={toastOpen}
                  autoHideDuration={toastAutoHideDuration}
                  onClose={closeToast}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={closeToast}
                severity={toastSeverity}
            >
                {toastMessage}
            </MuiAlert>
        </Snackbar>
    );

    return (
        <ToastContext.Provider value={showToast}>
            {children}
            {ToastComponent}
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};
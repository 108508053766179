import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import "./page.css";

import {fetchMissions} from "../services/missionService";

import {AppLayout} from "../components/UI/AppLayout";
import {DisplayBar} from "../components/UI/DisplayBar";
import {UserCard} from "../components/UI/UserCard";
import Utils from "../utils/Utils";
import {Table} from "../components/Tables/Table";
import Popup from "../components/Popup/Popup";
import EnterpriseCollaborationRequestForm
    from "../components/Forms/EnterpriseCollaborationRequest/EnterpriseCollaborationRequestForm";
import {Box, Button} from "@mui/material";
import Functions from "../utils/Functions";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const MissionsBoard = () => {
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [, setError] = React.useState("");
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [displayType, setDisplayType] = useState("List");
    const [editingMode, setEditingMode] = useState("");
    const [editingData, setEditingData] = useState({});
    const tableRef = useRef();
    const fetchMissionsRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    let displayItems = [
        {
            key: 'List',
            name: isEnterpriseSession ? 'All assignments' : 'List',
            pageTitle: 'Assignments',
            statuses: [
                Functions.STATUS_MISSION.Open,
                Functions.STATUS_MISSION["In progress"],
                Functions.STATUS_MISSION.Closed
            ]
        }
    ];
    if (isEnterpriseSession) {
        displayItems = [
            ...displayItems,
            {
                key: 'MyAssignments',
                name: 'My assignments',
                pageTitle: 'My assignments',
                statuses: [
                    Functions.STATUS_MISSION.Open,
                    Functions.STATUS_MISSION["In progress"],
                    Functions.STATUS_MISSION.Closed
                ],
                operationalManager: "me"
            },
            {
                key: 'Archived',
                name: 'Archived plural',
                pageTitle: 'Archived assignments',
                statuses: [Functions.STATUS_MISSION.Archived, Functions.STATUS_MISSION.Deleted]
            }
        ];
    }

    const activeDisplay = displayItems[Utils.findIndexByKey(displayItems, displayType)];
    const pageTitle = activeDisplay?.pageTitle;

    const missionStatusFilter = activeDisplay?.statuses.map((status) => ({
        value: status,
        label: t(status)
    }));

    useEffect(() => {
        fetchMissionsRef.current(filterConfigs);
    }, [filterConfigs]);

    const handleMissionCreate = () => {
        navigate("/missions/missionsdetail");
    };

    const handleCollaborationRequestCreate = () => {
        setEditingData({
            email: null,
            companyName: "",
            firstName: "",
            lastName: "",
            language: "",
            companyMessage: "",
        })
        setEditingMode("CollaborationRequest");
    }

    const handleCollaborationRequestSaveFinish = (success) => {
        if (success) {
            handleDialogClose();
        }
    }

    const handleDialogClose = () => {
        setEditingMode("");
    }

    fetchMissionsRef.current = async (query = {}) => {
        try {
            query.statuses = JSON.stringify(activeDisplay.statuses || []);
            if (activeDisplay?.operationalManager) {
                query.operational_manager = "me";
            }

            const fetchedData = await fetchMissions(query, token);
            setRowCount(fetchedData.count);
            setData(fetchedData.data);
        } catch (error) {
            setError(error);
        }
    }

    const handleCellClick = (row) => {
        navigate(`/missions/missionsdetail/${row.id}`);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleDisplayMenuClick = (newDisplay) => {
        tableRef.current.initTable();
        setDisplayType(newDisplay);
        setFilterConfigs(defaultFilterConfigs);
    };

    let columns;
    if (isEnterpriseSession) {
        columns = [
            {
                field: 'Mission.name',
                headerName: t("Name"),
                type: 'string',
                minWidth: 200,
                flex: 2,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => row.name
            },
            {
                field: 'OperationalManager.User.fullName',
                type: 'string',
                headerName: t("Assignment Manager"),
                minWidth: 200,
                flex: 2,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const user = row.OperationalManager?.User;
                    return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
                }
            },
            {
                field: 'StaffedFreelance.User.fullName',
                type: 'string',
                headerName: t("Freelancer"),
                minWidth: 200,
                flex: 2,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const user = row.StaffedFreelance?.User;
                    return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
                }
            },
            {
                field: 'start_date',
                type: 'date',
                headerName: t("Starting date"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.start_date && new Date(row.start_date),
                renderCell: ({row}) => {
                    return Utils.formatDate(row.start_date);
                }
            },
            {
                field: 'missionDuration',
                type: 'number',
                noSearchFilter: true,
                headerName: t("Duration"),
                minWidth: 140,
                flex: 1.4,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return row.missionDuration > 0
                        ? `${row.missionDuration} ${t(row.missionDuration > 1 ? "Days" : "Day").toString().toLowerCase()}`
                        : "-";
                }
            },
            {
                field: 'status',
                type: 'singleSelect',
                headerName: t("Status"),
                minWidth: 140,
                flex: 1.4,
                headerAlign: 'center',
                align: 'center',
                valueOptions: missionStatusFilter,
                renderCell: ({row}) => {
                    return t(row.status);
                }
            }
        ];
    } else {
        columns = [
            {
                field: 'Mission.name',
                headerName: t("Name"),
                type: 'string',
                minWidth: 200,
                flex: 2,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => row.name
            },
            {
                field: 'Company.name',
                type: 'string',
                headerName: t("Company"),
                minWidth: 200,
                flex: 2,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.Company.name;
                }
            },
            {
                field: 'OperationalManager.User.fullName',
                type: 'string',
                headerName: t("Assignment Manager"),
                minWidth: 200,
                flex: 2,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const user = row.OperationalManager?.User;
                    return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
                }
            },
            {
                field: 'start_date',
                type: 'date',
                headerName: t("Starting date"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.start_date && new Date(row.start_date),
                renderCell: ({row}) => {
                    return Utils.formatDate(row.start_date);
                }
            },
            {
                field: 'missionDuration',
                type: 'number',
                noSearchFilter: true,
                headerName: t("Duration"),
                minWidth: 140,
                flex: 1.4,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return row.missionDuration > 0
                        ? `${row.missionDuration} ${t(row.missionDuration > 1 ? "Days" : "Day").toString().toLowerCase()}`
                        : "-";
                }
            },
            {
                field: 'status',
                type: 'singleSelect',
                headerName: t("Status"),
                minWidth: 140,
                flex: 1.4,
                headerAlign: 'center',
                align: 'center',
                valueOptions: missionStatusFilter,
                renderCell: ({row}) => {
                    return t(row.status);
                }
            },
        ];
    }

    let button;
    if (isEnterpriseSession) {
        button = <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleMissionCreate}
        >
            {t("Create a new assignment")}
        </Button>
    } else {
        button = <>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleCollaborationRequestCreate}
            >
                {t("Share my profile with a company")}
            </Button>

            <Popup
                title={t("Share my profile with a company")}
                open={editingMode === "CollaborationRequest"}
                onDialogClose={handleDialogClose}
            >
                <EnterpriseCollaborationRequestForm
                    collaborationRequest={editingData}
                    onSaveFinish={handleCollaborationRequestSaveFinish}
                />
            </Popup>
        </>
    }

    return (
        <AppLayout>
            <div className="pageheader">
                <h1 className="pagetitle">
                    {t(pageTitle)}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
                <div className="pageaction">
                    {button}
                </div>
            </div>
            <DisplayBar
                displayItems={displayItems}
                displayType={displayType}
                onDisplayMenuClick={handleDisplayMenuClick}
            />
            <div className="table-layout">
                <Box sx={{width: {md: "100%"}, pt: 1, overflow: "hidden"}}>
                    <Table
                        ref={tableRef}
                        columns={columns}
                        data={data}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        onChange={handleDataGridChange}
                        onCellClick={handleCellClick}
                    />
                </Box>
            </div>
        </AppLayout>
    );
};

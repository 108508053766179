import React, {useRef, useState} from "react";
import {FieldHolder} from "../../../FieldHolder";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import RatingSelector from "../../../../Inputs/RatingSelector";
import {saveCooptation} from "../../../../../services/collaborationService";
import Utils from "../../../../../utils/Utils";
import {useToast} from "../../../../../context/ToastProvider";

const CooptationForm = ({token, cooptation, onSaveFinish}) => {
    const {t} = useTranslation();
    const showToast = useToast();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(cooptation);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = ['firstName', 'lastName', 'email', 'workedWithFreelancer'];
    const workedWithFreelancerOptions = [
        {id: "1", name: t("Yes")},
        {id: "0", name: t("No")}
    ];

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleRatingChange = (name, rating) => {
        setData({...data, [name]: rating});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            const response = await saveCooptation({
                ...data,
                password: Utils.generateRandomPassword(15),
            }, token);

            if (response.message) {
                showToast(
                    t(response.message),
                    response.error ? 'error' : (response.success ? 'success' : 'warning'),
                    10000
                );
            }
            onSaveFinish && onSaveFinish(response.success);

            setLoading(false);
        })()
    };

    return <form ref={formRef} onSubmit={handleSubmit} noValidate>
        <Box sx={{width: {md: "40vw"}, display: "flex", flexDirection: "column", gap: "15px"}}>
            <Stack gap={2}>
                <Typography variant="label">{t("Freelancer information")}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FieldHolder
                            fielddesc={t("First name")}
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={data.firstName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.firstName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FieldHolder
                            fielddesc={t("Last name")}
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={data.lastName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.lastName}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FieldHolder
                            fielddesc={t("Email")}
                            type="text"
                            name="email"
                            id="email"
                            value={data.email}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.email}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FieldHolder
                            fielddesc={t("Phone number")}
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={data.phoneNumber}
                            onChange={handleChange}
                            canModify={true}
                            error={formErrors.phoneNumber}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FieldHolder
                            fielddesc={t("Have you already worked with this freelancer ?")}
                            type="dropdown"
                            name="workedWithFreelancer"
                            id="workedWithFreelancer"
                            datas={workedWithFreelancerOptions}
                            formData={data}
                            setFormData={setData}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.workedWithFreelancer}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FieldHolder
                            fielddesc={t("Language")}
                            type="dropdown"
                            set="Language"
                            name="language"
                            id="language"
                            formData={data}
                            setFormData={setData}
                            onChange={handleChange}
                            error={formErrors.language}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Stack gap={2} sx={{mt: 2}}>
                <Grid container sx={{width: "100%", alignItems: "start"}}>
                    <Grid item xs={12}>
                        <RatingSelector
                            onChange={handleRatingChange}
                            label={t("On a scale of 0 to 10, how likely would you be to recommend freelancing to someone you know or to a company?")}
                            name="recommendationProbability"
                            value={data.recommendationProbability}
                            min={0}
                            max={10}
                            justifyContent="start"
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Why?")}
                            type="multiline"
                            name="recommendationDescription"
                            id="recommendationDescription"
                            value={data.recommendationDescription}
                            onChange={handleChange}
                            canModify={true}
                            error={formErrors.recommendationDescription}
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Box sx={{textAlign: "center"}}>
                <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="secondary"
                >{t("Save")}</Button>
            </Box>
        </Box>
    </form>
}

export default CooptationForm
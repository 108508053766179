import React from "react";
import {useTranslation} from "react-i18next";

import './languageselector.css';
import {Button} from "@mui/material";

export const LanguageSelector = () => {
    const {t, i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);

    };

    const languages = {
        fr: {nativeName: 'French'},
        en: {nativeName: 'English'}
    };

    return (
        <div className="language-selector">
            {Object.keys(languages).map((language) => (
                <Button
                    key={language}
                    type="submit"
                    variant={i18n.resolvedLanguage === language ? "contained": "outlined"}
                    color="primary"
                    size="small"
                    onClick={() => changeLanguage(language)}
                >
                    {t(`${languages[language].nativeName}`)}
                </Button>
            ))}
        </div>
    );
};
import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {MdClose} from "react-icons/md";
import {styled} from "@mui/system";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Popup = (
    {
        open,
        title = "",
        onDialogClose,
        dialogActions,
        children = "",
        justifyDialogAction = "center",
        flexDirectionAction = "row",
        hideCloseButton = false,
        ...props
    }
) => {
    const style = props.style || {};
    style.maxHeight = '100vh';

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={onDialogClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="lg"
                style={style}
            >
                {title &&
                    <DialogTitle sx={{m: 0, p: 2, whiteSpace: "normal"}} id="customized-dialog-title">
                        {title}
                    </DialogTitle>
                }
                {!hideCloseButton && (
                    <IconButton
                        aria-label="close"
                        onClick={onDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        {props.dialogCloseIcon ?? <MdClose/>}
                    </IconButton>
                )}
                <DialogContent sx={{overflowX: "hidden"}}>
                    {children}
                </DialogContent>
                {dialogActions &&
                    <DialogActions sx={{flexDirection: flexDirectionAction, justifyContent: justifyDialogAction}}>
                        {dialogActions}
                    </DialogActions>
                }
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Popup
import {AppLayout} from "../components/UI/AppLayout";
import {useSelector} from "react-redux";
import {InvoiceListSection} from "../components/Forms/Mission/Sections/InvoiceSection/InvoiceListSection";

export const InvoicesBoard = () => {
    const sessionType = useSelector((state) => state.auth.sessionType);
    return (
        <>
            <AppLayout>
                <InvoiceListSection sessionType={sessionType} isSection={false}/>
            </AppLayout>
        </>
    );
};

export default InvoicesBoard;

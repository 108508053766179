import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {FieldHolder} from "../../../../FieldHolder";
import {Document} from "../../../../../Inputs/Document";
import _ from "lodash";

export const FreelanceInfo4Card = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);
    const [kbisFile, setKbisFile] = useState(null);
    const [responsibilityCertificateFile, setResponsibilityCertificateFile] = useState(null);
    const [vigilanceCertificateFile, setVigilanceCertificateFile] = useState(null);
    const [ribFile, setRibFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const [files, setFiles] = useState({});
    const requiredFields = ['kbis', 'rib'];

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
            setFiles([]);
            setKbisFile(null);
            setResponsibilityCertificateFile(null);
            setVigilanceCertificateFile(null);
            setRibFile(null);
        }
    }, [editMode]);

    const handleFileChange = (name, fileData) => {
        const selectedFiles = {...files};
        if (fileData) {
            selectedFiles[name] = fileData;
        } else {
            delete selectedFiles[name];
        }
        setFiles && setFiles(selectedFiles);
    };

    const handleSave = async () => {
        let freelanceProfileFiles = _.cloneDeep(_.values(files));
        freelanceProfileFiles.push(kbisFile);
        freelanceProfileFiles.push(responsibilityCertificateFile);
        freelanceProfileFiles.push(vigilanceCertificateFile);
        freelanceProfileFiles.push(ribFile);

        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields,
            freelanceProfileFiles
        });
        if (result?.error) {
            setFormErrors(result.data);
        } else if (result?.data) {
            setFreelanceData(result.data);
        }
    };

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            <FieldHolder
                fielddesc={t("KBIS or INSEE registration document")}
                type="file"
                name="kbis"
                id="kbis"
                value={freelanceProfileData.kbis}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setKbisFile}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.kbis}
            />
            <FieldHolder
                fielddesc={t("RC pro certifcate")}
                type="file"
                name="certificate_of_professional_responsibility"
                id="certificate_of_professional_responsibility"
                placeholder={t("RC pro certifcate")}
                value={freelanceProfileData.certificate_of_professional_responsibility}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setResponsibilityCertificateFile}
                canModify={editMode}
                error={formErrors.certificate_of_professional_responsibility}
            />
            <FieldHolder
                fielddesc={t("Vigilance certificate")}
                type="file"
                name="vigilance_certificate"
                id="vigilance_certificate"
                placeholder={t("Vigilance certificate")}
                value={freelanceProfileData.vigilance_certificate}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setVigilanceCertificateFile}
                canModify={editMode}
                error={formErrors.vigilance_certificate}
            />
            <FieldHolder
                fielddesc={t("RIB")}
                type="file"
                name="rib"
                id="rib"
                value={freelanceProfileData.rib}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setRibFile}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.rib}
            />
            <Document
                set="FreelanceProfileDocuments"
                label={t("Other documents")}
                documents={freelanceProfileData?.FreelanceProfileDocuments}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                onFileChange={handleFileChange}
                canModify={editMode}
            />
        </CustomCard>
    </>
};
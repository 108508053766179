import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";

import "./helpercard.css";

export const HelperCard = (
    {
        icon = <></>,
        headerText = "",
        descriptionText = "",
        descriptionComponent = null,
        className
    }
) => {
    const {t} = useTranslation();
    const size = 35;

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        // Montage du composant
        setIsMounted(true);

        // Démontage du composant
        return () => {
            setIsMounted(false);
        };
    }, []);

    return (
        <div className={`helpercard-block ${isMounted ? 'mounted' : ''} ${className}`}>
            {React.cloneElement(icon, {size})}
            <div className="helpercard-header">
                {t(headerText)}
            </div>
            <div className="helpercard-text">
                {descriptionComponent ? descriptionComponent : t(descriptionText)}
            </div>
        </div>
    );
};

import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Grid, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {MdAdd, MdMoreHoriz} from "react-icons/md";
import Popup from "../../../../../Popup/Popup";
import FormationForm from "./FormationForm";
import {useSelector} from "react-redux";
import {deleteFormation, fetchFreelanceFormations} from "../../../../../../services/formationService";
import {PiCertificateFill} from "react-icons/pi";

const Formation = ({freelanceProfile, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [data, setData] = useState([]);
    const defaultValue = {
        FreelanceProfileId: freelanceProfile?.id,
        degree: '',
        companyName: '',
        attainmentYear: '',
        ongoingStatus: false,
        comment: '',
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const fetchFreelanceFormationsRef = useRef();

    useEffect(() => {
        fetchFreelanceFormationsRef.current({});
    }, [freelanceProfile?.id]);

    fetchFreelanceFormationsRef.current = async (query = {}) => {
        try {
            if (freelanceProfile?.id) {
                const fetchedData = await fetchFreelanceFormations(freelanceProfile?.id, query, token);
                setData(fetchedData.data);
            }
        } catch (error) {

        }
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleEdit = (id) => {
        const index = data.findIndex((achievement) => achievement.id === id);
        if (index > -1) {
            setEditingData(data[index] ?? defaultValue);
            setEditingMode(true);
        }
    }

    const handleDelete = async (id) => {
        await deleteFormation(id, token);
        fetchFreelanceFormationsRef.current();
    }

    const handleDialogClose = () => {
        setEditingMode(false);
    }

    const handleSaveFinish = (success) => {
        if (success) {
            fetchFreelanceFormationsRef.current({});
            handleDialogClose();
        }
    }

    return <Box sx={{width: '100%'}}>
        <Stack direction="column" alignItems="flex-start" spacing={2} >
            <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                <Typography variant="label"><span>{t("Education")}</span></Typography>
                {canModify && (
                    <>
                        <Button variant="outlined" startIcon={<MdAdd/>} size="small"
                                onClick={handleCreate}>
                            {t("Add")}
                        </Button>

                        <Popup
                            title={t("Education")}
                            open={isEditingMode}
                            onDialogClose={handleDialogClose}
                        >
                            <FormationForm
                                formation={editingData}
                                onSaveFinish={handleSaveFinish}
                            />
                        </Popup>
                    </>
                )}
            </Stack>
            <Stack direction="column" alignItems="start" spacing={2} sx={{width: '100%'}}>
                {data.length === 0 && (
                    <Alert icon={false} severity="info" sx={{width: '100%'}}>
                        {t("No formation added")}
                    </Alert>
                )}
                {data?.map((formation, index) => (
                    <FormationRow
                        key={index}
                        formation={formation}
                        canModify={canModify}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                ))}
            </Stack>
        </Stack>
    </Box>
};

const FormationRow = ({formation, canModify, onEdit, onDelete}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleEdit = () => {
        handleClose();
        onEdit && onEdit(formation.id);
    };

    const handleDelete = () => {
        handleClose();
        onDelete && onDelete(formation.id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <Grid container className="formation-item" gap={1.5}>
        <Grid item xs="auto">
            <PiCertificateFill className="Mui-icon" size={30} style={{marginRight: "3px"}}/>
        </Grid>
        <Grid item xs container direction="column" gap={0.5}>
            <Typography sx={{fontWeight: "bold"}}>{formation.degree}</Typography>
            <Typography>{formation.companyName}</Typography>
            <Typography>{formation.attainmentYear}</Typography>
            <Typography className="MuiTypography-body1">{formation.comment}</Typography>
            {formation.ongoingStatus && (
                <Typography>{t("Still ongoing")}</Typography>
            )}
            {canModify &&
                <div style={{position: "absolute", top: "5px", right: "5px"}}>
                    <Grid container justifyContent="flex-end">
                        <IconButton
                            aria-controls="item-actions-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            size="small"
                        >
                            <MdMoreHoriz size={20}/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleEdit}>{t("Edit")}</MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <Typography color="secondary">{t("Delete")}</Typography>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </div>
            }
        </Grid>
    </Grid>
};

export {
    Formation,
    FormationRow
};
import {AppLayout} from "../components/UI/AppLayout";
import {ContractListSection} from "../components/Forms/Mission/Sections/ContractSection/ContractListSection";
import {useSelector} from "react-redux";

export const ContractsBoard = () => {
    const sessionType = useSelector((state) => state.auth.sessionType);
    return (
        <>
            <AppLayout>
                <ContractListSection sessionType={sessionType} isSection={false}/>
            </AppLayout>
        </>
    );
};

export default ContractsBoard;
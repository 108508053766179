import React from "react";

import {Grid, Stack} from "@mui/material";
import {FreelanceSummaryCard} from "./Card/FreelanceSummaryCard";
import {FreelanceLocationCard} from "./Card/FreelanceLocationCard";
import {Achievement} from "./Achievement/Achievement";
import {Certification} from "./Certification/Certification";
import {Formation} from "./Formation/Formation";
import {FreelanceSkillCard} from "./Card/FreelanceSkillCard";
import {FreelanceInfoCard} from "./Card/FreelanceInfoCard";
import {FreelanceContactCard} from "./Card/FreelanceContactCard";
import {useFreelanceProfileStore} from "../../../../../store/freelanceProfileStore";
import {FreelanceExperienceCard} from "./Card/FreelanceExperienceCard";
import {FreelanceCvCard} from "./Card/FreelanceCvCard";
import {FreelancePortfolioCard} from "./Card/FreelancePortfolioCard";

export const ProfileSection = ({formData, canModify, ...props}) => {
    const {
        freelanceData,
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    return (
        <Stack direction="column" gap={2} sx={{width: "100%"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={props.fullWidth ? 12 : true} sx={{overflow: "hidden"}}>
                    <FreelanceInfoCard formData={freelanceProfileData} {...props}/>
                </Grid>
                {(canModify || freelanceProfileData?.FreelancePortfolios?.length > 0) && (
                    <Grid item xs={12} md={props.fullWidth ? 12 : 6}>
                        <FreelancePortfolioCard formData={freelanceProfileData}/>
                    </Grid>
                )}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FreelanceSummaryCard formData={freelanceProfileData}/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={props.fullWidth ? 12 : 6}>
                    <FreelanceContactCard formData={freelanceProfileData}/>
                </Grid>
                <Grid item xs={12} md={props.fullWidth ? 12 : 6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FreelanceExperienceCard formData={freelanceProfileData}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FreelanceCvCard formData={freelanceProfileData}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FreelanceLocationCard formData={freelanceProfileData}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md>
                    <FreelanceSkillCard formData={freelanceProfileData}/>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md>
                    <div className="card standardform-card">
                        <Achievement
                            freelanceProfile={freelanceProfileData}
                            canModify={canModify}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md>
                    <div className="card standardform-card">
                        <Formation
                            freelanceProfile={freelanceProfileData}
                            canModify={canModify}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md>
                    <div className="card standardform-card">
                        <Certification
                            freelanceProfile={freelanceProfileData}
                            canModify={canModify}
                        />
                    </div>
                </Grid>
            </Grid>
        </Stack>
    );
};

import React, {useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useLocation,
} from "react-router-dom";

import {useSelector} from "react-redux";

import {Index} from "../pages/Index";
import {ResetPassword} from "../pages/ResetPassword";
import {TalentBoard} from "../pages/TalentBoard";
import {FreelanceProfile} from "../pages/FreelanceProfile";
import {MissionsBoard} from "../pages/MissionsBoard";
import {MissionsDetail} from "../pages/MissionsDetail";
import {Configuration} from "../pages/Configuration";
import {ContractsBoard} from "../pages/ContractsBoard";
import {Onboarding} from "../pages/Onboarding";
import {InvoicesBoard} from "../pages/InvoicesBoard";
import {PublicFreelanceProfile} from "../pages/Public/PublicFreelanceProfile";
import {PublicFreelanceEvaluation} from "../pages/Public/PublicFreelanceEvaluation";
import {FreelanceComparator} from "../pages/FreelanceComparator";
import {PublicFreelanceMission} from "../pages/Public/PublicFreelanceMission";
import {ContractDetail} from "../pages/ContractDetail";
import {InvoiceDetail} from "../pages/InvoiceDetail";
import {ReportingBoard} from "../pages/ReportingBoard";
import PermissionService from "../services/permissionService";
import QuotesBoard from "../pages/QuotesBoard";
import {QuoteDetail} from "../pages/QuoteDetail";
import {CompanyBoard} from "../pages/Company/CompanyBoard";
import {SourcingBoard} from "../pages/Sourcing/SourcingBoard";
import {SourcingFreelanceProfile} from "../pages/Sourcing/SourcingFreelanceProfile";
import {OfferBoard} from "../pages/Recruitment/Offer/OfferBoard";
import {OfferDetail} from "../pages/Recruitment/Offer/OfferDetail";
import {CandidateBoard} from "../pages/Recruitment/Candidate/CandidateBoard";
import {CandidateDetail} from "../pages/Recruitment/Candidate/CandidateDetail";
import {JobApplicationBoard} from "../pages/Recruitment/JobApplication/JobApplicationBoard";
import {JobApplicationDetail} from "../pages/Recruitment/JobApplication/JobApplicationDetail";
import {AffiliationBoard} from "../pages/Affiliation/AffiliationBoard";

const ProtectedRoute = ({element, redirectTo, isGranted = true}) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [lastVisitedPage, setLastVisitedPage] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== "/") {
            if (!isAuthenticated) {
                setLastVisitedPage(`${location.pathname}${location.search}`);
            } else if (!isGranted) {
                setLastVisitedPage("/");
            }
        }
    }, [isAuthenticated, isGranted, location]);

    return isAuthenticated && isGranted ? (
        element
    ) : lastVisitedPage ? (
        <Navigate to={redirectTo || "/"} state={{lastVisitedPage}}>
            {element}
        </Navigate>
    ) : null;
};

export const AppRouter = () => {
    const connectedUser = useSelector((state) => state.auth);

    return (
        <Router>
            <Routes>
                <Route path="/public/freelanceprofile/:id" element={<PublicFreelanceProfile/>}/>
                <Route path="/public/freelance-evaluation/:id" element={<PublicFreelanceEvaluation/>}/>
                <Route path="/public/freelance-mission/:id" element={<PublicFreelanceMission/>}/>
                <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                <Route path="/" element={<Index/>}/>
                {/* <Route path="/directauthentification/:token" element={<DirectAuthentification />} /> */}
                <Route
                    path="/onboarding"
                    element={<ProtectedRoute element={<Onboarding/>} redirectTo="/"/>}
                />
                <Route
                    path="/configuration"
                    element={<ProtectedRoute element={<Configuration/>} redirectTo="/"/>}
                />
                <Route
                    path="/companies/companiesboard"
                    element={<ProtectedRoute element={<CompanyBoard/>} redirectTo="/"/>}
                />
                <Route
                    path="/affiliation"
                    element={<ProtectedRoute element={<AffiliationBoard/>} redirectTo="/"/>}
                />
                <Route
                    path="/talents/talentboard"
                    element={<ProtectedRoute element={<TalentBoard/>} redirectTo="/"/>}
                />
                <Route
                    path="/talents/comparator"
                    element={<ProtectedRoute element={<FreelanceComparator/>} redirectTo="/"/>}
                />
                <Route
                    path="/talents/freelanceprofile/:id"
                    element={<ProtectedRoute element={<FreelanceProfile/>} redirectTo="/"/>}
                />
                <Route
                    path="/profile"
                    element={<ProtectedRoute element={<FreelanceProfile/>} redirectTo="/"/>}
                />
                <Route
                    path="/sourcing/sourcingboard"
                    element={
                        <ProtectedRoute
                            element={<SourcingBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "sourcingBoard")}
                        />
                    }
                />
                <Route
                    path="/sourcing/freelanceprofile/:id"
                    element={
                        <ProtectedRoute
                            element={<SourcingFreelanceProfile/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "sourcingFreelanceProfile")}
                        />
                    }
                />
                <Route
                    path="/recruitment/jobapplications"
                    element={
                        <ProtectedRoute
                            element={<JobApplicationBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "recruitmentJobApplications")}
                        />
                    }
                />
                <Route
                    path="/recruitment/jobapplications/detail/:id?"
                    element={
                        <ProtectedRoute
                            element={<JobApplicationDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "recruitmentJobApplicationDetail")}
                        />
                    }
                />
                <Route
                    path="/recruitment/offers"
                    element={
                        <ProtectedRoute
                            element={<OfferBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "recruitmentOffers")}
                        />
                    }
                />
                <Route
                    path="/recruitment/offers/detail/:id?"
                    element={
                        <ProtectedRoute
                            element={<OfferDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "recruitmentOfferDetail")}
                        />
                    }
                />
                <Route
                    path="/recruitment/candidates"
                    element={
                        <ProtectedRoute
                            element={<CandidateBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "recruitmentCandidates")}
                        />
                    }
                />
                <Route
                    path="/recruitment/candidates/detail/:id?"
                    element={
                        <ProtectedRoute
                            element={<CandidateDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "recruitmentCandidateDetail")}
                        />
                    }
                />
                <Route
                    path="/missions/missionsboard"
                    element={
                        <ProtectedRoute
                            element={<MissionsBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "missionsBoard")}
                        />
                    }
                />
                <Route
                    path="/missions/missionsdetail/:id?"
                    element={
                        <ProtectedRoute
                            element={<MissionsDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "missionDetail")}
                        />
                    }
                />
                <Route
                    path="/quotes/quotesboard"
                    element={
                        <ProtectedRoute
                            element={<QuotesBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "quotesBoard")}
                        />
                    }
                />
                <Route
                    path="/quotes/:id"
                    element={
                        <ProtectedRoute
                            element={<QuoteDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "quoteDetail")}
                        />
                    }
                />
                <Route
                    path="/contracts/contractsboard"
                    element={
                        <ProtectedRoute
                            element={<ContractsBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "contractsBoard")}
                        />
                    }
                />
                <Route
                    path="/contracts/:id"
                    element={
                        <ProtectedRoute
                            element={<ContractDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "contractDetail")}
                        />
                    }
                />
                <Route
                    path="/invoices/invoicesboard"
                    element={
                        <ProtectedRoute
                            element={<InvoicesBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "invoicesBoard")}
                        />
                    }
                />
                <Route
                    path="/invoices/:id"
                    element={
                        <ProtectedRoute
                            element={<InvoiceDetail/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "invoiceDetail")}
                        />
                    }
                />
                <Route
                    path="/reporting"
                    element={
                        <ProtectedRoute
                            element={<ReportingBoard/>}
                            redirectTo="/"
                            isGranted={PermissionService.hasPermission(connectedUser, "reportingBoard")}
                        />
                    }
                />
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </Router>
    );
};

import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/missionstoragelinks/";

export const fetchMissionStorageLinks = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching storageLinks :", error);
        throw error;
    }
};

export const createMissionStorageLink = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating storageLink :", error);
        throw error;
    }
};

export const updateMissionStorageLink = async (missionStorageLinkId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${missionStorageLinkId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating storageLink :", error);
        throw error;
    }
};

export const deleteMissionStorageLink = async (missionStorageLinkId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${missionStorageLinkId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting storageLink :", error);
        throw error;
    }
};
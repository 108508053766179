import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import "./usercard.css";

import {updateCompanyToFreelanceRelation} from "../../services/companyToFreelanceRelationService";
import {Button} from "@mui/material";
import Functions from "../../utils/Functions";

export const ContactRequestActions = ({contactRequest, token, className = "", setTriggerApi}) => {
    const {t} = useTranslation();
    const sessionType = useSelector((state) => state.auth.sessionType);

    const handleClick = async (value) => {
        contactRequest.access_request_status = value;
        await updateCompanyToFreelanceRelation(contactRequest.id, contactRequest, token);
        setTriggerApi(true);
    };

    let buttons;
    if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
        buttons = (
            <div className={`user-card ${className}`}>
                {(contactRequest?.access_request_status !== "Canceled") ?
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleClick("Canceled")}
                    >
                        {t("Cancel")}
                    </Button>
                    : <></>}
                {(contactRequest?.access_request_status === "Canceled") ?
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleClick("Accepted")}
                    >
                        {t("Reopen")}
                    </Button>
                    : <></>
                }
            </div>
        );
    }

    return (
        <>{buttons}</>
    );
};

import {Display} from "../../../../../Inputs/Display";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import {Box, Stack, Typography} from "@mui/material";
import {FieldHolder} from "../../../../FieldHolder";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";

export const FreelanceSummaryCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);

    const freelanceFirstName = freelanceProfileData?.User?.firstName;
    const freelanceSummary = t("{{freelanceFirstName}}, in brief", {freelanceFirstName: freelanceFirstName});

    const getDetailDialogContent = () => {
        return <>
            <Box sx={{width: {md: "40vw"}}}>
                <Stack direction="column" justifyContent="start" spacing={3}>
                    <Typography variant="label">
                        {freelanceSummary}
                    </Typography>
                    <Typography>{freelanceProfileData.summary ?? "-"}</Typography>
                </Stack>
            </Box>
        </>
    };

    return <>
        <CustomCard
            detailDialogContent={getDetailDialogContent()}
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={() =>
                handleFreelanceProfileSave({
                    token,
                    freelanceProfileData,
                    callback: () => setEditMode(false)
                })
            }
        >
            {editMode
                ? (
                    <FieldHolder
                        fielddesc={freelanceSummary}
                        type="multiline"
                        name="summary"
                        id="summary"
                        value={freelanceProfileData.summary}
                        onChange={handleFreelanceDataChange}
                        canModify={true}
                    />
                )
                : (
                    <Display
                        type="textTruncate"
                        label={freelanceSummary}
                        value={freelanceProfileData.summary}
                    />
                )
            }
        </CustomCard>
    </>
};
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {FieldHolder} from "../../../../FieldHolder";

export const FreelanceInfo1Card = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const requiredFields = ['User.countryOfResidence', 'personalAddress'];
    const userData = freelanceProfileData?.User;

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    const handleSave = async () => {
        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields
        });
        if (result?.error) {
            setFormErrors(result.data);
        }
    };

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            <FieldHolder
                fielddesc={t("Birth date")}
                type="date"
                name="User.dateOfBirth"
                id="dateOfBirth"
                value={userData?.dateOfBirth}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
            />
            <FieldHolder
                fielddesc={t("Nationality")}
                type="text"
                name="nationality"
                id="nationality"
                value={freelanceProfileData?.nationality}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
            />
            <FieldHolder
                fielddesc={t("Country of residence")}
                type="dropdown"
                set="country"
                name="User.countryOfResidence"
                id="countryOfResidence"
                placeholder={t("United states of america")}
                value={userData?.countryOfResidence}
                canModify={editMode}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                mandatoryFlag={true}
                error={formErrors["User.countryOfResidence"]}
            />
            <FieldHolder
                fielddesc={t("Personal address")}
                type="text"
                name="personalAddress"
                id="personalAddress"
                placeholder={t("1 rue du colisée 75008 Paris")}
                value={freelanceProfileData?.personalAddress}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.personalAddress}
            />
        </CustomCard>
    </>
};
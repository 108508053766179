import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + '/api/companies/';

export const createCompany = async ({companyData, token}) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', companyData, token);
    } catch (error) {
        console.error('Error creating company:', error);
        throw error;
    }
};

export const fetchCompany = async (companyId, token) => {
    try {
        const response = await fetch(`${BASE_URL}${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': token,
            },
        });
        if (response.status === 204) {
            // La réponse est vide (204 No Content)
            return {}; // ou toute autre action appropriée
        } else {
            return await response.json();
        }
    } catch (error) {
        console.error('Error fetching company:', error);
        throw error;
    }
};

export const updateCompany = async ({companyId, companyData, token}) => {
    try {
        return requestApi(`${BASE_URL}modify/${companyId}`, 'PUT', companyData, token);
    } catch (error) {
        console.error('Error updating company:', error);
        throw error;
    }
};
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import "./page.css";

import {AppLayout} from "../components/UI/AppLayout";
import {
    Button,
} from "@mui/material";
import {MdAdd, MdClose, MdEdit} from "react-icons/md";
import FreelanceComparatorList from "../components/Forms/FreelanceComparator/FreelanceComparatorList";
import {FreelanceSelectionPopup} from "../components/Popup/FreelanceSelectionPopup";


export const FreelanceComparator = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFreelances, setSelectedFreelances] = useState([]);

    const handleFreelanceSelection = (selFreelances) => {
        setSelectedFreelances(selFreelances);
        handleDialogClose();
    };

    const handleDialogClose = () => {
        setIsOpen(false);
    };

    return (
        <AppLayout>
            <div className="pageheader">
                <p className="pagetitle">{t("Freelancer/Assignment Matching")}</p>
                <div className="pageaction" style={{display: "flex", gap: "15px"}}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => setIsOpen(true)}
                        color="secondary"
                    >
                        {selectedFreelances.length > 0 ? t("Modify") : t("Add freelancers")}
                    </Button>


                    <FreelanceSelectionPopup
                        open={isOpen}
                        title={t("Select freelancers")}
                        help={t("Please select up to 3 freelancers")}
                        onDialogClose={handleDialogClose}
                        values={selectedFreelances}
                        onSelect={handleFreelanceSelection}
                        maxSelection={3}
                    />

                    {
                        selectedFreelances.length > 0 &&
                        <Button
                            startIcon={<MdClose/>}
                            size="large"
                            onClick={() => setSelectedFreelances([])}
                        >
                            {t("Remove freelancers")}
                        </Button>
                    }
                </div>
            </div>

            {
                selectedFreelances.length > 0 &&
                <FreelanceComparatorList freelances={selectedFreelances}/>
            }
        </AppLayout>
    );
};

import React from "react";
import {Trans, useTranslation} from "react-i18next";

import {FaInfo, FaCircleNotch, FaTimes, FaCheck} from "react-icons/fa";

import "./messagecomponent.css";

export const MessageComponent = ({type, message, width = "30%"}) => {
    const {t} = useTranslation();

    // Tester si le message n'est pas un composant pour traduire
    if (!React.isValidElement(message)) {
        message = t(message);
    }

    let messageComponent;
    if (type === "information") {
        messageComponent = (
            <div
                className="message-component fade-enter message-information"
                style={{width: width}}
            >
                <FaInfo size={20} className="message-icon"/>
                <p className="message-text">{message}</p>
            </div>
        );
    } else if (type === "loading") {
        messageComponent = (
            <div
                className="message-component fade-enter message-loading"
                style={{width: width}}
            >
                <FaCircleNotch size={20} className="spinner message-icon"/>
                <p className="message-text">{message}</p>
            </div>
        );
    } else if (type === "error") {
        messageComponent = (
            <div
                className="message-component fade-enter message-error"
                style={{width: width}}
            >
                <FaTimes color="red" size={20} className="message-icon"/>
                <p className="message-text">{message}</p>
            </div>
        );
    } else if (type === "success") {
        messageComponent = (
            <div
                className="message-component fade-enter message-success"
                style={{width: width}}
            >
                <FaCheck size={20} className="message-icon"/>
                <p className="message-text">{message}</p>
            </div>
        );
    }
    return <>{messageComponent}</>;
};

export const DisplayErrorMessage = ({t, errorTemplate, values}) => {
    if (typeof errorTemplate === "undefined") {
        errorTemplate = "The <0>{{field}}</0> field must be filled in";
    }

    return (
        <Trans
            t={t}
            i18nKey={errorTemplate}
            values={values}
            components={[<span></span>]}
        />
    );
};
import React from "react";
import {Box, Tooltip} from "@mui/material";
import {Display} from "../Inputs/Display";

export const CustomTooltipCell = ({value}) => {
    return <Tooltip
        classes={{popper: "CustomTooltipCell MuiTooltip-light"}}
        title={value}
        placement="bottom"
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -64],
                        },
                    },
                ],
            },
        }}
        disableInteractive
    >
        <Box>
            <Display type="textTruncate" value={value}/>
        </Box>
    </Tooltip>
};
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../FieldHolder";

import {verifyUser} from "../../../../services/userService";
import {MessageComponent} from "../../../UI/MessageComponent";
import Functions from "../../../../utils/Functions";
import Utils from "../../../../utils/Utils";
import {Button} from "@mui/material";
import {Grid} from "@mui/material";

export const FreelanceSearchStep = (
    {
        formData,
        setFormData,
        setFreelanceStep,
        message,
        setMessage,
        canModify
    }
) => {
    const token = useSelector((state) => state.auth.token);
    const {t} = useTranslation();

    useEffect(() => {
        setMessage({});
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        setMessage({});
    };

    const handleNextStep = () => {
        setMessage({});
        setFreelanceStep(Functions.TEXT_PERSONAL_INFO_STEP);
    };

    const handleCheckEmailAddress = async () => {
        try {
            const verifyData = Utils.verifyDataFreelance(t, formData, Functions.TEXT_SEARCH);
            if (verifyData.type === "error") {
                setMessage(verifyData);
            } else {
                const verify = await verifyUser({
                    email: formData.email,
                    token: token,
                });
                if (verify) {
                    if (verify.main_type === Functions.SESSION_TYPE_FREELANCE) {
                        if (!verify?.FreelanceProfile?.CompanyToFreelanceRelations?.length) {
                            setFormData({
                                ...formData,
                                existingUser: true,
                                firstName: verify.firstName,
                                lastName: verify.lastName
                            });
                            handleNextStep();
                        } else {
                            setMessage({
                                type: "error",
                                message: t("This freelancer is already part of your pool")
                            });
                        }
                    } else {
                        setMessage({
                            type: "error",
                            message: t("This email address is associated with a non freelance user, please use another address")
                        });
                    }
                } else if (!verify) {
                    setFormData({
                        ...formData,
                        existingUser: false,
                        firstName: "",
                        lastName: ""
                    });
                    handleNextStep();
                }
            }
        } catch (error) {
            setMessage({type: "error", message: "An error occurred checking the email"});
        }
    };

    return (
        <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="100%"
                />
            </Grid>
            <Grid item xs={12}>
                <FieldHolder
                    fielddesc={t("Email")}
                    type="text"
                    name="email"
                    id="email"
                    placeholder={t("Email")}
                    value={formData?.email}
                    onChange={handleChange}
                    canModify={canModify}
                    className={"w-form"}
                />
            </Grid>
            <Grid item xs={12} className="text-center" sx={{mt: 2}}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleCheckEmailAddress}
                >
                    {t("Check the freelancer's e-mail address")}
                </Button>
            </Grid>
        </Grid>
    );
};

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../FieldHolder";
import {useSelector} from "react-redux";
import {CompanyFreelanceNote} from "../CompanyFreelance/CompanyFreelanceNote";
import Functions from "../../../../utils/Functions";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../store/freelanceProfileStore";
import {CustomCard} from "../../../UI/CustomCard";
import PermissionService from "../../../../services/permissionService";
import AvailabilityCalendar from "./AvailabilitySection/AvailabilityCalendar";

export const AvailabilitySection = ({formData, editPermissions, ...props}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const connectedUser = useSelector((state) => state.auth);

    const {
        freelanceData,
        setFreelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const [editMode, setEditMode] = useState(false);

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    return (
        <>
            <CustomCard
                editMode={editMode}
                setEditMode={setEditMode}
                canModify={
                    Boolean(editPermissions?.length) &&
                    PermissionService.hasPermission(connectedUser, "updateAvailability")
                }
                onSave={() =>
                    handleFreelanceProfileSave({
                        token,
                        freelanceProfileData,
                        callback: () => setEditMode(false)
                    })
                }
            >
                <FieldHolder
                    fielddesc={t("Date of last update")}
                    type="date"
                    id="availabilityUpdatedAt"
                    value={freelanceProfileData.availabilityUpdatedAt}
                    canModify={false}
                />
                {editPermissions?.length > 0 && (
                    <FieldHolder
                        fielddesc={t("Updater")}
                        type="text"
                        id="availabilityUpdatedUserId"
                        value={freelanceProfileData?.AvailabilityUpdatedUser?.fullName}
                        canModify={false}
                    />
                )}
                <AvailabilityCalendar
                    freelanceProfile={freelanceProfileData}
                    setFreelanceProfile={setFreelanceData}
                    editMode={editMode}
                    {...props}
                />
                <FieldHolder
                    fielddesc={t("Comment")}
                    type="multiline"
                    name="availabilityComment"
                    id="availabilityComment"
                    value={freelanceProfileData.availabilityComment}
                    onChange={handleFreelanceDataChange}
                    canModify={editMode}
                />
            </CustomCard>

            {sessionType === Functions.SESSION_TYPE_ENTERPRISE && Boolean(editPermissions?.length) && (
                <CompanyFreelanceNote
                    activeSection={Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY}
                    freelanceProfile={freelanceProfileData}
                />
            )}
        </>
    );
};

import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {FieldHolder} from "../../../../FieldHolder";
import {fetchCountries} from "../../../../../../services/countryService";

export const FreelanceInfo3Card = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData,
        handleFreelanceDataChange
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [countries, setCountries] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const requiredFields = ['legal_status', 'enterprise_name', 'siren_number', 'enterpriseAddress', 'headquarterCountry'];

    useEffect(() => {
        (async () => {
            const fetchedCountries = await fetchCountries(token);
            setCountries(fetchedCountries.data);
        })();
    }, [token]);

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    const handleSave = async () => {
        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields
        });
        if (result?.error) {
            setFormErrors(result.data);
        }
    };

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            <FieldHolder
                fielddesc={t("Legal status")}
                type="dropdown"
                set="FreelanceLegalStatus"
                name="legal_status"
                id="legal_status"
                placeholder={t("EI / SAS / Portage")}
                value={freelanceProfileData.legal_status}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.legal_status}
            />
            <FieldHolder
                fielddesc={t("Corporate name")}
                type="text"
                name="enterprise_name"
                id="enterprise_name"
                value={freelanceProfileData.enterprise_name}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.enterprise_name}
            />
            <FieldHolder
                fielddesc={t("SIREN number")}
                type="text"
                name="siren_number"
                id="numeroSiren"
                placeholder={t("SIREN number")}
                value={freelanceProfileData.siren_number}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.siren_number}
            />
            <FieldHolder
                fielddesc={t("VAT Number")}
                type="text"
                name="vatNumber"
                id="vatNumber"
                placeholder={t("VAT Number")}
                value={freelanceProfileData.vatNumber}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
            />
            <FieldHolder
                fielddesc={t("Company address")}
                type="text"
                name="enterpriseAddress"
                id="enterpriseAddress"
                placeholder={t("1 rue du colisée 75008 Paris")}
                value={freelanceProfileData.enterpriseAddress}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.enterpriseAddress}
            />
            <FieldHolder
                fielddesc={t('Headquarter country 2')}
                type="dropdown"
                name="headquarterCountry"
                id="headquarterCountry"
                value={formData.headquarterCountry}
                datas={countries}
                onChange={handleFreelanceDataChange}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.headquarterCountry}
            />
            <FieldHolder
                fielddesc={t("Activity Declaration Number (training)")}
                type="text"
                name="activityDeclarationNumber"
                id="activityDeclarationNumber"
                value={freelanceProfileData.activityDeclarationNumber}
                onChange={handleFreelanceDataChange}
                canModify={editMode}
            />
        </CustomCard>
    </>
};
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {addUserToCompany} from "../../services/userService";
import {FieldHolder} from "../Forms/FieldHolder";
import {MessageComponent} from "../UI/MessageComponent";
import Utils from "../../utils/Utils";
import Popup from "./Popup";
import {Box, Button, Grid, Stack} from "@mui/material";

export const EnterprisePopup = ({open, onClose}) => {
    const token = useSelector((state) => state.auth.token);
    const {t} = useTranslation();

    const initialValueFormData = {
        companyRole: "",
        firstName: "",
        lastName: "",
        email: "",
        main_type: "Enterprise",
        password: Utils.generateRandomPassword(15),
    };
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialValueFormData);
    const [message, setMessage] = useState({});

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = () => {
        // Effectue la requête API POST avec les données du formulaire
        (async () => {
            try {
                const verifyDataUser = Utils.verifyDataOrganisationUser(formData);
                if (verifyDataUser.type === "error") {
                    setMessage(verifyDataUser);
                } else {
                    setLoading(true);
                    const responseData = await addUserToCompany({
                        userData: {userData: formData},
                        token: token,
                    });
                    setLoading(false);
                    if (responseData.success) {
                        setMessage({});
                        setFormData(initialValueFormData);
                        onClose();
                    } else {
                        setMessage({type: "error", message: responseData.error});
                    }
                }
            } catch (error) {
                setMessage({});
                console.error(error);
            }
        })();
    };


    return (
        <Popup open={open}
               title={t("Add a user")}
               onDialogClose={onClose}
        >
            <Box sx={{width: {md: "30vw"}}}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={12}>
                        <MessageComponent
                            type={message?.type}
                            message={message?.message}
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("First name")}
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder={t("First name")}
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Last name")}
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder={t("Last name")}
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Email")}
                            type="email"
                            name="email"
                            id="lastName"
                            placeholder={t("Last name")}
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Role")}
                            set="UserRole"
                            type="dropdown"
                            name="companyRole"
                            id="company_role"
                            placeholder={"Role"}
                            value={formData?.companyRole}
                            formData={formData}
                            setFormData={setFormData}
                            onChange={(e) => handleChange(e)}
                            canModify={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="center" spacing={2}>
                            <Button
                                disabled={loading}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                {t("Confirm")}
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Popup>
    );
};

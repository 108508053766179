import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import "./logoutcomponent.css";
import {logout} from "../../utils/actions/authActions";
import {Button} from "@mui/material";
import {FaSignOutAlt} from "react-icons/fa";

export const LogoutComponent = ({display = "button"}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleLogout = () => {
        // Déclencher l'action de déconnexion
        dispatch(logout());
        navigate("/");
    };

    return (
        <>
            {display === "button" &&
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleLogout}
                >
                    {t("Logout")}
                </Button>
            }
            {display === "link" &&
                <div
                    style={{display: "flex", alignItems: "center"}}
                    onClick={handleLogout}
                    className="navigation-link"
                >
                    <FaSignOutAlt className="app-bar-menu-logo-small"/>
                    <span className="">{t("Logout")}</span>
                </div>
            }
        </>
    );
};

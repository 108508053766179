import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/availability/";

export const fetchAvailabilities = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching availability :", error);
        throw error;
    }
};

export const fetchFreelanceAvailabilities = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching availability :", error);
        throw error;
    }
};

export const saveAvailability = async (availabilityData, token) => {
    try {
        return requestApi(`${BASE_URL}save`, 'POST', availabilityData, token);
    } catch (error) {
        console.error("Error saving availability :", error);
        throw error;
    }
};

export const deleteAvailability = async (availabilityData, token) => {
    try {
        return requestApi(`${BASE_URL}delete`, 'DELETE', availabilityData, token);
    } catch (error) {
        console.error("Error deleting availability :", error);
        throw error;
    }
};
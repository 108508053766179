import {LanguageSelector} from "../../UI/LanguageSelector";
import React from "react";
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";

export const Language = () => {
    const {t} = useTranslation();

    return <>
        <div className="standardform standardform-w55 card transparent">
            <div className="w-100">
                <h3 className="text-title text-theme">
                    {t("Language")}
                </h3>
            </div>
            <Stack direction="column" justifyContent="start" sx={{width: "100%"}}>
                <LanguageSelector/>
            </Stack>
        </div>
    </>
}
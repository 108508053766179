import React from "react";
import {Grid} from "@mui/material";
import {OnboardingResource} from "./OnboardingResource/OnboardingResource";
import {OnboardingStorageLink} from "./OnboardingStorageLink/OnboardingStorageLink";

export const OnboardingSection = ({formData, setFormData, canModify}) => {
    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md>
                <div className="card standardform-card">
                    <OnboardingResource
                        formData={formData}
                        setFormData={setFormData}
                        canModify={canModify}
                    />
                </div>
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} md>
                <div className="card standardform-card">
                    <OnboardingStorageLink
                        formData={formData}
                        setFormData={setFormData}
                        canModify={canModify}
                    />
                </div>
            </Grid>
        </Grid>
    </>
};
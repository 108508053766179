import {Grid} from "@mui/material";
import {FieldHolder} from "../../FieldHolder";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Functions from "../../../../utils/Functions";
import {CustomCard} from "../../../UI/CustomCard";

export const PaymentModeSection = (
    {
        formData,
        setFormData,
        handleChange,
        onSave,
        canModify
    }
) => {
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
        }
    }, [editMode]);

    const handleSave = async () => {
        const result = onSave && await onSave();
        setEditMode(false);
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            setEditMode(false);
        }
    };

    return <>
        <CustomCard
            className="justify-content-center"
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={canModify}
            onSave={handleSave}
        >
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Payment terms")}
                        type="dropdown"
                        name="paymentMethod"
                        id="paymentMethod"
                        set="PaymentMethod"
                        value={formData?.paymentMethod}
                        formData={formData}
                        setFormData={setFormData}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.paymentMethod}
                    />
                </Grid>
            </Grid>

            {formData.paymentMethod === Functions.TEXT_STANDARD_PAYMENT_METHOD && (
                <>
                    <Grid container spacing={{xs: 2, md: 3}}>
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("Amount")}
                                type="number"
                                name="payment_amount"
                                id="payment_amount"
                                placeholder={t("Amount")}
                                formData={formData}
                                setFormData={setFormData}
                                value={formData?.payment_amount}
                                onChange={handleChange}
                                canModify={editMode}
                                mandatoryFlag={true}
                                error={formErrors.payment_amount}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("Currency")}
                                type="dropdown"
                                name="currency"
                                id="currency"
                                set="Exchange"
                                placeholder={t("Currency")}
                                formData={formData}
                                value={formData?.currency}
                                setFormData={setFormData}
                                canModify={editMode}
                                mandatoryFlag={true}
                                error={formErrors.currency}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={{xs: 2, md: 3}}>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Payment frequency")}
                                type="dropdown"
                                name="payment_frequency"
                                id="payment_frequency"
                                set="paymentFrequency"
                                placeholder={t("Payment frequency")}
                                formData={formData}
                                value={formData?.payment_frequency}
                                setFormData={setFormData}
                                canModify={editMode}
                                mandatoryFlag={true}
                                error={formErrors.payment_frequency}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {formData.paymentMethod === Functions.TEXT_CUSTOM_PAYMENT_METHOD && (
                <Grid container spacing={{xs: 2, md: 3}}>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Details")}
                            type="multiline"
                            name="customPaymentMethod"
                            id="customPaymentMethod"
                            placeholder={t("Enter the full description of the payment terms as they will be written in the contract")}
                            formData={formData}
                            value={formData.customPaymentMethod}
                            setFormData={setFormData}
                            canModify={editMode}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.customPaymentMethod}
                        />
                    </Grid>
                </Grid>
            )}
        </CustomCard>
    </>
};
import React from "react";
import {Worker, Viewer} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {FaCircleNotch} from "react-icons/fa";
import {useSelector} from "react-redux";
import Functions from "../../utils/Functions";
import {MessageComponent} from "../UI/MessageComponent";
import useFileOpener from "../../hooks/customHooks";

import {FaDownload} from "react-icons/fa6";
import "./pdfviewer2.css";

export const PDFViewer2 = (
    {
        activeFile = null,
        showDownloadBtn = false,
        fileName,
    }
) => {
    const token = useSelector((state) => state.auth.token);
    const {loading, message, openFileInNewTab} = useFileOpener();

    // Fonction pour ouvrir le fichier sauvegardé sous S3
    const handleFileOpen = async (e, value) => {
        e.preventDefault();
        if (!value) {
            return false;
        }
        try {
            await openFileInNewTab(fileName, Functions.TEXT_DOCUMENT, token);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="pdf-container-column">
            <MessageComponent
                type={message.type}
                message={message.message}
                width="100%"
            />
            <div className="pdf-container-row">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    {activeFile !== null && <Viewer fileUrl={activeFile}/>}
                </Worker>
                {showDownloadBtn && (
                    <div className="pdf-controls-container cursor-pointer">
                        {loading ?
                            <FaCircleNotch size={30} className="spinner"/> :
                            <FaDownload onClick={(e) => handleFileOpen(e, fileName)}
                                        size={30}>
                            </FaDownload>
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

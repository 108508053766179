import Functions from "../utils/Functions";

const PermissionService = {
    hasPermission: (user, action) => {
        const authorizedRoles = [
            'Super administrator', 'Administrator', 'Payer', 'Standard'
        ];

        if (!user) {
            return false;
        }

        if (user.sessionType === Functions.SESSION_TYPE_FREELANCE) {
            return true;
        }

        if (action === 'sourcingBoard') {
            return user.fullAccess || user.userId === "d981284c-900e-4223-b143-cd04570a56b3";
        }

        return authorizedRoles.includes(user.companyRole);
    }
};

export default PermissionService;
import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import Utils from "../../../utils/Utils";

import {FieldHolder} from '../FieldHolder';

import {fetchCompany, updateCompany} from "../../../services/companyService";

import '../form.css';

import {MessageComponent} from '../../UI/MessageComponent';
import {Button} from "@mui/material";

export const CompanyForm = forwardRef(({setLoading, onSubmit, ...props}, ref) => {
    const token = useSelector((state) => state.auth.token);
    const companyId = useSelector((state) => state.auth.companyId);
    const {t} = useTranslation();
    const [formErrors, setFormErrors] = useState({});
    const [message, setMessage] = useState({});
    const [formData, setFormData] = useState({});
    const formRef = useRef(null);

    const requiredFields = ['name'];

    useEffect(() => {
        (async () => {
            try {
                // Fetch the company
                const fetchedCompany = await fetchCompany(companyId, token);
                setFormData(fetchedCompany);
            } catch (error) {
                setMessage({type: "error", message: error.message});
            }
        })();
    }, [token]);

    useEffect(() => {
        if (Object.keys(formErrors).length > 0) {
            const {errors} = Utils.validateRequiredFields(formData, requiredFields);
            setFormErrors(errors);
        }
    }, [formData]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(formData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return
        }

        try {
            setMessage({type: "loading", message: "Submitting form"});

            // Effectue la requête API POST avec les données du formulaire

            setLoading && setLoading(true);
            const company = updateCompany({
                companyId: formData.id,
                companyData: formData,
                token: token
            });
            setLoading && setLoading(false);

            if (!company.error) {
                await new Promise((resolve) => setTimeout(resolve, 400));
                setMessage({type: "success", message: "Company updated"});

                onSubmit && onSubmit({success: true});
            } else {
                setMessage({type: "error", message: "Error saving data"});
            }
        } catch (error) {
            setMessage({type: "error", message: "Error saving data"});
        }
    };

    if (ref) {
        ref.current = handleSubmit;
    }

    const standardFormClass = props.className || "standardform-w55";

    return (
        <>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                method="post"
                className={`standardform ${standardFormClass}`}
                noValidate
            >
                <div className='card standardform-card card-padding-wide'>
                    <FieldHolder
                        fielddesc={t('Name')}
                        type="text"
                        name="name"
                        id="name"
                        placeholder={t('Company')}
                        value={formData.name}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.name}
                    />
                    <FieldHolder
                        fielddesc={t('Headquarter country')}
                        type="dropdown"
                        set="country"
                        name="headquartersCountry"
                        id="headquartersCountry"
                        placeholder={t('United States of America')}
                        value={formData.headquartersCountry}
                        onChange={handleChange}
                        formData={formData}
                        setFormData={setFormData}
                        error={formErrors.headquartersCountry}
                    />
                    <FieldHolder
                        fielddesc={t('Address of the headquarters')}
                        type="text"
                        name="legalAddress"
                        id="legalAddress"
                        placeholder={t('1, 5th Ave, New York, NY 10003')}
                        value={formData.legalAddress}
                        onChange={handleChange}
                        error={formErrors.legalAddress}
                    />
                    <FieldHolder
                        fielddesc={t('Siret')}
                        type="text"
                        name="siret"
                        id="siret"
                        placeholder={t('XXXXXXXXX')}
                        value={formData.siret}
                        onChange={handleChange}
                        error={formErrors.siret}
                    />
                    <FieldHolder
                        fielddesc={t("VAT Number")}
                        type="text"
                        name="vatNumber"
                        id="vatNumber"
                        placeholder={t("VAT Number")}
                        value={formData.vatNumber}
                        onChange={handleChange}
                        error={formErrors.vatNumber}
                    />
                    <FieldHolder
                        fielddesc={t('Organization size')}
                        type="dropdown"
                        set="Size"
                        name="size"
                        id="size"
                        value={formData.size}
                        formData={formData}
                        setFormData={setFormData}
                        error={formErrors.size}
                    />
                    <p className="mandatory-legend">{t("* champs obligatoires")}</p>
                </div>
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />

                {!ref &&
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                    >
                        {t("Save")}
                    </Button>
                }
            </form>
            {/* <div className="helpercard-container">
          <HelperCard 
            icon = {<TbWorldSearch />}
            headerText = {"Pourquoi renseigner vos informations entreprise ?"}
            descriptionText = {"Renseigner vos informations entreprise permet à Smarteem de vérifier l'identité"}
            />
          <HelperCard 
            icon = {<LuShieldCheck />}
            headerText = {"Comment sont sécurisées vos informations personnelles ?"}
            descriptionText = {"Smarteem applique les meilleures pratiques RGPD. Seules les personnes ayant besoin de vous contacter peuvent accéder à vos informations."}
            />
        </div> */}
        </>
    );
});
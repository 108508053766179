import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {FaRegAddressCard} from "react-icons/fa";
import {LuShieldCheck} from "react-icons/lu";

import {
    fetchFreelanceProfile,
} from "../../../services/freelanceProfileService";
import filesFreelanceProfile from "../../../services/filesFreelanceProfile";

import {ProfileSection} from "./Sections/ProfileSection/ProfileSection";
import {InformationAndDocumentSection} from "./Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import {MissionsSection} from "./Sections/MissionsSection";
import {CooptationSection} from "./Sections/CooptationSection";

import "../form.css";
import "./freelanceprofileform.css";
import {MessageComponent} from "../../UI/MessageComponent";
import {HelperCard} from "../../UI/HelperCard";
import Functions from "../../../utils/Functions";
import {AvailabilitySection} from "./Sections/AvailabilitySection";
import {ReferralSection} from "./Sections/ReferralSection";
import SectionMenu from "../../UI/SectionMenu";
import {CompanyFreelanceNote} from "./CompanyFreelance/CompanyFreelanceNote";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";
import {Trans, useTranslation} from "react-i18next";
import {fetchCompany} from "../../../services/companyService";

export const FreelanceProfileForm = (
    {
        activeSection,
        setActiveSection,
        editPermissions
    }
) => {
    const objectAttachedType = "freelanceProfile";
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {id} = useParams();
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const sessionType = useSelector((state) => state.auth.sessionType);
    const freelanceId = useSelector((state) => state.auth.freelanceId);
    const companyId = useSelector((state) => state.auth.companyId);

    const [message, setMessage] = useState({});
    const [company, setCompany] = useState({});

    const [triggerApi, setTriggerApi] = useState(true);
    const formRef = useRef(null);

    const canModify = editPermissions.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL);

    useEffect(() => {
        filesFreelanceProfile.setFiles([]);
        filesFreelanceProfile.setFilesDeleted([]);

        // Get the freelance profile to display
        if (triggerApi) {
            (async () => {
                try {
                    const fetchedFreelanceId = typeof id !== "undefined" ? id : freelanceId;
                    const fetchedFreelanceProfile = await fetchFreelanceProfile(
                        fetchedFreelanceId,
                        token,
                        true
                    );
                    setFreelanceData(fetchedFreelanceProfile);
                    setTriggerApi(false);
                } catch (error) {
                }
            })();
        }
    }, [id, token, triggerApi]);

    useEffect(() => {
        (async () => {
            if (companyId) {
                const fetchedCompany = await fetchCompany(companyId, token);
                setCompany(fetchedCompany);
            }
        })();
    }, [token]);

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    let sectionMenuItems = [];
    let helperContent;

    if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
        sectionMenuItems = [
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
        ];

        if (editPermissions.length) {
            sectionMenuItems.splice(2, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.MISSION,
                name: t("Assignments") + (parseInt(freelanceData?.missionCount) ? ` (${freelanceData?.missionCount})` : ""),
                tooltip: t("Assignments for {{company}}", {company: company?.name})
            });
            sectionMenuItems.splice(4, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
                name: 'Informations and documents'
            });
        }
    } else {
        sectionMenuItems = [
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
            {key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT, name: 'Informations and documents'},
            // {key: Functions.FREELANCE_PROFILE_SECTION.PREFERENCE, name: 'Assignment preferences'},
            {key: Functions.FREELANCE_PROFILE_SECTION.COOPTATION, name: 'Cooptation'},
        ];

        if (activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE) {
            helperContent = (
                <>
                    <HelperCard
                        icon={<FaRegAddressCard/>}
                        headerText={"Why complete your profile?"}
                        descriptionText={
                            "Your Smarteem profile can be shared with multiple companies. Once completed, you can transmit all your personal information, skills, and documents to a new company with a single click."
                        }
                    />
                </>
            );
        } else if (activeSection === Functions.FREELANCE_PROFILE_SECTION.COOPTATION) {
            const descriptionText = <Trans
                t={t}
                i18nKey={
                    "Grow the Smarteem community and introduce us to the freelancers you personally recommend. \n" +
                    "\n" +
                    "If you recommend 5 freelancers, you will receive a <0>{{link}}</0> gift card worth 20 euros. \n" +
                    "\n" +
                    "There's no limit: for every 5 freelancers you recommend, you'll receive a new gift card!"
                }
                values={{link: "Ethi'Kdo"}}
                components={[<a
                    className=""
                    href="https://www.ethikdo.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                ></a>]}
            />

            helperContent = (
                <>
                    <HelperCard
                        headerText={"Recommend 5 freelancers from your community and receive a gift card!"}
                        descriptionComponent={descriptionText}
                    />
                </>
            );
        }
    }

    const standardFormClass = activeSection === Functions.FREELANCE_PROFILE_SECTION.MISSION
        ? "standardform-w70"
        : "standardform-w55";

    return (
        <div className="form-layout">
            <div className="workflowmenu">
                <SectionMenu
                    color="info"
                    sectionMenuItems={sectionMenuItems}
                    activeSection={activeSection}
                    onSectionMenuClick={handleSectionClick}
                />
            </div>
            <form
                ref={formRef}
                className={`standardform ${standardFormClass}`}
                id="freelanceProfileForm"
                noValidate
            >
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <ProfileSection
                        objectAttachedType={objectAttachedType}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                    <AvailabilitySection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                    <ReferralSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        canModify={
                            editPermissions?.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL)
                        }
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.MISSION && (
                    <MissionsSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                    <InformationAndDocumentSection
                        objectAttachedType={objectAttachedType}
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.COOPTATION && (
                    <CooptationSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        canModify={canModify}
                    />
                )}

                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />
            </form>

            {
                sessionType === Functions.SESSION_TYPE_ENTERPRISE && Boolean(editPermissions.length) &&
                activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <CompanyFreelanceNote
                        activeSection={Functions.FREELANCE_PROFILE_SECTION.PROFILE}
                        freelanceProfile={freelanceData}
                    />
                )
            }
            {helperContent && (<div className="helpercard-container">{helperContent}</div>)}
        </div>
    );
};

import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {fetchDefaultJobKeywords, fetchFreelanceProfiles} from "../services/freelanceProfileService";

import {AppLayout} from "../components/UI/AppLayout";

import {DisplayBar} from "../components/UI/DisplayBar";
import {UserCard} from "../components/UI/UserCard";

import "./page.css";
import {FreelancePopup} from "../components/Popup/FreelancePopup";
import {Table} from "../components/Tables/Table";

import skillService from "../services/skillService";
import {Box, Button, Chip, IconButton, Link, Popover} from "@mui/material";
import {MdOutlineFavorite, MdOutlineFavoriteBorder, MdQuestionMark} from "react-icons/md";
import Utils from "../utils/Utils";
import Functions from "../utils/Functions";
import {FreelanceAvailabilityDisplay} from "../components/Forms/FreelanceProfile/FreelanceAvailabilityDisplay";
import {CustomTagCell} from "../components/Tables/CustomTagCell";
import _ from "lodash";
import {addFreelanceToFavorite, removeFreelanceToFavorite} from "../services/enterpriseProfileService";
import PermissionService from "../services/permissionService";
import {useFreelanceProfileStore} from "../store/freelanceProfileStore";
import {saveDisplayConfig} from "../services/userService";
import {updateDisplayConfig} from "../utils/actions/authActions";
import TextTruncate from "react-text-truncate";
import TagService from "../services/tagService";
import HoverCopyButton from "../components/UI/HoverCopyButton";
import {EditableTag} from "../components/Tables/EditableTag";

let pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const TalentBoard = ({sourcing = false}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const connectedUser = useSelector((state) => state.auth);
    let pageKey = 'freelance';
    const displayConfig = Object.keys(connectedUser.displayConfig).length
        ? connectedUser.displayConfig : {freelance: {}};
    if (!displayConfig.freelance?.pinnedColumns) {
        displayConfig.freelance.pinnedColumns = {left: ['action', 'User.fullName']}
    }

    const freelanceDisplayConfig = {...displayConfig.freelance};
    if (sourcing) {
        freelanceDisplayConfig.advancedFilter = displayConfig?.sourcing?.advancedFilter ?? {};
        freelanceDisplayConfig.filterModel = displayConfig?.sourcing?.filterModel ?? {};
    }
    pageSize = freelanceDisplayConfig.pageSize ?? pageSize;

    const {setFreelanceData} = useFreelanceProfileStore();

    const [data, setData] = useState([]);
    const [displayType, setDisplayType] = useState("List");
    const [triggerApi, setTriggerApi] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    const tableRef = useRef();
    const fetchDataRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: '',
        activatedFilter: false
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
    const [skillsData, setSkillsData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [jobKeywords, setJobKeywords] = useState([]);
    const [senioritiesData, setSenioritiesData] = useState([]);
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isAdminUser = connectedUser.fullAccess;
    let displayItems = [];
    let activeDisplay = {};
    let pageTitle;

    if (sourcing) {
        pageTitle = "Finding new freelancers";
    } else {
        displayItems = [
            {
                key: 'List',
                name: isEnterpriseSession ? 'All freelancers' : 'List',
                pageTitle: 'Freelancers'
            }
        ];
        if (isEnterpriseSession) {
            displayItems = [
                ...displayItems,
                {
                    key: 'MyFavoriteFreelancers',
                    name: 'My favorite freelancers',
                    pageTitle: 'My favorite freelancers',
                    favorite: 'me'
                }
            ];
        }

        activeDisplay = displayItems[Utils.findIndexByKey(displayItems, displayType)];
        pageTitle = activeDisplay?.pageTitle;
    }

    useEffect(() => {
        (async () => {
            skillService.getAllDefaultSkill(token).then((defaultSkills) => {
                defaultSkills = defaultSkills.map((skill) => {
                    return {...skill, group: t(skillService.getSkillTypeLabel(skill.type))};
                });
                setSkillsData(defaultSkills);
            });

            TagService.getFreelanceProfileTags({type: Functions.TAGS_TYPE.FREELANCE_TAG}, token).then(fetchedTags => {
                if (!fetchedTags?.error) {
                    setTagsData(fetchedTags.data);
                }
            });

            fetchDefaultJobKeywords(token).then((defaultJobKeywords) => {
                setJobKeywords(defaultJobKeywords);
            });

            const jsonOptions = Utils.getParameterValues("professionalExperienceYears");
            setSenioritiesData(
                Object.keys(jsonOptions).map((key) => ({
                    id: key,
                    name: t(jsonOptions[key].nativetext)
                }))
            );
        })();
    }, [token, t]);

    useEffect(() => {
        fetchDataRef.current(filterConfigs);
    }, [filterConfigs, triggerApi]);

    const tagMap = useMemo(() => {
        return tagsData.reduce((acc, tag) => {
            acc[tag.id] = tag.name;
            return acc;
        }, {});
    }, [tagsData]);

    const columns = useMemo(() => {
        const _columns = [
            {
                field: 'User.fullName',
                type: 'string',
                hideable: false,
                headerName: t("Name"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                cellClassName: isEnterpriseSession ? 'pl-20' : '',
                renderCell: ({row}) => {
                    const user = row?.User;
                    return <UserCard user={user} displaySkeleton={true}/>;
                }
            },
            {
                field: 'jobTitle',
                type: 'string',
                headerName: t("Job title"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return <Box sx={{textWrap: "wrap"}}>{row.jobTitle}</Box>
                }
            },
            {
                field: 'averageDailyRate',
                type: 'number',
                headerName: t("ADR"),
                minWidth: 180,
                flex: 1.8,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return `${row.averageDailyRate ?? ''}`
                        + ` ${t(Utils.getParameterValue(row?.averageDailyRateCurrency, "Currency"))}`
                }
            },
            {
                field: 'CompanyFreelanceNotes.negotiatedRate',
                type: 'string',
                headerName: t("Negotiated rate"),
                minWidth: 180,
                flex: 1.8,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return row?.CompanyFreelanceNotes.length
                        ? row.CompanyFreelanceNotes[0].negotiatedRate : "";
                }
            },
            {
                field: 'User.phoneNumber',
                type: 'string',
                headerName: t("Phone number"),
                minWidth: 180,
                flex: 1.8,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const phoneNumber = row?.User.phoneNumber
                    return phoneNumber ? (
                        <HoverCopyButton textToCopy={phoneNumber}>
                            <Box sx={{textWrap: "wrap"}}>{phoneNumber}</Box>
                        </HoverCopyButton>
                    ) : '';
                }
            },
            {
                field: 'User.email',
                type: 'string',
                headerName: t("Email"),
                minWidth: 300,
                flex: 3.0,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const email = row?.User.email;
                    return email ? (
                        <HoverCopyButton textToCopy={email}>
                            <div className="MuiDataGrid-cellContent" title={email} role="presentation">{email}</div>
                        </HoverCopyButton>
                    ) : '';
                }
            },
            {
                field: 'website',
                type: 'string',
                headerName: t("Website"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const formattedUrl = Utils.formatUrl(row.website);
                    return <Link
                        className="MuiDataGrid-cellContent"
                        href={formattedUrl}
                        underline="none"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(formattedUrl, '_blank', 'noopener,noreferrer');
                        }}
                    >
                        <TextTruncate
                            line={2}
                            truncateText="..."
                            text={row.website}
                        />
                    </Link>
                }
            },
            {
                field: 'linkedinProfile',
                type: 'string',
                headerName: t("LinkedIn profile"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const formattedUrl = Utils.formatUrl(row.linkedinProfile);
                    return <Link
                        className="MuiDataGrid-cellContent"
                        href={formattedUrl}
                        underline="none"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(formattedUrl, '_blank', 'noopener,noreferrer');
                        }}
                    >
                        <TextTruncate
                            line={2}
                            truncateText="..."
                            text={row.linkedinProfile}
                        />
                    </Link>
                }
            },
            {
                field: 'location',
                headerName: t("Localization"),
                minWidth: 250,
                flex: 2.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return (
                        <Box sx={{textWrap: "wrap"}}>
                            {row?.Location ? `${row?.Location.name}, ${row?.Location?.Country.name}` : ""}
                        </Box>
                    );
                }
            },
            {
                field: 'professionalExperienceYears',
                type: 'string',
                headerName: t("Experience"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return (
                        <Box sx={{textWrap: "wrap"}}>
                            {t(row.professionalExperienceYears)}
                        </Box>
                    );
                }
            },
            {
                field: 'working_terms_preference',
                type: 'string',
                headerName: t("Preferences"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return (
                        <Box sx={{textWrap: "wrap"}}>
                            {t(row.working_terms_preference)}
                        </Box>
                    );
                }
            },
            {
                field: 'JobKeywords.id',
                headerName: t("Job keywords"),
                minWidth: 300,
                flex: 3.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const tags = row?.FreelanceProfileJobKeywords.map(jk => jk.JobKeyword.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileSkills.Skill.technical',
                headerName: t("Skills"),
                minWidth: 300,
                flex: 3.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const customOrder = {technical: 1, transversal: 2};
                    let skills = row?.FreelanceProfileSkills.map((item) => item.Skill);
                    skills = skills.filter(skill => skill.type === 'technical' || skill.type === 'transversal');
                    skills = _.orderBy(skills, skill => customOrder[skill.type]);
                    const tags = skills.map(tag => tag.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileSkills.Skill.language',
                headerName: t("Languages"),
                minWidth: 300,
                flex: 3.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    let skills = row?.FreelanceProfileSkills
                        .filter(item => item.Skill.type === Functions.SKILL_TYPE.LANGUAGE)
                        .map(item => item.Skill);
                    const tags = skills.map(tag => tag.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileSkills.Skill.business_line',
                headerName: t("Business lines"),
                minWidth: 300,
                flex: 3.0,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    let skills = row?.FreelanceProfileSkills
                        .filter(item => item.Skill.type === Functions.SKILL_TYPE.BUSINESS)
                        .map(item => item.Skill);
                    const tags = skills.map(tag => tag.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileTags.freelance_tag',
                headerName: t("Tags"),
                minWidth: 300,
                flex: 3.0,
                headerAlign: 'center',
                align: 'center',
                editable: true,
                valueGetter: (value, row) => {
                    if (typeof value !== "undefined") return value;
                    if (!row.FreelanceProfileTags?.freelance_tag) return [];
                    const tags = row.FreelanceProfileTags.freelance_tag.map(item => ({
                        ...item,
                        name: tagMap[item.id] || ''
                    }));
                    return _.orderBy(tags, 'name');
                },
                renderCell: (params) => {
                    const tags = params?.value.map(item => item?.name);
                    return <CustomTagCell value={tags}/>;
                },
                renderEditCell: (params) => {
                    return <EditableTag data={tagsData} onEditOption={handleTagEditOption} {...params}/>;
                }
            },
            {
                field: 'FreelanceProfileAvailabilities.date',
                type: 'dateRange',
                headerName: t("Availability"),
                minWidth: 280,
                flex: 2.8,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return <FreelanceAvailabilityDisplay availabilityData={row}/>;
                }
            },
            {
                field: 'User.onboardingComplete',
                type: 'singleSelect',
                headerName: t("Account status"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                valueOptions: [
                    {value: true, label: t("Activated account")},
                    {value: false, label: t("Inactive account")},
                ],
                renderCell: ({row}) => {
                    const accountStatus = row?.User.onboardingComplete || false;
                    const accountStatusLabel = accountStatus ? t("Activated account") : t("Inactive account");
                    const accountStatusClass = accountStatus ? "account-status-active" : "account-status-inactive";
                    return <Chip label={accountStatusLabel} className={accountStatusClass} variant="outlined"/>
                }
            }
        ];
        if (!sourcing && isEnterpriseSession) {
            _columns.splice(0, 0,
                {
                    field: 'action',
                    headerName: '',
                    minWidth: 50,
                    flex: 0.5,
                    cellClassName: 'p-0',
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => (
                        <FavoriteButton
                            row={row}
                            isFavorite={row?.EnterpriseFreelanceFavorites?.length > 0}
                            onToggleFavorite={handleToggleFavorite}
                        />
                    )
                }
            );
            _columns.splice(18, 0,
                {
                    field: 'missionCount',
                    type: 'number',
                    noSearchFilter: true,
                    headerName: t("Number of assignments completed"),
                    minWidth: 220,
                    flex: 2.2,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        return parseInt(row.missionCount) > 0 ? row.missionCount : "-";
                    }
                },
                {
                    field: 'interactionCount',
                    type: 'number',
                    noSearchFilter: true,
                    headerName: t("Number of interactions"),
                    minWidth: 220,
                    flex: 2.2,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        return parseInt(row.interactionCount) > 0 ? row.interactionCount : "-";
                    }
                },
                {
                    field: 'noteCount',
                    type: 'number',
                    noSearchFilter: true,
                    headerName: t("Number of notes"),
                    minWidth: 220,
                    flex: 2.2,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        return parseInt(row.noteCount) > 0 ? row.noteCount : "-";
                    }
                },
                {
                    field: 'FreelancePerformances.nps',
                    type: 'number',
                    headerName: t("Recommendation"),
                    minWidth: 200,
                    flex: 2.0,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        const nps = row?.FreelancePerformances[0]?.nps ?? null;
                        return nps !== null && typeof nps !== "undefined"
                            ? <Chip
                                label={+parseFloat(nps).toFixed(1)}
                                className={Utils.getRecommendationStatus(parseFloat(nps))}
                            />
                            : "-";
                    }
                }
            );

            if (isAdminUser) {
                _columns.splice(22, 0,
                    {
                        field: 'Cooptation.User.fullName',
                        headerName: t("Referred"),
                        minWidth: 220,
                        flex: 2.2,
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: ({row}) => {
                            const cooperatorUser = row?.Cooptation?.User;
                            return cooperatorUser
                                ? <Box sx={{textWrap: "wrap"}}>{t("Yes") + " - " + cooperatorUser?.fullName}</Box>
                                : t("No");
                        }
                    },
                    {
                        field: 'referredFreelanceCount',
                        type: 'number',
                        noSearchFilter: true,
                        headerName: t("Referred freelancers"),
                        minWidth: 220,
                        flex: 2.2,
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: ({row}) => {
                            return parseInt(row.referredFreelanceCount) > 0 ? row.referredFreelanceCount : "-";
                        }
                    },
                );
            }
        }
        return _columns;
    }, [isEnterpriseSession, isAdminUser, tagsData]);

    const advancedSearchColumns = [
        {
            field: 'averageDailyRate',
            type: 'number',
            headerName: t("ADR")
        },
        {
            field: 'FreelanceProfileSkills.Skill.id',
            type: 'multipleSelect',
            headerName: t("Skills and business lines"),
            data: skillsData
        },
        {
            field: 'FreelanceProfileTags.Tag.id',
            type: 'multipleSelect',
            headerName: t("Tags"),
            data: tagsData
        },
        {
            field: 'FreelanceProfileAvailabilities.date',
            type: 'dateRange',
            headerName: t("Availability")
        },
        {
            field: 'FreelanceProfileJobKeywords.JobKeyword.id',
            type: 'multipleSelect',
            headerName: t("Job keywords"),
            data: jobKeywords
        },
        {
            field: 'professionalExperienceYears',
            type: 'singleSelect',
            headerName: t("Seniority"),
            data: senioritiesData
        },
        {
            field: 'Location',
            type: 'location',
            headerName: t("Localization")
        }
    ];

    fetchDataRef.current = async (query = {}) => {
        try {
            if (sourcing && !filterConfigs.activatedFilter) {
                return;
            }
            if (triggerApi) {
                query.sourcing = sourcing;
                if (activeDisplay?.favorite) {
                    query.favorite = "me";
                }

                fetchFreelanceProfiles(query, token).then(freelanceData => {
                    if (!freelanceData?.error) {
                        const _data = freelanceData.data.map(freelanceItem => {
                            if (freelanceItem?.FreelanceProfileTags) {
                                freelanceItem.FreelanceProfileTags = freelanceItem.FreelanceProfileTags.map(item => item.Tag);
                                freelanceItem.FreelanceProfileTags = TagService.groupTagsByType(freelanceItem.FreelanceProfileTags);
                            }
                            return freelanceItem;
                        });
                        setRowCount(freelanceData.count);
                        setData(_data);
                    }
                }).finally(() => setTriggerApi(false));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleHelpClick = (event) => {
        setHelpAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const handleToggleFavorite = async (row, isFavorite) => {
        const index = data.findIndex(freelance => freelance.id === row.id);
        if (index === -1) return;

        const updatedData = [...data];
        const action = isFavorite ? removeFreelanceToFavorite : addFreelanceToFavorite;
        const result = await action(row.id, token);

        if (!result) return;

        if (isFavorite) {
            if (displayType === 'MyFavoriteFreelancers') {
                updatedData.splice(index, 1);
            } else {
                updatedData[index].EnterpriseFreelanceFavorites = [];
            }
        } else {
            updatedData[index].EnterpriseFreelanceFavorites = [{FreelanceProfileId: row.id}];
        }

        setData(updatedData);
    };

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
        tableRef.current.initTable();
        setTriggerApi(true);
        setFilterConfigs(defaultFilterConfigs);
    };

    const handleDataGridChange = (filterConfigs) => {
        setTriggerApi(true);
        setFilterConfigs(filterConfigs);
    };

    const togglePopupVisibility = () => {
        if (PermissionService.hasPermission(connectedUser, "addFreelance")) {
            setShowPopup(true);
        }
    };

    const handleCellClick = ({id}) => {
        setFreelanceData({});
        if (sourcing) {
            navigate(`/sourcing/freelanceprofile/${id}`);
        } else {
            navigate(`/talents/freelanceprofile/${id}`);
        }
    };

    const handleTagEditOption = (option, newLabel) => {
        const newTagsData = tagsData.map(item => item.id === option.value ? {...item, name: newLabel} : item);
        setTagsData(newTagsData);
    };

    const handleDisplayConfigChange = (displayKey, displayValue) => {
        if (displayKey === 'columnVisibilityModel') {
            displayValue = _.pickBy(displayValue, value => value === false);
        } else if (sourcing && ['advancedFilter', 'filterModel'].includes(displayKey)) {
            pageKey = 'sourcing';
        }
        const displayConfigValue = {
            ...displayConfig,
            [pageKey]: {...displayConfig[pageKey], [displayKey]: displayValue}
        };

        saveDisplayConfig(displayConfigValue, token).then((result) => {
            if (!result?.error) {
                dispatch(updateDisplayConfig(displayConfigValue));
            }
        });
    };

    const handleFreelanceDialogClose = (reload = false) => {
        setShowPopup(false);
        if (reload) {
            setTriggerApi(true);
        }
    };

    const openHelpMenu = Boolean(helpAnchorEl);
    const helpPopoverId = openHelpMenu ? 'help-popover' : undefined;

    return (
        <AppLayout className="content-full-height">
            <div className="pageheader">
                <h1 className="pagetitle">
                    {t(pageTitle)}
                    <p className={`header-data-row-count ${sourcing && !filterConfigs.activatedFilter ? "d-none" : ""}`}>
                        {rowCount}
                    </p>
                    {sourcing && (
                        <>
                            <IconButton
                                aria-describedby={helpPopoverId}
                                onClick={handleHelpClick}
                                size="small"
                                sx={{border: '1px solid rgba(0, 0, 0, 0.23)'}}
                            ><MdQuestionMark/></IconButton>
                            <Popover
                                id={helpPopoverId}
                                open={openHelpMenu}
                                anchorEl={helpAnchorEl}
                                onClose={handleHelpClose}
                                anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                sx={{ml: 1}}
                            >
                                <Box sx={{p: 1.5, width: "300px"}}>
                                    {t("Explore the freelancers registered on Smarteem, consult their profiles and add them to your pool of freelancers.")}
                                </Box>
                            </Popover>
                        </>
                    )}
                </h1>
                <div className="pageaction">
                    {!sourcing && isEnterpriseSession && (
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={togglePopupVisibility}
                            >
                                {t("Add a freelancer")}
                            </Button>
                            <FreelancePopup
                                show={showPopup}
                                onClose={handleFreelanceDialogClose}
                                canModify={true}
                            />
                        </>
                    )}
                </div>
            </div>
            {!sourcing && (
                <DisplayBar
                    displayItems={displayItems}
                    displayType={displayType}
                    onDisplayMenuClick={handleDisplayMenuClick}
                />
            )}
            {sourcing && <Box sx={{mt: 1.5}}></Box>}
            <div className="table-layout">
                <Table
                    ref={tableRef}
                    height={sourcing ? "76vh" : "71vh"}
                    disableColumnSelector={sourcing}
                    disableColumnPinning={sourcing}
                    disableColumnReorder={sourcing}
                    displayConfig={freelanceDisplayConfig}
                    columns={columns}
                    advancedSearchColumns={advancedSearchColumns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                    onCellDoubleClick={handleCellClick}
                    onDisplayConfigChange={handleDisplayConfigChange}
                >
                    {!sourcing && (
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            onClick={() => navigate(`/talents/comparator`)}
                        >
                            {t("Freelancer/Assignment Matching")}
                        </Button>
                    )}
                </Table>
            </div>
        </AppLayout>
    );
};

const FavoriteButton = ({row, isFavorite, onToggleFavorite}) => {
    const handleButtonClick = (event) => {
        event.stopPropagation();
        onToggleFavorite(row, isFavorite);
    };

    return (
        <IconButton
            color={isFavorite ? "secondary" : "default"}
            onClick={handleButtonClick}
            aria-label="favorite"
            size="small"
        >
            {isFavorite ? <MdOutlineFavorite size={22}/> : <MdOutlineFavoriteBorder size={22}/>}
        </IconButton>
    );
};

import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + '/api/enterpriseprofile/';

export const fetchEnterpriseProfiles = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching entreprise profile :", error);
        throw error;
    }
};

export const fetchEnterpriseProfile = async (enterpriseId, token) => {
    try {
        return requestApi(`${BASE_URL}${enterpriseId}`, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching mission:', error);
        throw error;
    }
};

export const updateEnterpriseProfile = async (enterpriseId, enterpriseData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${enterpriseId}`, 'PUT', enterpriseData, token);
    } catch (error) {
        console.error('Error updating mission:', error);
        throw error;
    }
};

export const addFreelanceToFavorite = async (freelanceId, token) => {
    try {
        return requestApi(`${BASE_URL}freelance/${freelanceId}/addtofavorite`, 'POST', {}, token);
    } catch (error) {
        console.error('Error adding freelance to favorite:', error);
        throw error;
    }
};

export const removeFreelanceToFavorite = async (freelanceId, token) => {
    try {
        return requestApi(`${BASE_URL}freelance/${freelanceId}/removetofavorite`, 'DELETE', {}, token);
    } catch (error) {
        console.error('Error adding freelance to favorite:', error);
        throw error;
    }
};
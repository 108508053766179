import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/formations/";

export const fetchFormations = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching formations :", error);
        throw error;
    }
};

export const fetchFreelanceFormations = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching formations :", error);
        throw error;
    }
};

export const createFormation = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', data, token);
    } catch (error) {
        console.error("Error creating formation :", error);
        throw error;
    }
};

export const updateFormation = async (formationId, data, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${formationId}`, 'PUT', data, token);
    } catch (error) {
        console.error("Error updating formation :", error);
        throw error;
    }
};

export const deleteFormation = async (formationId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${formationId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting formation :", error);
        throw error;
    }
};
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/missionshortlistedfreelances/";

export const fetchShortlistedFreelance = async (id) => {
    try {
        return requestApi(`${BASE_URL}/${id}`, 'GET');
    } catch (error) {
        console.error("Error fetching shortlistedFreelances", error);
        throw error;
    }
};

export const fetchMissionShortlistedFreelances = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching shortlistedFreelances :", error);
        throw error;
    }
};

export const createShortlistedFreelance = async (shortlistedFreelanceData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', shortlistedFreelanceData, token);
    } catch (error) {
        console.error("Error creating shortlistedFreelances :", error);
        throw error;
    }
};

export const modifyShortlistedFreelance = async (shortlistedFreelanceId, shortlistedFreelanceData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${shortlistedFreelanceId}`, 'PUT', shortlistedFreelanceData, token);
    } catch (error) {
        console.error("Error updating shortlistedFreelances :", error);
        throw error;
    }
};

export const deleteShortlistedFreelance = async (shortlistedFreelanceId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${shortlistedFreelanceId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting shortlistedFreelances :", error);
        throw error;
    }
};

export const sendFreelanceFeedback = async (shortlistedFreelanceId, shortlistedFreelanceData) => {
    try {
        return requestApi(`${BASE_URL}freelance-feedback/${shortlistedFreelanceId}`, 'PUT', shortlistedFreelanceData);
    } catch (error) {
        console.error("Error updating shortlistedFreelances :", error);
        throw error;
    }
};
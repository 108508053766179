import {BehaviorSubject, Observable} from "rxjs";
import Functions from "../utils/Functions";

class Mission {
    constructor() {
        this.mission = new BehaviorSubject({
            quote: {},
            contrat: {},
            payment_method: {},
        });
        this.fileNameFile = new BehaviorSubject(null);
        this.missionStep = new BehaviorSubject(Functions.TEXT_DESCRIPTION);
        this.missionSection = new BehaviorSubject(Functions.SECTION_DETAIL_MISSION);
        this.freelanceStep = new BehaviorSubject(Functions.TEXT_SEARCH);
        this.missionFreelance = new BehaviorSubject({});
        this.sectionStatus = new BehaviorSubject([]);
        this.missionId = new BehaviorSubject(null);
        this.quoteFile = new BehaviorSubject(null);
        this.contratFile = new BehaviorSubject(null);
        this.quoteSignatureFile = new BehaviorSubject(null);
        this.contratSignatureFile = new BehaviorSubject(null);
        this.quoteSignatureMergeFile = new BehaviorSubject(null);
        this.contratSignatureMergeFile = new BehaviorSubject(null);
        this.missionAction = new BehaviorSubject("new");
        this.quoteFiles = new BehaviorSubject([]);
        this.contractFiles = new BehaviorSubject([]);
        this.factureFile = new BehaviorSubject(null);
    }

    setFactureFile(data) {
        this.factureFile.next(data);
    }

    setMissionId(id) {
        this.missionId.next(id);
    }

    setMissionAction(action) {
        this.missionAction.next(action);
    }

    setSectionStatus(sectionStatus) {
        this.sectionStatus.next(sectionStatus);
    }

    setQuoteFile(data) {
        this.quoteFile.next(data);
    }

    setContratFile(data) {
        this.contratFile.next(data);
    }

    setQuoteSignatureFile(data) {
        this.quoteSignatureFile.next(data);
    }

    setContratSignatureFile(data) {
        this.contratSignatureFile.next(data);
    }

    setQuoteSignatureMergeFile(data) {
        this.quoteSignatureMergeFile.next(data);
    }

    setContratSignatureMergeFile(data) {
        this.contratSignatureMergeFile.next(data);
    }

    setQuoteFiles(data) {
        this.quoteFiles.next(data);
    }

    setContractFiles(data) {
        this.contractFiles.next(data);
    }

    setMission(mission) {
        this.mission.next(mission);
    }

    setMissionStep(step) {
        this.missionStep.next(step);
    }

    setMissionSection(section) {
        this.missionSection.next(section);
    }

    setFreelanceStep(section) {
        this.freelanceStep.next(section);
    }

    setFileNameFile(mission) {
        this.fileNameFile.next(mission);
    }

    setMissionFreelance(freelance) {
        this.missionFreelance.next(freelance);
    }

    getFactureFileObservable() {
        return this.factureFile.asObservable();
    }

    getMissionObservable() {
        return this.mission.asObservable();
    }

    getMissionActionObservable() {
        return this.missionAction.asObservable();
    }

    getMissionStepObservable() {
        return this.missionStep.asObservable();
    }

    getFileNameFileObservable() {
        return this.fileNameFile.asObservable();
    }

    getMissionSectionObservable() {
        return this.missionSection.asObservable();
    }

    getFreelanceStepObservable() {
        return this.freelanceStep.asObservable();
    }

    getMissionFreelanceObservable() {
        return this.missionFreelance.asObservable();
    }

    getSectionStatusObservable() {
        return this.sectionStatus.asObservable();
    }

    getMissionIdObservable() {
        return this.missionId.asObservable();
    }

    getQuoteFileObservable() {
        return this.quoteFile.asObservable();
    }

    getContratFileObservable() {
        return this.contratFile.asObservable();
    }

    getQuoteSignatureFileObservable() {
        return this.quoteSignatureFile.asObservable();
    }

    getContratSignatureFileObservable() {
        return this.contratSignatureFile.asObservable();
    }

    getQuoteSignatureMergeFileObservable() {
        return this.quoteSignatureMergeFile.asObservable();
    }

    getContratSignatureMergeFileObservable() {
        return this.contratSignatureMergeFile.asObservable();
    }

    getQuoteFilesObservable() {
        return this.quoteFiles.asObservable();
    }

    getContractFilesObservable() {
        return this.contractFiles.asObservable();
    }
}

export default new Mission();

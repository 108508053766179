import React from 'react';
import ReactDOM from 'react-dom';

const Support = () => {
    return ReactDOM.createPortal(
        <div>
            <chatlio-widget widgetid="b4ec1f2a-d309-4687-4978-01849c080ecd"></chatlio-widget>
        </div>,
        document.getElementById('chatlio-widget-container')
    );
};

export default Support;
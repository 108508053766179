import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + '/api/countries/';

export const fetchCountries = async (token) => {
    try {
        return requestApi(`${BASE_URL}`, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching countries:', error);
        throw error;
    }
};

export const fetchCity = async (cityId, token) => {
    try {
        return requestApi(`${BASE_URL}cities/city/${cityId}`, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching city:', error);
        throw error;
    }
};

export const fetchCitiesByCountry = async (countryId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}cities/${countryId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error('Error fetching cities:', error);
        throw error;
    }
};
import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MdEdit, MdSave} from "react-icons/md";
import {FieldHolder} from "../../FieldHolder";
import Utils from "../../../../utils/Utils";
import {fetchCompanyFreelanceNote, saveCompanyFreelanceNote} from "../../../../services/freelanceProfileService";
import {useSelector} from "react-redux";
import Functions from "../../../../utils/Functions";
import {fetchEnterpriseProfiles} from "../../../../services/enterpriseProfileService";
import "./companyfreelancenote.css";
import PermissionService from "../../../../services/permissionService";
import TagService from "../../../../services/tagService";
import {CompanyFreelanceInteraction} from "./CompanyFreelanceInteraction";

export const CompanyFreelanceNote = ({activeSection, freelanceProfile}) => {
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState("DetailTab");
    const [editMode, setEditMode] = useState(false);

    const [tags, setTags] = useState([]);
    const [contactOwners, setContactOwners] = useState([]);
    const [triggerApi, setTriggerApi] = useState(true);

    const [companyFreelanceNoteData, setCompanyFreelanceNoteData] = useState({
        contactOwner: null,
        profileSourceType: '',
        profileSourceComment: '',
        profileNote: '',
        availabilityNote: '',
        FreelanceProfileTags: []
    });

    const isSuperAdministrator = connectedUser.companyRole === Functions.USER_ROLE.SUPER_ADMINISTRATOR;

    useEffect(() => {
        (async () => {
            if (freelanceProfile?.id && triggerApi) {
                const fetchedData = await fetchCompanyFreelanceNote(freelanceProfile?.id, token);
                if (fetchedData?.FreelanceProfileTags) {
                    fetchedData.FreelanceProfileTags = fetchedData.FreelanceProfileTags.map(item => item.Tag);
                    fetchedData.FreelanceProfileTags = TagService.groupTagsByType(fetchedData.FreelanceProfileTags);
                }
                setCompanyFreelanceNoteData(fetchedData);
                setTriggerApi(false);
            }
        })();
    }, [token, freelanceProfile, triggerApi]);

    useEffect(() => {
        (async () => {
            const fetchedEnterpriseProfiles = await fetchEnterpriseProfiles({}, token);
            const fetchedEnterpriseProfilesData = fetchedEnterpriseProfiles.data.map(({User, ...rest}) => ({
                id: rest.id,
                name: User.fullName
            }));
            setContactOwners(fetchedEnterpriseProfilesData);
        })();
    }, [token]);

    useEffect(() => {
        if (editMode) {
            try {
                TagService.getFreelanceProfileTags({}, token).then((fetchedTags) => {
                    if (!fetchedTags?.error) {
                        const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                        setTags(fetchedTagsData);
                    }
                });
            } catch (error) {
            }
        }
    }, [token, editMode]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setCompanyFreelanceNoteData((prevFormData) =>
            Utils.updateNestedField(prevFormData, name, value)
        );
    };

    const handleEdit = () => {
        setEditMode(true);
    }

    const handleSave = async () => {
        try {
            const data = {
                ...companyFreelanceNoteData,
                FreelanceProfileId: freelanceProfile?.id,
            };

            const result = await saveCompanyFreelanceNote(data, token);
            if (!result.error) {
                setEditMode(false);
                setTriggerApi(true);
            }
        } catch (error) {

        }
    };

    const handleEditOption = async (option, newLabel) => {
        if (option.value !== option.label) {
            await TagService.modifyTag(option.value, {name: newLabel}, token);
        }
    };

    const handleDeleteOption = async (option) => {
        if (option.value !== option.label) {
            await TagService.deleteTag(option.value, token);
        }
    };

    let contentStyle = {
        display: "flex",
        gap: "21px",
        flexDirection: "column",
        alignItems: "start",
        width: "100%",
    };
    if (editMode) {
        contentStyle.mt = 2;
    }

    return <Box className="company-freelance-note" sx={{width: "100%"}}>
        {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
            <Stack direction="row" className="company-freelance-note-tab" spacing={0.5} sx={{mb: 1}}>
                <Button
                    variant={activeTab === "DetailTab" ? "contained" : "text"}
                    color="light"
                    onClick={() => setActiveTab("DetailTab")}
                    size="small"
                    fullWidth={false}
                >
                    {t("Detail")}
                </Button>
                <Button
                    variant={activeTab === "OrganisationTab" ? "contained" : "text"}
                    color="light"
                    onClick={() => setActiveTab("OrganisationTab")}
                    size="small"
                    fullWidth={false}
                >
                    {t("Organisation")}
                </Button>
                <Button
                    variant={activeTab === "InteractionTab" ? "contained" : "text"}
                    color="light"
                    onClick={() => setActiveTab("InteractionTab")}
                    size="small"
                    fullWidth={false}
                >
                    {t("Interactions")}
                </Button>
                <Button
                    variant={activeTab === "NoteTab" ? "contained" : "text"}
                    color="light"
                    onClick={() => setActiveTab("NoteTab")}
                    size="small"
                    fullWidth={false}
                >
                    {t("Notes")}
                </Button>
            </Stack>
        )}
        <div className="card standardform-card card-padding-wide custom-card" style={{height: `calc(100% - 44px)`}}>
            {
                PermissionService.hasPermission(connectedUser, "updateCompanyFreelanceNote") &&
                !["InteractionTab", "NoteTab"].includes(activeTab) && (
                    <Stack direction="row"
                           justifyContent="end"
                           sx={{
                               width: "100%",
                               position: "absolute",
                               top: "5px",
                               right: "8px",
                               zIndex: 2
                           }}
                    >
                        {!editMode
                            ? (
                                <IconButton
                                    title={t("Modify")}
                                    onClick={handleEdit}
                                    size="small"
                                >
                                    <MdEdit size={20}/>
                                </IconButton>
                            )
                            : (
                                <IconButton
                                    title={t("Save")}
                                    onClick={handleSave}
                                    size="small"
                                >
                                    <MdSave size={20}/>
                                </IconButton>
                            )
                        }
                    </Stack>
                )}
            <Box sx={contentStyle}>
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <>
                        {activeTab === "DetailTab" && (
                            <>
                                <FieldHolder
                                    fielddesc={t("Tags")}
                                    type="dropdown"
                                    name="FreelanceProfileTags.freelance_tag"
                                    id="FreelanceProfileTags.freelance_tag"
                                    value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_tag}
                                    datas={tags?.freelance_tag}
                                    formData={companyFreelanceNoteData}
                                    setFormData={setCompanyFreelanceNoteData}
                                    canModify={editMode}
                                    ismulti={true}
                                    allowAdd={isSuperAdministrator}
                                    allowManageOption={isSuperAdministrator}
                                    onEditOption={handleEditOption}
                                    onDeleteOption={handleDeleteOption}
                                />
                                <FieldHolder
                                    fielddesc={t("Negotiated rate")}
                                    type="text"
                                    name="negotiatedRate"
                                    id="negotiatedRate"
                                    value={companyFreelanceNoteData.negotiatedRate}
                                    onChange={handleChange}
                                    canModify={editMode}
                                />
                                <FieldHolder
                                    fielddesc={t("Contact owner")}
                                    type="dropdown"
                                    name="contactOwner"
                                    id="contactOwner"
                                    set="user"
                                    value={companyFreelanceNoteData.contactOwner}
                                    datas={contactOwners}
                                    formData={companyFreelanceNoteData}
                                    setFormData={setCompanyFreelanceNoteData}
                                    canModify={editMode}
                                />
                                <FieldHolder
                                    fielddesc={t("Profile source")}
                                    type="dropdown"
                                    set="ProfileSourceType"
                                    name="profileSourceType"
                                    id="profileSourceType"
                                    value={companyFreelanceNoteData.profileSourceType}
                                    formData={companyFreelanceNoteData}
                                    setFormData={setCompanyFreelanceNoteData}
                                    canModify={editMode}
                                />
                                <FieldHolder
                                    fielddesc={t("Comment on profile source")}
                                    type="text"
                                    name="profileSourceComment"
                                    id="profileSourceComment"
                                    value={companyFreelanceNoteData.profileSourceComment}
                                    onChange={handleChange}
                                    canModify={editMode}
                                />
                            </>
                        )}
                        {activeTab === "OrganisationTab" && (
                            <>
                                <FieldHolder
                                    fielddesc={t("Team / Department")}
                                    type="dropdown"
                                    name="FreelanceProfileTags.freelance_reporting_entity"
                                    id="FreelanceProfileTags.freelance_reporting_entity"
                                    value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_reporting_entity}
                                    datas={tags?.freelance_reporting_entity}
                                    formData={companyFreelanceNoteData}
                                    setFormData={setCompanyFreelanceNoteData}
                                    canModify={editMode}
                                    ismulti={true}
                                    allowAdd={isSuperAdministrator}
                                    allowManageOption={isSuperAdministrator}
                                    onEditOption={handleEditOption}
                                    onDeleteOption={handleDeleteOption}
                                />
                                <FieldHolder
                                    fielddesc={t("Legal entity")}
                                    type="dropdown"
                                    name="FreelanceProfileTags.freelance_legal_entity"
                                    id="FreelanceProfileTags.freelance_legal_entity"
                                    value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_legal_entity}
                                    datas={tags?.freelance_legal_entity}
                                    formData={companyFreelanceNoteData}
                                    setFormData={setCompanyFreelanceNoteData}
                                    canModify={editMode}
                                    ismulti={true}
                                    allowAdd={isSuperAdministrator}
                                    allowManageOption={isSuperAdministrator}
                                    onEditOption={handleEditOption}
                                    onDeleteOption={handleDeleteOption}
                                />
                                <FieldHolder
                                    fielddesc={t("Country")}
                                    type="dropdown"
                                    name="FreelanceProfileTags.freelance_country"
                                    id="FreelanceProfileTags.freelance_country"
                                    value={companyFreelanceNoteData?.FreelanceProfileTags?.freelance_country}
                                    datas={tags?.freelance_country}
                                    formData={companyFreelanceNoteData}
                                    setFormData={setCompanyFreelanceNoteData}
                                    canModify={editMode}
                                    ismulti={true}
                                    allowAdd={isSuperAdministrator}
                                    allowManageOption={isSuperAdministrator}
                                    onEditOption={handleEditOption}
                                    onDeleteOption={handleDeleteOption}
                                />
                            </>
                        )}
                        {activeTab === "InteractionTab" && (
                            <CompanyFreelanceInteraction
                                freelanceProfile={freelanceProfile}
                                type="interaction"
                                canModify={true}
                            />
                        )}
                        {activeTab === "NoteTab" && (
                            <CompanyFreelanceInteraction
                                freelanceProfile={freelanceProfile}
                                type="note"
                                canModify={true}
                            />
                        )}
                    </>
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                    <FieldHolder
                        fielddesc={t("Notes")}
                        type="multiline"
                        name="availabilityNote"
                        id="availabilityNote"
                        value={companyFreelanceNoteData.availabilityNote}
                        onChange={handleChange}
                        canModify={editMode}
                    />
                )}
            </Box>
        </div>
    </Box>
};
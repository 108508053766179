import React from "react";
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";

import {createContract} from '../../services/contractService';
import {FileInput} from '../Inputs/FileInput';
import {Display} from '../Inputs/Display';

import {MessageComponent} from '../UI/MessageComponent';
import {Button} from "@mui/material";

export const DocumentPopup = ({onClose, missionData, setFileInfo, ...props}) => {

    const token = useSelector(state => state.auth.token);

    // Gestion des traductions
    const {t} = useTranslation();
    // // Gestion de l'envoi du formulaire signup
    const [formData, setFormData] = React.useState({
        documentName: '',
        file: '',
        commentEnterprise: '',
        commentFreelance: '',
        status: 'open',
        MissionId: '',
        FreelanceId: '',
    });

    const [message, setMessage] = React.useState({});

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Effectue la requête API POST avec les données du formulaire
        const addDocument = async () => {
            try {
                let responseData
                if (DocumentType === 'contract') {
                    responseData = await createContract({contractData: formData, token: token});
                } else if (DocumentType === 'quote') {
                    responseData = await createContract({contractData: formData, token: token});
                } else if (DocumentType === 'invoice') {
                    responseData = await createContract({contractData: formData, token: token});
                }
                if (responseData.success) {
                    setMessage({type: 'success', message: 'Document created'});
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    onClose();
                } else {
                    setMessage({type: 'error', message: responseData.error});
                }
            } catch (error) {
                setMessage({type: 'error', message: error});
                console.error(error);
            }
        };

        addDocument();
    };

    return (
        <>
            <div className='popup-overlay'>
                <div className='popup-container'>
                    <form className='popup-form' id='documentform' onSubmit={handleSubmit}>
                        <>
                            <div className='field'>
                                <p>{t("Document Name")}</p>
                                <input
                                    type='text'
                                    name='documentName'
                                    id='01'
                                    placeholder='Document number 1'
                                    className='input inputindex'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='field'>
                                <p>{t("Freelancer")}</p>
                                <Display
                                    type='text'
                                    name='freelance'
                                    id='02'
                                    className='file-inputindex'
                                    value={missionData}
                                    placeholder={'No freelance staffed'}
                                />
                            </div>
                            <div className='field'>
                                <p>{t("File")}</p>
                                <FileInput
                                    type='text'
                                    name='file'
                                    id='02'
                                    className='file-inputindex'
                                    value={formData.file}
                                    onChange={handleChange}
                                    setFileInfo={setFileInfo}
                                />
                            </div>
                            <div className='field'>
                                <p>{t("Comment")}</p>
                                <input
                                    type='text'
                                    name='commentEnterprise'
                                    id='03'
                                    placeholder='Comment 1'
                                    className='input inputindex'
                                    value={formData.commentEnterprise}
                                    onChange={handleChange}
                                />
                            </div>
                            <MessageComponent
                                type={message.type} message={message.message} width='80%'
                            />
                            <div className='field'>
                                <Button
                                    type='button'
                                    onClick={handleSubmit}
                                    variant="contained"
                                    size='medium'
                                    style={{width: '80%'}}>
                                    {t("Confirm and add")}
                                </Button>
                            </div>
                            <div className='field'>
                                <Button
                                    variant="contained"
                                    onClick={onClose}
                                    style={{width: '80%'}}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </>
                    </form>
                </div>
            </div>
        </>
    );
};
import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/collaborations/";

export const fetchFreelanceCooptations = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}cooptations/freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching cooptations :", error);
        throw error;
    }
};

export const saveCooptation = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}cooptations/save`, 'POST', data, token);
    } catch (error) {
        console.error("Error saving cooptation :", error);
        throw error;
    }
};

export const deleteCooptation = async (cooptationId, token) => {
    try {
        return requestApi(`${BASE_URL}cooptations/delete/${cooptationId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting cooptation :", error);
        throw error;
    }
};


export const fetchFreelanceAffiliations = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}affiliations/freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching affiliations :", error);
        throw error;
    }
};

export const saveAffiliation = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}affiliations/save`, 'POST', data, token);
    } catch (error) {
        console.error("Error saving affiliation :", error);
        throw error;
    }
};

export const deleteAffiliation = async (affiliationId, token) => {
    try {
        return requestApi(`${BASE_URL}affiliations/delete/${affiliationId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting affiliation :", error);
        throw error;
    }
};

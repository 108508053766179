import React from "react";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useDispatch} from 'react-redux';
import {saveToken} from '../../../utils/actions/authActions';
import './signupform.css';
import {createUser} from '../../../services/userService';
import CGU_et_CGV_Smarteem from '../../../assets/CGU_et_CGV_Smarteem.pdf';
import Mentions_d_information_Smarteem from '../../../assets/Mentions_d_information_Smarteem.pdf';

import {MessageComponent} from '../../UI/MessageComponent';
import {FieldHolder} from '../FieldHolder';
import {Button} from "@mui/material";


export const SignupForm = (props) => {
    // Gestion des traductions
    const {t} = useTranslation();
    // // Gestion de l'envoi du formulaire signup
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const [companyData, setCompanyData] = React.useState({});
    const [isHidden, setIsHidden] = React.useState(false);
    const [message, setMessage] = React.useState({});

    const toggleCompanyNameVisibility = () => {
        setIsHidden(!isHidden);
        setMessage({});
    };

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleCompanyChange = (e) => {
        setCompanyData({...companyData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Effectue la requête API POST avec les données du formulaire
        const signup = async () => {
            try {
                const responseData = await createUser(formData, companyData);
                if (responseData.success) {
                    dispatch(
                        saveToken({
                            token: responseData.token,
                            userId: responseData.userId,
                            userFirstName: responseData.userFirstName,
                            userLastName: responseData.userLastName,
                            freelanceId: responseData.freelanceId,
                            enterpriseId: responseData.enterpriseId,
                            companyId: responseData.companyId,
                            companyRole: responseData.companyRole,
                            userType: responseData.userType,
                            displayConfig: responseData.displayConfig,
                            fullAccess: false
                        })
                    );
                    navigate('/onboarding');
                } else {
                    setMessage({type: 'error', message: responseData?.error});
                }
            } catch (error) {
                setMessage({type: 'error', message: error.message});
            }
        };

        signup();
    };

    return (
        <>
            <div className='centerformcard'>
                <form className='inputform standardform' id='signupform' onSubmit={handleSubmit}>
                    {!isHidden &&
                        <>
                            <FieldHolder
                                fielddesc={t("First name")}
                                type="text"
                                name="firstName"
                                id="firstName"
                                placeholder={t("Jean")}
                                value={formData.firstName}
                                onChange={handleChange}
                                mandatoryFlag={true}
                            />
                            <FieldHolder
                                fielddesc={t("Last name")}
                                type="text"
                                name="lastName"
                                id="lastName"
                                placeholder={t("Dupont")}
                                value={formData.lastName}
                                onChange={handleChange}
                                mandatoryFlag={true}
                            />
                            <FieldHolder
                                fielddesc={t("Email")}
                                type="text"
                                name="email"
                                id="email"
                                placeholder={t("")}
                                value={formData.email}
                                onChange={handleChange}
                                mandatoryFlag={true}
                            />
                            <FieldHolder
                                fielddesc={t("Password")}
                                type="password"
                                name="password"
                                id="password"
                                placeholder={t("************")}
                                value={formData.password}
                                onChange={handleChange}
                                mandatoryFlag={true}
                            />
                            <FieldHolder
                                fielddesc={t("Confirm password")}
                                type="password"
                                name="passwordConfirm"
                                id="passwordConfirm"
                                placeholder={t("************")}
                                value={formData.passwordConfirm}
                                onChange={handleChange}
                                mandatoryFlag={true}
                            />
                            <a
                                className='indexlink'
                                href={CGU_et_CGV_Smarteem}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("By creating my account, I accept Smarteem's terms and conditions.")}
                            </a>
                            <a
                                className='indexlink'
                                href={Mentions_d_information_Smarteem}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("To find out more about how your personal data is managed and to exercise your rights, please refer to the Smarteem information notice.")}
                            </a>
                            <div className='buttonbox'>
                                <Button
                                    type="submit"
                                    name="main_type"
                                    value="Enterprise"
                                    variant="contained"
                                    size="large"
                                    onClick={toggleCompanyNameVisibility}
                                    sx={{width: "48%"}}
                                >
                                    {t("Enterprise")}
                                </Button>

                                <Button
                                    type="submit"
                                    name="main_type"
                                    value="Freelance"
                                    variant="contained"
                                    size="large"
                                    onClick={handleChange}
                                    sx={{width: "48%"}}
                                >
                                    {t("Freelancer")}
                                </Button>
                            </div>
                            <div className='field'>
                                <p className='indexlink'
                                   onClick={props.toggleLoginVisibility}>{t("Already have an account? Connect")}</p>
                            </div>
                        </>
                    }
                    {isHidden &&
                        <>
                            <div className='field'>
                                <p>{t("Company name")}</p>
                                <input
                                    type='text'
                                    name='name'
                                    id='01'
                                    placeholder='Bluecube consulting'
                                    className='input inputindex'
                                    value={companyData.name}
                                    onChange={handleCompanyChange}
                                />
                            </div>
                            <div className='field'>
                                <Button
                                    type="submit"
                                    name="main_type"
                                    value="Enterprise"
                                    variant="contained"
                                    size="large"
                                    onClick={handleChange}
                                    fullWidth
                                >
                                    {t("Validate and create company")}
                                </Button>
                            </div>
                            <MessageComponent type={message.type} message={message.message} width="90%"/>
                            <div className='field'>
                                <p className='indexlink' onClick={toggleCompanyNameVisibility}>{t("Return")}</p>
                            </div>
                        </>
                    }
                </form>
            </div>
        </>
    );
};
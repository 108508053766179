import React from 'react';
import {useTranslation} from "react-i18next";
import './tag.css';

export const Tag = ({text, color = '#7b7f8b', className = ''}) => {
    const {t} = useTranslation();

    return (
        <span className={`universal-tag ${className}`} style={{backgroundColor: color}}>{t(text)}</span>
    )
}
const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/files/";

export const fetchFile = async (fileId, typeFile, token, options = {}) => {
    try {
        const response = await fetch(`${BASE_URL}content/${fileId}/${typeFile}`, {
            ...options,
            method: "GET",
            headers: {
                authorization: token,
            },
        });

        if (!response.ok) {
            return await response.json();
        } else {
            // Récupérer le contenu du fichier comme un blob
            return await response.blob();
        }
    } catch (error) {
        if (error.name !== "AbortError") {
            console.error("Error fetching file:", error);
        }
        throw error;
    }
};

export const fetchFileInfo = async (fileId, token) => {
    try {
        const response = await fetch(`${BASE_URL}info/${fileId}`, {
            method: "GET",
            headers: {
                authorization: token,
            },
        });

        return await response.json();
    } catch (error) {
        console.error("Error fetching file:", error);
        throw error;
    }
};

export const createFile = async (fileData, objectAttachedType, token) => {
    try {
        const formData = new FormData();
        for (const prop in fileData) {
            formData.append(prop, fileData[prop]);
        }
        formData.append("objectAttachedType", objectAttachedType);

        const response = await fetch(`${BASE_URL}create`, {
            method: "POST",
            headers: {
                authorization: token,
            },
            body: formData,
        });

        return await response.json();
    } catch (error) {
        console.error("Error fetching file:", error);
        throw error;
    }
};

export const deleteFile = async (fileId, token) => {
    try {
        const response = await fetch(`${BASE_URL}delete/${fileId}`, {
            method: "DELETE",
            headers: {
                authorization: token,
            },
        });

        return await response.json();
    } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
};

import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {FieldHolder} from "../../../../FieldHolder";

export const FreelanceExperienceCard = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);

    return <>
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={() =>
                handleFreelanceProfileSave({
                    token,
                    freelanceProfileData,
                    callback: () => setEditMode(false)
                })
            }
        >
            <FieldHolder
                set="professionalExperienceYears"
                fielddesc={t("Experience")}
                type="dropdown"
                name="professionalExperienceYears"
                id="professionalExperienceYears"
                value={freelanceProfileData.professionalExperienceYears}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                canModify={editMode}
                ismulti={false}
            />
        </CustomCard>
    </>
};
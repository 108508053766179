import React from 'react';
import {useMediaQuery} from '@mui/material';

const ResponsiveComponent = ({mobile, tablet, desktop, children}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
    const isDesktop = !isMobile && !isTablet;

    return (
        (mobile && isMobile) ||
        (tablet && isTablet) ||
        (desktop && isDesktop) ||
        (mobile && tablet && (isMobile || isTablet)) ||
        (tablet && desktop && (isTablet || isDesktop)) ||
        (!mobile && !tablet && !desktop && (isDesktop))
            ? <>{children}</> : null
    );
};

export default ResponsiveComponent;

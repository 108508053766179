import React from 'react';
import {useTranslation} from "react-i18next";

import './index.css';

import {SignupForm} from '../components/Forms/Login_Signup/SignupForm';
import {LoginForm} from '../components/Forms/Login_Signup/LoginForm';
import {PasswordResetForm} from '../components/Forms/Login_Signup/PasswordResetForm';

import logosmall from '../assets/smarteem_logo_small.png';


export const Index = () => {
    // Gestion des traductions
    const {t} = useTranslation();

    // Gestion de l'affichage de la card signup/login
    const [loginVisibility, setLoginVisibility] = React.useState(true);
    const toggleLoginVisibility = () => {
        setLoginVisibility(!loginVisibility);
    };

    // Gestion de l'affichage de la card resetpassword
    const [resetPasswordVisibility, setResetPasswordVisibility] = React.useState(false);
    const toggleResetPasswordVisibility = () => {
        setResetPasswordVisibility(!resetPasswordVisibility);
    };

    return (
        <>
            <div className="contentholder">
                <div className="contentholder">
                    <img
                        src={logosmall}
                        alt='Smarteem Logo'
                        height={100}
                        className='logosmall'
                    />
                    <h1>{t("Join Smarteem community")}</h1>
                </div>
                {loginVisibility ? null :
                    <SignupForm toggleLoginVisibility={toggleLoginVisibility}/>
                }
                {(!loginVisibility || resetPasswordVisibility) ? null :
                    <LoginForm toggleLoginVisibility={toggleLoginVisibility}
                               toggleResetPasswordVisibility={toggleResetPasswordVisibility}/>
                }
                {!resetPasswordVisibility ? null :
                    <PasswordResetForm toggleResetPasswordVisibility={toggleResetPasswordVisibility}/>
                }

            </div>
        </>
    );
};

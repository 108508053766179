import {t} from "i18next";
import React, {useEffect} from "react";
import SignatureCanvas from "react-signature-canvas";
import {Button, Stack, Typography} from "@mui/material";

export const SignaturePad = (
    {
        signatureCanvasRef,
        signatureOfText,
        textDescSignature,
        handleClear,
        handleSave,
    }
) => {
    useEffect(() => {
    }, []);

    return (
        <Stack direction="column" alignItems="center" spacing={1} sx={{width: "100%"}}>
            <div className="text-theme">
                <Typography variant="label">{signatureOfText}</Typography>
                <div style={{color: "black"}}>
                    <small>{textDescSignature}</small>
                </div>
            </div>

            <SignatureCanvas
                ref={signatureCanvasRef}
                penColor="black"
                canvasProps={{
                    className: "signature-canvas",
                }}
            />

            <Stack
                direction="row"
                justifyContent="space-between"
                columnGap={2}
                sx={{width: "100%", pt: 1}}
            >
                <Button
                    type="button"
                    variant="contained"
                    color="light"
                    onClick={handleClear}
                >
                    {t("Erase")}
                </Button>

                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleSave}
                >
                    {t("Sign")}
                </Button>
            </Stack>
        </Stack>
    );
};
